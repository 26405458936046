<template>
  <div class="label-container">
    <!-- ICON EDITION -->
    <SymbolEditor
      :icon="icon.value"
      :color="icon.color"
      @update-icon="updateLabelIcon"
      @update-color="updateLabelColor"
    />

    <!-- LABEL TITLE -->
    <TextEllipsis
      v-if="!editTitle"
      :text="title"
      @dblclick="editTitle = true"
    />

    <ItemEditor
      class="label-editor"
      v-else
      :value="title"
      @edit="updateLabelTitle"
      :isLabel="true"
      @cancel="editTitle = false"
      placeholder="settings.permissions.user-labels.category-input-placeholder"
    />
    <!-- OPTIONS -->
    <div class="label-options" v-if="!editTitle">
      <base-button
        :icon-only="true"
        icon="pen"
        size="sm"
        variant="tertiary"
        @click="editTitle = true"
        class="ml-1"
      ></base-button>
      <base-button
        @click="showDeleteLabel = true"
        :icon-only="true"
        icon="trash"
        size="sm"
        variant="tertiary"
        class="ml-1"
      ></base-button>
    </div>
    <BaseDialog
      v-if="showDeleteLabel"
      @confirm="deleteLabel"
      @cancel="showDeleteLabel = false"
      @close="showDeleteLabel = false"
      :title="$t('settings.permissions.user-labels.suppress-confirm-title')"
      :description="
        $t('settings.academy.module-labels.delete-label.description', {
          name: title,
        })
      "
      :confirm-text="$t('generic.delete')"
      :cancel-text="$t('generic.cancel')"
      :open="showDeleteLabel"
      variant="danger"
    ></BaseDialog>
  </div>
</template>

<script>
import ItemEditor from '../PermissionSettings/AccessSettings/LabelCategorySettings/ItemEditor';
import SymbolEditor from '@/components/Settings/ContentParameters/SymbolEditor';
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'ModuleLabelsSettingsOverviewItem',
  components: {
    TextEllipsis,
    ItemEditor,
    SymbolEditor,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editTitle: false,
      showDeleteLabel: false,
    };
  },
  methods: {
    /** DELETE LABEL */
    deleteLabel() {
      this.$emit('delete-label', {
        id: this.id,
        title: this.title,
        parentId: this.parentId,
      });
    },

    /** EDIT LABEL */
    updateLabelTitle(title) {
      this.editTitle = false;
      if (title !== this.title) {
        return this.$emit('update-label-title', {
          id: this.id,
          title,
          parentId: this.parentId,
        });
      }
    },

    /** EDIT ICON */
    updateLabelIcon(icon) {
      this.$emit('update-label-icon', {
        id: this.id,
        icon: {
          value: icon,
          color: this.icon.color,
          type: this.icon.type,
        },
        parentId: this.parentId,
      });
    },
    updateLabelColor(color) {
      this.$emit('update-label-icon', {
        id: this.id,
        icon: {
          value: this.icon.value,
          color,
          type: this.icon.type,
        },
        parentId: this.parentId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-container {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
}

.label-editor {
  margin-left: 5px;
}

.label-container .label-options {
  visibility: hidden;
  display: contents;
}

.label-container:hover .label-options {
  visibility: visible;
}

.label-options {
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.options-icon {
  color: $grey-7-mayday;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background-color: $grey-1-mayday;
  }
  cursor: pointer;
  &_trash:hover {
    color: $red-mayday;
  }
  &_edit:hover {
    color: $blue-mayday;
  }
  * {
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
}
</style>
