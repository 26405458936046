import { urlCurator } from './event.utils';
import store from '../../../store';

export class SettingsEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  // SSO Profiles

  async createSsoProfile(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_PROFILE_CREATE',
      event,
      url: urlCurator(url),
    });
  }

  async updateSsoProfile(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_PROFILE_UPDATE',
      event,
      url: urlCurator(url),
    });
  }

  async deleteSsoProfile(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_PROFILE_DELETE',
      event,
      url: urlCurator(url),
    });
  }

  // SSO Mapping Profiles

  async createSsoProfileMapping(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_MAPPING_PROFILE_CREATE',
      event,
      url: urlCurator(url),
    });
  }

  async updateSsoProfileMapping(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_MAPPING_PROFILE_UPDATE',
      event,
      url: urlCurator(url),
    });
  }

  async deleteSsoProfileMapping(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_MAPPING_PROFILE_DELETE',
      event,
      url: urlCurator(url),
    });
  }

  // SSO Mapping User Labels

  async createSsoUserLabelMapping(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_MAPPING_USER_LABEL_CREATE',
      event,
      url: urlCurator(url),
    });
  }

  async updateSsoUserLabelMapping(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_MAPPING_USER_LABEL_UPDATE',
      event,
      url: urlCurator(url),
    });
  }

  async deleteSsoUserLabelMapping(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'SSO_MAPPING_USER_LABEL_DELETE',
      event,
      url: urlCurator(url),
    });
  }
}
