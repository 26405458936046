<template>
  <div class="item-row col-12 d-flex align-items-start justify-content-between">
    <div
      :class="[
        'd-flex align-items-start',
        showBulkActions ? 'max-width-w-checkbox' : 'max-width',
      ]"
    >
      <div class="pl-2 icon-wrapper" v-if="showBulkActions">
        <el-checkbox
          v-model="checked"
          class="mb-0 d-flex checkbox"
        ></el-checkbox>
      </div>

      <div
        class="d-flex align-items-start list-item-label-wrapper col pl-0"
        @click.exact.prevent="openContent"
        @click.meta.exact="openContent(true)"
        @click.ctrl.exact="openContent(true)"
      >
        <div class="icon-wrapper">
          <img
            src="~assets/article-icon-v2.svg"
            v-if="content.type === 'Article'"
            class="content-icon mr-2"
          />
          <img src="~assets/diag_icon.svg" v-else class="content-icon mr-2" />
        </div>

        <div class="w-100 pl-0">
          <ItemLabelEditor
            class="item-label-wrapper"
            :is-draft="!content.published"
            :label="content.label"
            :edit="edit_label"
            :item-type="content.type"
            :contentId="content.id"
            @update-label="updateContent"
            @close="edit_label = false"
          />
          <ContentListItemFullLabelWrapper
            v-if="content.labels && content.labels.length"
            :labels="content.labels"
            class="pb-1"
          />
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end icon-wrapper">
      <CommonNodeStatus
        :content="content"
        :isNode="false"
        @update-content="updateStatus"
        @update-content-verification="updateContentVerification"
        class="content-node-status d-flex flex-row align-items-center mr-2"
      />
      <ContentViewSettingModal
        v-if="displayContentViewParametersModal"
        :open="displayContentViewParametersModal"
        :content="content"
        :contentTitle="content.contentLabel"
        @update-content-view-setting="updateContentSettings"
        @close="displayContentViewParametersModal = false"
      />
      <DangerDeleteModal
        v-if="displayDeleteModal"
        :display="displayDeleteModal"
        type="archive"
        to-delete-name="SELECTED-CONTENTS"
        @choice="bulkDelete"
        @close="displayDeleteModal = false"
      />
      <TranslationTooltip
        class="mr-2"
        :is-translated="isTranslated"
        is-content
      />
      <ItemOptions
        class="option-selection-dropdown"
        :item-id="content.id"
        :user-permissions="content.userPermissions"
        item-type="content"
        @edit-label="edit_label = true"
        @delete="handleDelete"
        @open-content-view-parameter-modal="openContentViewParametersModal"
        @open-clone-to-target-modal="$emit('open-clone-to-target-modal')"
        @duplicate-in-same-location="$emit('duplicate-in-same-location')"
      />
    </div>
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="archiveContent"
    />
    <ModalEvents
      modal-name="SoftDeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="softDeleteContent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ItemLabelEditor from './ItemLabelEditor';
import ItemOptions from './ItemOptions';
import CommonNodeStatus from '@/views/KnowledgeEditor/NavigationBar/Navigator/NavigatorNode/CommonNode/CommonNodeStatus';
import ContentViewSettingModal from '@/components/Modals/ContentViewSettingModal/ContentViewSettingModal';
import ModalEvents from '@/components/Modals/ModalEvents';
import DangerDeleteModal from '@/components/Modals/DangerDeleteModal';
import TranslationTooltip from '@/components/Commons/TranslationTooltip';
import ContentListItemFullLabelWrapper from '@/components/ListItems/ContentListItemFullLabelWrapper';

export default {
  name: 'content-list-item',
  components: {
    ItemLabelEditor,
    ItemOptions,
    CommonNodeStatus,
    ContentViewSettingModal,
    DangerDeleteModal,
    ModalEvents,
    TranslationTooltip,
    ContentListItemFullLabelWrapper,
  },
  props: {
    content: Object,
    selectedContents: {
      type: Array,
      default: () => [],
    },
    itemType: {
      type: String,
      default: 'content',
    },
    showBulkActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked: {
      get() {
        return this.selectedContents.some((sc) => sc.id === this.content.id);
      },
      set(val) {
        let selectedContents = [...this.selectedContents];
        if (val) {
          selectedContents.push({
            id: this.content.id,
            userPermissions: this.content.userPermissions,
            labels: this.content.labels,
            accessRestrictions: this.content.accessRestrictions,
          });
        } else {
          selectedContents = this.selectedContents.filter(
            (sc) => sc.id !== this.content.id,
          );
        }
        this.$emit('update-selected-contents', selectedContents);
      },
    },
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.content.id}`;
    },
    isTranslated() {
      return this.content.returnLanguage === this.navigationLanguage;
    },
    ...mapGetters('knowledgeModule', ['editingLanguage', 'navigationLanguage']),
  },
  data() {
    return {
      displayContentViewParametersModal: false,
      displayDeleteModal: false,
      edit_label: false,
    };
  },
  methods: {
    openContent(newTab = false) {
      if (!this.edit_label)
        this.$emit('focus-content', { ...this.content, newTab });
    },
    updateStatus(event) {
      this.$emit('update-content', {
        id: this.content.id,
        payload: {
          ...event,
          lang: this.editingLanguage,
        },
      });
    },
    async updateContent(label) {
      const newContent = {
        id: this.content.id,
        payload: { label },
      };
      this.$emit('update-content', newContent);
    },
    updateContentVerification(isOutdated) {
      this.$emit('update-content-verification', {
        id: this.content.id,
        isOutdated,
      });
    },
    updateContentSettings(evt) {
      this.$emit('update-content-settings', evt);
    },
    handleDelete({ softDeleted } = {}) {
      if (this.itemType === 'archive') {
        this.displayDeleteModal = true;
      } else {
        this.openArchiveContent(softDeleted, this.content.label);
      }
    },
    async openArchiveContent(softDeleted) {
      const contentId = this.content.id;

      const { relatedCount, automationsCount } =
        await this.countLinkedReferences({
          contentId,
        });

      const payload = {
        component: softDeleted
          ? 'SoftDeleteContentModal'
          : 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: softDeleted
          ? {
              display: true,
              relatedCount,
              automationsCount,
              label: this.content.label,
            }
          : {
              display: true,
              id: contentId,
              relatedCount,
              automationsCount,
              label: this.content.label,
            },
      };
      this.openModal(payload);
    },
    openContentViewParametersModal() {
      this.displayContentViewParametersModal = true;
    },
    archiveContent(choice) {
      if (choice)
        return this.$emit('archive', { contentIds: [this.content.id] });
    },
    softDeleteContent(choice) {
      if (choice)
        return this.$emit('archive', {
          contentIds: [this.content.id],
          softDeleted: true,
        });
    },
    bulkDelete(choice) {
      if (choice) this.$emit('delete', [this.content.id]);
      this.displayDeleteModal = false;
    },
    ...mapActions('modalsModule', ['openModal']),
    ...mapActions('knowledgeModule', ['countLinkedReferences']),
  },
};
</script>

<style lang="scss" scoped>
.max-width {
  max-width: calc(100% - 100px);
}

.max-width-w-checkbox {
  max-width: calc(100% - 120px);
}

.list-item-label-wrapper {
  margin-left: 16px;
  width: 100%;
}

.item-label-wrapper {
  min-width: 0;
}

.content-label {
  z-index: 1;
}

.content-node-status {
  z-index: 1;
}

.option-selection-dropdown {
  :deep() .dropdown-menu {
    box-shadow: 0px 15px 30px $shadow-4-mayday;
  }
}

.item-row {
  padding-left: 0px;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 2px;
    cursor: pointer;
    & .content-icon {
      color: $blue-mayday;
    }
  }
}

.content-icon {
  max-width: 16px;
  max-height: 16px;
}

.options-selection {
  color: $grey-5-mayday;
  max-width: 12px;
  visibility: hidden;
  &:hover {
    color: $blue-mayday;
  }
}

.item-row {
  &:hover {
    .options-selection {
      visibility: visible;
    }
    .checkbox {
      :deep() .el-checkbox__input {
        visibility: visible;
      }
    }
  }
}

.checkbox {
  :deep() .el-checkbox__input {
    margin: auto;
    width: 14px;
    height: 14px;
  }
  :deep() .is-checked {
    visibility: visible;
  }
  :deep() .el-checkbox__inner {
    height: 14px;
    width: 14px;
  }
}

.dropdown-item {
  font-size: 12px !important;
  &:hover {
    color: $blue-mayday !important;
  }
}

.dropdown-item-danger {
  color: $red-mayday;
  background-color: rgba($yellow-mayday, 0.1);
}

.edit-icon {
  color: $grey-5-mayday;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  height: 29px;
}
</style>
