var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.baseClass} my-3`},[_c('IpRestrictionSettingsDrawerIpRanges',{attrs:{"ip-ranges-prop":_vm.ipRanges},on:{"change":function($event){_vm.ipRanges = $event}}}),_c('div',{class:`${_vm.baseClass}__ip-ranges__submission`},[_c('base-button',{staticClass:"mr-2",attrs:{"text":_vm.$t('generic.cancel'),"variant":"tertiary","size":"md"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('base-button',{attrs:{"text":_vm.$t('generic.confirm'),"variant":"primary","size":"md","disabled":!_vm.validIpRanges,"loading":_vm.loader},on:{"click":function($event){return _vm.setIpRanges()}}})],1),(_vm.showDeletion)?_c('BaseDialog',{attrs:{"variant":"danger","open":_vm.showDeletion,"title":_vm.$t(
        'settings-layout.menu-data.authentication.ip-restriction.drawer.deletion-modale.title',
      ),"description":_vm.$t(
        'settings-layout.menu-data.authentication.ip-restriction.drawer.deletion-modale.description',
      ),"confirm-text":_vm.$t(
        'settings-layout.menu-data.authentication.ip-restriction.drawer.deletion-modale.confirm',
      ),"cancel-text":_vm.$t('generic.cancel')},on:{"cancel":function($event){return _vm.addIpRange()},"close":function($event){return _vm.addIpRange()},"confirm":function($event){return _vm.disableIpRestrictions()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }