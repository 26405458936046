<template>
  <div class="navbar-submenu">
    <!-- SUBMENU TITLE -->
    <div class="navbar-submenu__title strong">
      {{ title }}
    </div>
    <!-- SUBMENU OPTIONS -->
    <div class="navbar-submenu__options-wrapper">
      <a
        :class="[
          'navbar-submenu__option',
          'align-items-center flex-row d-flex',
        ]"
        v-for="option in this.menuOptions"
        :key="option.id"
        :href="optionUrl(option)"
      >
        <TextEllipsis :text="option.label" font-size="14px" width="100%" />
        <font-awesome-icon v-if="focusedOption === option.value" icon="check" />
      </a>
    </div>
  </div>
</template>

<script>
import TextEllipsis from 'components/TextEllipsis.vue';

export default {
  name: 'NavbarSubMenu',
  components: { TextEllipsis },
  props: {
    title: {
      type: String,
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
    focusedOption: {
      type: String,
    },
    sortLabels: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    optionUrl(option) {
      return `/home/${option.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-submenu {
  &__title {
    padding: 8px;
    background-color: rgb(242, 245, 249);
    margin-bottom: 2px;
    font-weight: 700;
    color: black;
  }
  &__options-wrapper {
    max-height: 320px;
    max-width: 240px;
    padding: 4px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    color: black;
    margin-bottom: 4px;

    & svg {
      color: $blue-mayday;
      margin-left: 8px;
      flex: none;
    }
    &:hover {
      background-color: rgb(242, 245, 249);
    }
  }
}
</style>
