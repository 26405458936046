<template>
  <div class="d-flex flex-column mt-2">
    <el-input
      :placeholder="$t(element.emptyPlaceholder)"
      type="textarea"
      :rows="3"
      v-model="textValue"
      class="text-input mr-2"
      :disabled="!isEmpty && !isModifying"
    ></el-input>
    <div
      class="d-flex flex-row align-items-center justify-content-between mt-2"
    >
      <div class="char-count">
        {{ textValue.length }} {{ $t('public-knowledge.characters') }}
      </div>
      <base-button
        v-if="isModifying"
        variant="tertiary"
        icon="check"
        size="sm"
        @click="newValue"
        :text="$t('public-knowledge.configuration.items.domain.save-button')"
      ></base-button>
      <base-button
        v-else
        variant="tertiary"
        icon="pen"
        size="sm"
        @click="isModifying = true"
        :text="$t('public-knowledge.configuration.items.domain.modify-button')"
      ></base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextAreaInput',
  data() {
    return {
      textValue: '',
      isModifying: false,
    };
  },
  props: {
    element: Object,
    elementValueDisplay: String,
    isEmpty: Boolean,
  },
  created() {
    this.isModifying = this.isEmpty;
    if (!this.isEmpty) this.textValue = this.elementValueDisplay;
  },

  methods: {
    newValue() {
      this.$emit('new-element', this.textValue);
      this.isModifying = false;
    },
  },
  watch: {
    elementValueDisplay() {
      if (this.isEmpty) this.textValue = '';
      else this.textValue = this.elementValueDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  font-size: 12px;
}
.char-count {
  font-size: 12px;
  color: $grey-6-mayday;
}
:deep() .el-textarea.is-disabled .el-textarea__inner {
  color: $grey-7-mayday;
}
</style>
