<template>
  <BaseDialog
    :title="title"
    :description="description"
    :disabled="!isConfirmed"
    :open="localDisplay"
    :confirmText="$t('components.modals.delete-modal.ctas.delete')"
    :cancelText="$t('components.modals.delete-modal.ctas.cancel')"
    variant="danger"
    @confirm="$emit('confirm')"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <template v-if="hasConfirm" v-slot:body>
      <div
        class="d-flex align-items-center justify-content-center flex-row confirmation"
        @click="isConfirmed = !isConfirmed"
      >
        <el-checkbox
          v-model="isConfirmed"
          class="mb-0 mr-2"
          @click.native.stop
        ></el-checkbox>
        {{ $t('components.modals.delete-modal.ctas.confirm-delete') }}
      </div>
      <div
        v-if="hasCallout"
        class="d-flex flex-row align-items-center mt-3 callout"
      >
        <font-awesome-icon
          :icon="['fas', 'exclamation-circle']"
          class="mr-2 icon"
        ></font-awesome-icon>
        {{ callout }}
      </div>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  name: 'danger-delete-dialog',
  props: {
    title: String,
    description: String,
    hasConfirm: Boolean,
    display: Boolean,
    hasCallout: Boolean,
    callout: String,
  },
  data() {
    return {
      localDisplay: this.display,
      isConfirmed: false,
    };
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.callout {
  background-color: rgba($red-1-mayday, 0.4);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  .icon {
    color: $red-5-mayday;
    font-size: 16px;
    height: 16px;
  }
}
.confirmation {
  font-size: 14px;
}
</style>
