<template>
  <SettingsDropdown :force-open="forceOpen">
    <template slot="title">
      <SettingsDropdownCapitalizedColumn
        class="app-name"
        :text="apiOauthAppRegistration.name"
        :is-editing="editAppName"
        can-edit
        @edit-text="editAppName = true"
        @cancel="editAppName = false"
        @update-text="
          $emit('update', {
            id: apiOauthAppRegistration.id,
            key: 'name',
            value: $event,
          })
        "
      />

      <el-tooltip
        :content="$t('public-knowledge.appearances.items.brand.tooltip-delete')"
      >
        <base-button
          @click="showDeleteApi = true"
          variant="tertiary"
          size="sm"
          :icon-only="true"
          icon="trash"
        ></base-button>
      </el-tooltip>
      <BaseDialog
        v-if="showDeleteApi"
        :title="$t('settings.integration.mayday-api.delete-modal.title')"
        :description="
          $t('settings.integration.mayday-api.delete-modal.description', {
            name: apiOauthAppRegistration.name,
          })
        "
        :open="showDeleteApi"
        :confirmText="$t('generic.delete')"
        :cancelText="$t('generic.cancel')"
        variant="danger"
        @confirm="handleDeleteApi"
        @cancel="showDeleteApi = false"
        @close="showDeleteApi = false"
      >
      </BaseDialog>
    </template>
    <template slot="dropdown-content">
      <div class="mb-2 created-by-container">
        {{
          $t('settings.integration.mayday-api.created', {
            date: formattedCreatedAt,
            username: createdBy,
          })
        }}
      </div>
      <ContentViewSettingsCallout
        v-if="isNewlyCreated"
        trad="settings.integration.mayday-api"
        icon="exclamation-triangle"
        type="warning"
      />
      <!-- CLIENT ID INPUT -->
      <div class="w-full small-input flex flex-col">
        <span class="input-label">{{
          $t('settings.integration.mayday-api.client-id')
        }}</span>
        <div class="flex items-center mt-1">
          <el-input
            size="small"
            disabled
            :value="apiOauthAppRegistration.clientId"
          />
          <el-tooltip
            v-if="isNewlyCreated"
            class="ml-2"
            :content="$t('settings.integration.mayday-api.copy')"
            placement="top"
          >
            <base-button
              variant="secondary"
              size="md"
              icon="clipboard"
              @click="copyToClipboard(apiOauthAppRegistration.clientId)"
              :icon-only="true"
            ></base-button>
          </el-tooltip>
        </div>
      </div>
      <!-- CLIENT SECRET INPUT -->
      <div class="w-full small-input flex flex-col mr-2 mt-2">
        <span class="input-label">{{
          $t('settings.integration.mayday-api.client-secret')
        }}</span>
        <div class="flex items-center mt-1">
          <el-input
            size="small"
            disabled
            :value="apiOauthAppRegistration.clientSecret"
          />
          <el-tooltip
            v-if="isNewlyCreated"
            class="ml-2"
            :content="$t('settings.integration.mayday-api.copy')"
            placement="top"
          >
            <base-button
              variant="secondary"
              size="md"
              icon="clipboard"
              @click="copyToClipboard(apiOauthAppRegistration.clientSecret)"
              :icon-only="true"
            ></base-button>
          </el-tooltip>
        </div>
      </div>
      <!-- EXPIRATION TIME INPUT -->
      <div class="flex flex-col mt-2">
        <span class="input-label">{{
          $t('settings.integration.mayday-api.jwt-lifetime')
        }}</span>
        <div class="flex mt-1 jwt-lifetime-container">
          <el-input
            class="jwt-lifetime-input"
            size="small"
            type="number"
            min="1"
            v-model="jwtLifetime"
            @change="handleJwtLifetimeChange"
          />
          <el-select
            class="jwt-lifetime-select"
            size="small"
            placeholder="Select"
            value="s"
            v-model="jwtLifetimeValue"
            @change="handleJwtLifetimeChange"
          >
            <el-option
              v-for="item in jwtLifetimeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>

      <!-- PERMISSIONS SELECTOR -->
      <div class="flex flex-col mt-2">
        <span class="input-label mb-2">{{
          $t('settings.integration.mayday-api.permissions-selector')
        }}</span>
        <MaydayApiPermissionsSelector
          :permissions="apiOauthAppRegistration.permissions"
          @change="
            $emit('update', {
              id: apiOauthAppRegistration.id,
              key: 'permissions',
              value: $event,
            })
          "
        />
      </div>
      <BaseDialog
        v-if="showDeleteApi"
        :title="$t('settings.integration.mayday-api.delete-modal.title')"
        :description="
          $t('settings.integration.mayday-api.delete-modal.description', {
            name: apiOauthAppRegistration.name,
          })
        "
        :open="showDeleteApi"
        :confirmText="$t('generic.delete')"
        :cancelText="$t('generic.cancel')"
        variant="danger"
        @confirm="handleDeleteApi"
        @cancel="showDeleteApi = false"
        @close="showDeleteApi = false"
      >
      </BaseDialog>
    </template>
  </SettingsDropdown>
</template>

<script>
import ContentViewSettingsCallout from '../../../../../components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsCallout.vue';
import SettingsDropdown from '../../../../../components/Settings/SettingsDropdown.vue';
import SettingsDropdownCapitalizedColumn from '../../../../../components/Settings/SettingsDropdownCapitalizedColumn.vue';
import MaydayApiPermissionsSelector from './MaydayApiPermissionsSelector.vue';

export default {
  name: 'MaydayApiDropdown',
  components: {
    SettingsDropdown,
    SettingsDropdownCapitalizedColumn,
    ContentViewSettingsCallout,
    MaydayApiPermissionsSelector,
  },
  props: {
    apiOauthAppRegistration: {
      type: Object,
      required: true,
    },
    forceOpen: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const expirationTime = this.apiOauthAppRegistration.expirationTime || '2d';
    this.jwtLifetime = parseInt(expirationTime, 10);
    this.jwtLifetimeValue = expirationTime.slice(
      this.jwtLifetime.toString().length,
    );
    this.editAppName = this.apiOauthAppRegistration.isNewlyCreated;
  },
  data() {
    return {
      showDeleteApi: false,
      editAppName: false,
      jwtLifetime: 2,
      jwtLifetimeValue: 'd',
      jwtLifetimeOptions: [
        {
          value: 's',
          label: this.$t('settings.integration.mayday-api.units.seconds'),
        },
        {
          value: 'm',
          label: this.$t('settings.integration.mayday-api.units.minutes'),
        },
        {
          value: 'h',
          label: this.$t('settings.integration.mayday-api.units.hours'),
        },
        {
          value: 'd',
          label: this.$t('settings.integration.mayday-api.units.days'),
        },
      ],
    };
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.$message({
        message: this.$t('settings.integration.mayday-api.copied'),
        type: 'info',
      });
    },
    handleJwtLifetimeChange() {
      if (!this.jwtLifetime || this.jwtLifetime <= 0) {
        this.jwtLifetime = 1;
      }
      this.$emit('update', {
        id: this.apiOauthAppRegistration.id,
        key: 'expirationTime',
        value: `${this.jwtLifetime}${this.jwtLifetimeValue}`,
      });
    },
    handleDeleteApi() {
      this.$emit('delete');
      this.showDeleteApi = false;
    },
  },
  computed: {
    isNewlyCreated() {
      return this.apiOauthAppRegistration.isNewlyCreated;
    },
    formattedCreatedAt() {
      const date = new Date(this.apiOauthAppRegistration.createdAt);
      return (
        String(date.getDate()).padStart(2, '0') +
        '/' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '/' +
        String(date.getFullYear())
      );
    },
    createdBy() {
      return this.apiOauthAppRegistration.author || 'Mayday';
    },
  },
};
</script>

<style lang="scss" scoped>
.app-name {
  width: 250px;
}
.created-by-container {
  font-size: 12px;
  font-style: italic;
  color: $grey-7-mayday;
}

.small-input {
  :deep() .el-input--small {
    width: 100% !important;
  }
  :deep() .el-input__inner {
    letter-spacing: 2px;
  }
}

.input-label {
  font-weight: 700;
  color: black;
  font-size: 14px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.action-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-icon {
  width: 14px;
  height: 14px;
  color: $grey-7-mayday;

  &:hover {
    cursor: pointer;
    color: $red-mayday;
  }
}

.indicator-popconfirm {
  display: flex;
  align-items: center;
}

.jwt-lifetime-input {
  width: 100px;
  :deep() .el-input__inner {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-right: 0px;
  }
}
.jwt-lifetime-select {
  width: 130px;
  :deep() .el-input__inner {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    height: 32px;
  }
}
</style>
