<template>
  <headless-modal :open="open" @close="handleClose">
    <div class="base-dialog-container">
      <slot name="header">
        <div class="base-dialog-header" :class="[style[variant]]">
          <div class="base-dialog-icon-container">
            <font-awesome-icon
              :icon="['fal', icon[variant]]"
              class="base-dialog-icon"
            />
          </div>
        </div>
      </slot>

      <div
        class="base-dialog-content"
        :class="
          $slots.header
            ? 'base-dialog-content-with-header'
            : 'base-dialog-content-no-header'
        "
      >
        <div
          class="base-dialog-title"
          :class="{
            'mb-1': description && description.length,
          }"
        >
          {{ title }}
        </div>
        <div
          v-if="description && description.length"
          class="base-dialog-description"
        >
          {{ description }}
        </div>
      </div>

      <div v-if="messageBox" class="base-dialog-message">
        <base-message-box id="base-message-box" v-bind="messageBox" />
      </div>

      <div v-if="$slots.body" class="base-dialog-body">
        <slot name="body"></slot>
      </div>

      <div class="base-dialog-footer">
        <base-button
          class="mb-2"
          style="width: 100%"
          size="md"
          :variant="button[variant]"
          :text="confirmText"
          :loading="loading"
          :disabled="disabled"
          @click="$emit('confirm')"
        />
        <base-button
          style="width: 100%"
          variant="tertiary"
          size="md"
          :text="cancelText"
          :disabled="loading"
          @click="$emit('cancel')"
        />
      </div>
    </div>
  </headless-modal>
</template>

<script>
export default {
  name: 'BaseDialog',

  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'info',
      validator: (value) => ['danger', 'info'].includes(value),
    },
    confirmText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    messageBox: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    const baseMessageBoxHtmlContainer =
      document.getElementById('base-message-box');

    // For some reasons we need to force the show-close attribute this ways
    // as it is not being processed  when passed as a props
    if (baseMessageBoxHtmlContainer) {
      baseMessageBoxHtmlContainer.setAttribute('show-close', false);
    }
  },

  data() {
    return {
      style: {
        info: 'base-dialog-info-color',
        danger: 'base-dialog-error-color',
      },
      icon: {
        info: 'info-circle',
        danger: 'exclamation-circle',
      },
      button: {
        info: 'primary',
        danger: 'danger',
      },
    };
  },

  methods: {
    handleClose() {
      if (this.loading) return;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.base-dialog-container {
  display: flex;
  width: 320px;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  overflow: hidden;
}

.base-dialog-header {
  height: 96px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 16px;
}
.base-dialog-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.base-dialog-icon {
  font-size: 42px;
}

.base-dialog-content {
  padding: 24px;
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
  color: $grey-9-mayday;
}

.base-dialog-content-with-header {
  padding-top: 18px;
}
.base-dialog-content-no-header {
  padding-top: 8px;
}
.base-dialog-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.base-dialog-description {
  font-size: 14px;
  line-height: 20px;
}

.base-dialog-message {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.base-dialog-body {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
}

.base-dialog-footer {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.base-dialog-info-color {
  color: $blue-mayday;
}
.base-dialog-error-color {
  color: $red-6-mayday;
}
</style>
