<template>
  <div class="d-flex" :class="{ header: title }">
    <div v-if="title" class="title">{{ title }}</div>
    <base-button
      v-if="display"
      variant="tertiary"
      :text="toggleFiltersLabel"
      @click="$emit('toggle-filters')"
    />
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  computed: {
    toggleFiltersLabel() {
      return this.toggle
        ? this.$t('knowledge.search-admin.less-filters')
        : this.$t('knowledge.search-admin.more-filters');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 16px 16px 0px 16px;
}
.title {
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
}
.label {
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
  cursor: pointer;

  &:hover {
    color: $grey-7-mayday;
  }
}
</style>
