<template>
  <div :class="base">
    <div
      v-for="(category, index) in categories"
      :key="index"
      :class="`${base}__category`"
    >
      <div :class="`${base}__category__title`">
        {{ category.title }}
      </div>
      <div :class="`${base}__category__items`">
        <div
          :class="`${base}__category__items__details`"
          v-for="item in category.items"
          :key="item.id"
        >
          {{ item.name }}
        </div>
        <div
          v-if="category.empty && !category.items.length"
          :class="`${base}__category__items__details-error`"
        >
          {{ category.empty }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const base = 'settings-dropdown-item-column-preview';
export default {
  name: 'SettingsDropdownItemColumnPreview',
  props: {
    categories: Array,
  },
  data() {
    return {
      base: `${base}`,
    };
  },
};
</script>
<style lang="scss" scoped>
.settings-dropdown-item-column-preview {
  display: flex;
  flex-wrap: wrap;
  &__category {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 40%;
    margin-right: 10px;
    margin-bottom: 8px;
    &__title {
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      padding-bottom: 4px;
      border-bottom: 1px solid $grey-4-mayday;
    }
    &__items {
      &__details {
        &-error {
          color: $red-mayday;
        }
      }
    }
  }
}
</style>
