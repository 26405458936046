<template>
  <div class="search-footer">
    <div class="d-none d-md-flex align-items-center">
      <div class="info-label blue" v-if="count">
        <font-awesome-icon :icon="['fal', 'info-circle']" /><span
          class="ml-1"
          >{{ infoLabel }}</span
        >
      </div>
      <div class="mr-auto info-label red" v-else-if="!loading">
        <font-awesome-icon :icon="['fal', 'times-octagon']" /><span
          class="ml-1"
        >
          {{ $t('knowledge.search-admin.no-result') }}
        </span>
      </div>

      <font-awesome-icon
        v-if="loading"
        icon="spinner"
        class="mr-2 ml-auto blue"
        spin
      />
      <div v-else class="ml-auto">
        <div class="label" v-if="hasStepLabel">{{ stepLabel }}</div>
        <base-button-old
          v-else-if="isParametric && count"
          @click="$emit('go-to-results-page')"
          type="success"
          size="sm"
        >
          {{ countLabel }}
        </base-button-old>
        <base-button
          v-else-if="count"
          variant="tertiary"
          @click="$emit('go-to-results-page')"
          :text="countLabel"
        />
      </div>
    </div>
    <div class="w-100 d-md-none">
      <font-awesome-icon
        v-if="loading"
        icon="spinner"
        class="text-right"
        spin
      />
      <base-button-old
        v-if="count"
        class="w-100"
        @click="$emit('go-to-results-page')"
        type="success"
        size="sm"
      >
        {{ countLabel }}
      </base-button-old>
    </div>
  </div>
</template>

<script>
const LIMIT_COUNT_TO_DISPLAY = 10000;

import { mapGetters } from 'vuex';

export default {
  name: 'search-footer',
  props: {
    count: Number,
    steps: {
      type: Object,
      default: () => {
        return {
          univers: false,
          family: false,
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    infoLabel() {
      return this.count > 20
        ? this.$t('knowledge.search-admin.should-filter')
        : this.$t('knowledge.search-admin.click-on-product');
    },
    hasStepLabel() {
      return this.steps.univers;
    },
    stepLabel() {
      return this.steps.univers
        ? this.$t('knowledge.search-admin.select-univers')
        : this.$t('knowledge.search-admin.select-families');
    },
    countLabel() {
      if (this.count > LIMIT_COUNT_TO_DISPLAY) {
        return this.$t('knowledge.search-admin.results-count-exceeds');
      }
      return `${this.$t('knowledge.search-admin.show')} ${this.count} ${
        this.count > 1
          ? this.$t('knowledge.search-admin.results')
          : this.$t('knowledge.search-admin.result')
      }`;
    },
    ...mapGetters(['isParametric']),
  },
};
</script>

<style lang="scss" scoped>
.search-footer {
  border-top: 1px solid $grey-4-mayday;
  padding: 6px 4px;
}
.label {
  font-weight: bold;
  font-size: 12px;
  color: $grey-6-mayday;
}
.cursor-pointer {
  cursor: pointer;

  &:hover {
    color: $grey-7-mayday;
  }
}
.info-label {
  font-size: 12px;
  font-weight: 500;
  padding-left: 12px;
}
.blue {
  color: $blue-mayday;
}
.red {
  color: $red-mayday;
}
</style>
