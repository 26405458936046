<template>
  <div
    class="full-screen hide-scrollbar"
    :class="{
      'hidden-overflow':
        $route.name === 'knowledge-case' || $route.name === 'knowledge-content',
    }"
  >
    <side-bar class="small-sidebar p-0 d-none d-lg-block">
      <template slot-scope="{}" slot="links">
        <div class="relative d-flex flex-column">
          <!-- KNOWLEDGE POPOVER -->

          <el-popover
            v-if="hasSeveralKnowledges && userAccess.knowledgeEditor"
            placement="right-start"
            popper-class="navbar-popover"
            trigger="hover"
          >
            <NavbarSubMenu
              :focused-option="focusKnowledgeValue"
              :menu-options="knowledges"
              :title="$t('dashboard-layout.knowledge')"
            />
            <!-- Apply router-link-active class on $route.name for /knowledge route-->
            <a
              slot="reference"
              :class="{
                'router-link-active': $route.name.includes('knowledge'),
              }"
              :href="homeLink"
              class="text-center integrate-link my-1 router-link"
            >
              <div class="icon-wrapper">
                <font-awesome-icon
                  :icon="[
                    currentRouter === 'knowledge' ? 'fas' : 'fal',
                    'book',
                  ]"
                  class="nav-icon"
                />
              </div>
            </a>
          </el-popover>
          <!-- KNOWLEDGE -->
          <el-tooltip
            v-if="userAccess.knowledgeEditor && !hasSeveralKnowledges"
            :content="$t('dashboard-layout.knowledge')"
            class="router-link"
            placement="right"
          >
            <router-link
              :to="{
                name: 'knowledge-home',
                params: { lang: editingLanguage },
              }"
              class="text-center integrate-link my-1"
            >
              <div class="icon-wrapper">
                <font-awesome-icon
                  :icon="[
                    currentRouter === 'knowledge' ? 'fas' : 'fal',
                    'book',
                  ]"
                  class="nav-icon"
                />
              </div>
            </router-link>
          </el-tooltip>

          <!-- DASHBOARD -->
          <el-tooltip
            v-if="userAccess.dashboards"
            :content="$t('dashboard-layout.dashboard')"
            class="router-link"
            placement="right"
          >
            <router-link
              class="text-center integrate-link my-1"
              to="/dashboard"
            >
              <div class="icon-wrapper">
                <img
                  v-if="currentRouter === 'dashboard'"
                  alt="dashboard icon"
                  class="img-icon filter-solid-svg"
                  src="~assets/chart-simple-solid.svg"
                />
                <img
                  v-else
                  alt="dashboard icon"
                  class="img-icon filter-solid-svg"
                  src="~assets/chart-simple-light.svg"
                />
              </div>
            </router-link>
          </el-tooltip>

          <!-- TASKS -->
          <el-tooltip
            v-if="userAccess.tasksBoard"
            :content="$t('dashboard-layout.tasks')"
            class="router-link"
            placement="right"
          >
            <a class="text-center integrate-link my-1" href="/hub">
              <div
                class="icon-wrapper d-flex align-items-center justify-content-center"
              >
                <el-badge
                  v-if="backlogTasksCount > 0"
                  :max="99"
                  :value="backlogTasksCount"
                >
                  <img
                    v-if="currentRouter === 'hub'"
                    alt="dashboard icon"
                    class="img-icon filter-solid-svg"
                    src="~assets/square-kanban-solid.svg"
                  />
                  <img
                    v-else
                    alt="dashboard icon"
                    class="img-icon filter-solid-svg"
                    src="~assets/square-kanban-light.svg"
                  />
                </el-badge>
                <div v-else class="icon-wrapper">
                  <img
                    v-if="currentRouter === 'hub'"
                    alt="dashboard icon"
                    class="img-icon filter-solid-svg"
                    src="~assets/square-kanban-solid.svg"
                  />
                  <img
                    v-else
                    alt="dashboard icon"
                    class="img-icon filter-solid-svg"
                    src="~assets/square-kanban-light.svg"
                  />
                </div>
              </div>
            </a>
          </el-tooltip>

          <!-- NOTIFICATIONS -->
          <el-tooltip
            v-if="userAccess.notifications"
            :content="$t('dashboard-layout.notifications')"
            class="router-link"
            placement="right"
          >
            <a class="text-center integrate-link my-1" href="/notifications">
              <div class="icon-wrapper">
                <font-awesome-icon
                  :icon="[
                    currentRouter === 'notification-center' ? 'fas' : 'fal',
                    'paper-plane',
                  ]"
                  class="nav-icon"
                />
              </div>
            </a>
          </el-tooltip>

          <!-- AUTOMATIONS -->
          <el-tooltip
            v-if="userAccess.automations && hasIntegrations"
            :content="$t('dashboard-layout.automations')"
            class="router-link"
            placement="right"
          >
            <router-link
              class="text-center integrate-link my-1"
              to="/automations"
            >
              <div class="icon-wrapper">
                <font-awesome-icon
                  :icon="[
                    currentRouter === 'automations' ? 'fas' : 'fal',
                    'magic',
                  ]"
                  class="nav-icon"
                />
              </div>
            </router-link>
          </el-tooltip>

          <!-- ASK -->
          <el-tooltip
            v-if="hasAsk"
            :content="$t('dashboard-layout.ask')"
            class="router-link"
            placement="right"
          >
            <router-link
              class="text-center integrate-link my-1 ai-feature"
              to="/ask"
            >
              <div class="icon-wrapper">
                <font-awesome-icon
                  :icon="[currentRouter === 'ask' ? 'fas' : 'fal', 'sparkles']"
                  class="nav-icon ai-feature"
                />
              </div>
            </router-link>
          </el-tooltip>

          <hr v-if="hasAcademy" class="icon-separator" />

          <!-- ACADEMY -->
          <el-tooltip
            v-if="hasAcademy"
            :content="$t('dashboard-layout.academy')"
            class="router-link"
            placement="right"
          >
            <a class="text-center integrate-link my-1" href="/academy">
              <div class="icon-wrapper">
                <font-awesome-icon
                  :icon="['fal', 'graduation-cap']"
                  class="nav-icon academy-icon"
                />
              </div>
            </a>
          </el-tooltip>
        </div>

        <!-- BOTTOM -->
        <div class="sidebar-bottom-group">
          <!-- SEARCH -->
          <div
            v-if="userAccess.knowledgeEditor"
            class="bottom-icon-container mb-2"
          >
            <div
              class="bottom-icon-wrapper"
              @click.stop="searchModal = !searchModal"
            >
              <font-awesome-icon
                class="bottom-icon"
                :icon="['fal', 'search']"
              />
            </div>
          </div>

          <div class="md-popover-divider mb-2"></div>

          <WebRedirection class="redirect-mayday-web mx-auto mb-2" />

          <div class="md-popover-divider mb-2"></div>

          <!-- NOTIFICATION -->
          <el-tooltip
            :content="$t('dashboard-layout.notifications-drawer')"
            placement="right"
          >
            <NotificationPopover
              source="admin"
              class="mb-2"
              height="80"
              @click.native="handleOpen"
            />
          </el-tooltip>

          <!-- SETTINGS -->
          <router-link
            v-if="userAccess.settings"
            class="setting-router text-center bottom-icon-container mb-2"
            to="/settings"
          >
            <el-tooltip
              :content="$t('dashboard-layout.settings')"
              placement="right"
              class="router-link"
            >
              <div class="icon-wrapper-setting bottom-icon-wrapper">
                <font-awesome-icon
                  :icon="[currentRouter === 'settings' ? 'fas' : 'fal', 'cog']"
                  class="bottom-icon"
                />
              </div>
            </el-tooltip>
          </router-link>
          <div class="md-popover-divider mb-2"></div>
          <DocumentationWidget
            ref="documentationWidgetRef"
            :locale="$i18n.locale"
          />
          <div class="md-popover-divider mb-2"></div>

          <!-- USER -->
          <UserDropdown
            :user-access="userAccess"
            @click.native="handleOpen"
            @logout="logout"
            @update-language="updateAppLanguage"
          />
        </div>
      </template>
    </side-bar>

    <div class="main-content" :data="sidebarBackground">
      <div @click="toggleSidebar" class="main-content-wrapper">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <div style="max-height: calc(100vh)">
            <router-view class="d-none d-lg-block"></router-view>
            <!-- WIDEN WINDOW ALERT -->
            <div
              class="d-lg-none container-fluid widen-window d-flex align-items-center"
            >
              <div class="col-11 text-center px-4">
                <img
                  class="enlarge-gif"
                  src="~assets/material-design/empty-states/enlarge-screen.gif"
                />
                <p>
                  {{ $t('landing.enlarge-window') }}
                </p>
              </div>
            </div>
          </div>
        </fade-transition>
      </div>
    </div>
    <AdminSearchWrapper
      v-if="searchModal"
      @close="searchModal = false"
      :display="searchModal"
    />
  </div>
</template>

<script>
import AdminSearchWrapper from '@/components/SuperSearch/AdminSearchWrapper';
import NavbarSubMenu from '@/components/NavbarSubMenu';
import { mapActions, mapGetters } from 'vuex';
import NotificationPopover from '@/components/Notifications/NotificationPopover';
import WebRedirection from '@/components/Navbar/WebRedirection';
import UserDropdown from '@/components/Navbar/UserDropdown';
import { FadeTransition } from 'vue2-transitions';
import DocumentationWidget from '@/components/DocumentationWidget.vue';

export default {
  components: {
    FadeTransition,
    AdminSearchWrapper,
    NotificationPopover,
    NavbarSubMenu,
    WebRedirection,
    UserDropdown,
    DocumentationWidget,
  },
  props: {
    autoClose: Boolean,
  },
  computed: {
    hasIntegrations() {
      return this.hasZendesk || this.hasChrome || this.hasSalesforce;
    },
    hasSeveralKnowledges() {
      return this.knowledges.length > 1;
    },
    hasAsk() {
      return this.userAccess.ask;
    },
    isAiFeature() {
      return this.aiFeatures.includes(this.currentRouter);
    },
    routerOptions() {
      return [
        ...(this.userAccess.knowledgeEditor ? ['knowledge'] : []),
        ...(this.userAccess.dashboards ? ['dashboard'] : []),
        ...(this.userAccess.tasksBoard ? ['hub'] : []),
        ...(this.userAccess.notifications ? ['notification-center'] : []),
        ...(this.userAccess.automations && this.hasIntegrations
          ? ['automations']
          : []),
        ...(this.userAccess.ask ? ['ask'] : []),
      ];
    },
    hasAcademy() {
      return this.companyAllowAcademyPreference && this.userAccess.academy;
    },
    homeLink() {
      if (!this.focusKnowledge) return '/home';
      return `/home/${this.editingLanguage}/${this.focusKnowledge.id}`;
    },

    ...mapGetters([
      'hasZendesk',
      'hasChrome',
      'hasSalesforce',
      'userAccess',
      'isParametric',
      'companyAllowAcademyPreference',
    ]),
    ...mapGetters('knowledgeModule', [
      'knowledges',
      'focusKnowledgeValue',
      'focusKnowledge',
      'editingLanguage',
      'focusKnowledgeDefaultLanguage',
    ]),
    ...mapGetters('taskModule', ['backlogTasksCount']),
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
      searchModal: false,
      currentRouter: 'knowledge-home',
      routers: [
        'knowledge-home',
        'analytics-contents',
        'board',
        'notification-center',
        'automations',
        'ask',
      ],
      aiFeatures: ['ask'],
    };
  },
  mounted() {
    this.getKnowledges();
    this._keyListener = (e) => {
      if (
        this.userAccess.knowledgeEditor &&
        e.key === 'k' &&
        (e.metaKey || e.altKey || e.ctrlKey)
      ) {
        this.searchModal = true;
        e.preventDefault();
      } else if (e.key === 'Escape') {
        this.searchModal = false;
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
    this.getCompanyHostnames();
    this.getBacklogTasksCount();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    updateAppLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('updateAppLanguage', lang);
      // Set in window for web-components when rendered and eventListener for web-components already rendered
      window.___MAYDAY_CE___.locale = lang;
      window.dispatchEvent(
        new CustomEvent('md-updateAppLanguage', { detail: lang }),
      );
    },
    async updateFocusKnowledge(knowledge) {
      this.setFocusKnowledge(knowledge);
      await this.goToTranslatedEntity({
        entityType: 'Knowledge',
        lang: knowledge.defaultLanguage,
      });
    },
    handleOpen() {
      this.$refs.documentationWidgetRef.closeWidget();
    },
    ...mapActions('knowledgeModule', [
      'setFocusKnowledge',
      'goToTranslatedEntity',
      'getKnowledges',
    ]),
    ...mapActions('adminModule', ['getCompanyHostnames']),
    ...mapActions('taskModule', ['getBacklogTasksCount']),
    ...mapActions(['logout']),
  },
  watch: {
    $route: {
      async handler(newVal) {
        const { path, params, query, name, matched } = newVal;
        const { lang } = params;

        // Make sure there is always a lang parameter in the url
        if (!lang && matched.some(({ meta }) => meta.isLangRelated)) {
          return this.$router.replace({
            name,
            query,
            params: { ...params, lang: this.focusKnowledgeDefaultLanguage },
          });
        }

        this.currentRouter = path.split('/')[1];
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  width: 100vw;
  height: 100vh;
}

.hidden-overflow {
  overflow: hidden;
}

.widen-window {
  height: 100vh;
  width: 100%;
}

.enlarge-gif {
  max-width: 50vw;
}

.nav-icon {
  z-index: 1;
  width: 20px;
  height: 20px;
  color: $grey-6-mayday;
  margin-top: 10px;
  margin-bottom: 10px;
}

.img-icon {
  z-index: 1;
  width: 18px;
  height: 18px;
  color: $grey-6-mayday;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bottom-icon {
  width: 16px;
  height: 16px;
  color: $grey-6-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.sidebar-bottom-group {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 60px;
}

.md-popover-divider {
  border-bottom: 1px solid $grey-3-mayday;
}

.small-sidebar {
  width: 60px !important;
  padding-top: 5px;
}

.integrate-link {
  display: block;
  color: #9aa4b6;
  cursor: pointer;
  height: 40px;
}

.router-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.router-link-active {
  border-right: 2px solid $blue-mayday;
  .nav-icon {
    color: $blue-mayday;
    &.ai-feature {
      color: $purple-5-mayday;
    }
  }
  .bottom-icon {
    color: $blue-mayday !important;
  }
  .filter-solid-svg {
    filter: invert(38%) sepia(90%) saturate(3765%) hue-rotate(194deg)
      brightness(100%) contrast(101%);
  }
  .icon-wrapper-setting {
    background-color: rgba($blue-mayday, 0.1);
  }

  &:hover {
    .icon-wrapper {
      background-color: rgba($blue-mayday, 0.1);
    }
  }
}

.sidebar-bottom-group .router-link-active::before {
  content: '';
  position: absolute;
  right: 0px;
  width: 3px;
  height: 26px;
  border-left: 2px solid $blue-mayday;
  border-radius: 2px;
}

.icon-wrapper {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  transition: background 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.router-link-active .icon-wrapper {
  background-color: rgba($blue-mayday, 0.1);
}

.router-link:hover {
  .nav-icon {
    color: $blue-mayday;

    &.ai-feature {
      color: $purple-5-mayday;
    }
  }
  .bottom-icon {
    color: $blue-mayday;
  }
  .icon-wrapper {
    background-color: rgba($grey-5-mayday, 0.1);
  }

  .icon-wrapper-setting {
    border-radius: 4px;
    background-color: rgba($blue-mayday, 0.1);
  }
}

.cursor-animation {
  content: '';
  position: absolute;
  right: 0px;
  width: 3px;
  height: 40px;
  border-left: 2px solid $blue-mayday;
  border-radius: 2px;
  transition: transform 200ms ease 0ms;

  &.ai-feature {
    border-color: $purple-5-mayday;
  }
}

.sidebar-button {
  max-width: 50px;
}

.setting-router {
  height: 24px;
}

.bottom-icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background-color: $grey-2-mayday;
    .bottom-icon {
      color: $blue-mayday;
    }
  }
}

.bottom-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-solid-svg {
  filter: invert(67%) sepia(5%) saturate(1123%) hue-rotate(172deg)
    brightness(88%) contrast(87%);
}

:deep() .el-badge__content {
  background-color: $red-mayday;
  font-size: 10px;
  line-height: 14px;
}

.icon-separator {
  border-top: 1px solid $grey-3-mayday;
  margin: 8px 0;
  height: 0;
  width: 100%;
}

.academy-icon {
  font-size: 25px !important;
  width: 25px !important;
}

.academy-icon > path {
  height: 20px !important;
  font-size: 25px !important;
}

.home-icon {
  font-size: 20px !important;
  width: 25px !important;
}

.home-icon > path {
  height: 20px !important;
  font-size: 20px !important;
}
</style>
