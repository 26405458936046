<template>
  <div class="d-flex flex-row ml-2 quick-filter-wrapper">
    <el-popover
      popper-class="p-0 mt-1"
      v-model="datesPopover"
      placement="bottom-start"
    >
      <DateFilterPicker
        :chosen-dates="chosenDates[0]"
        :main-options="datesMainOptions"
        :sub-options="datesSubOptions"
        @set-dates="$emit('set-dates', $event)"
        @quit-dates="datesPopover = false"
      ></DateFilterPicker>
      <base-button
        slot="reference"
        icon="calendar"
        menu
        :pressed="datesPopover"
        :text="$t('knowledge.search-admin.quick-filters.dates.title')"
        variant="secondary"
      />
    </el-popover>
    <el-popover
      popper-class="p-0 mt-1"
      v-model="userPopover"
      placement="bottom-start"
    >
      <UserFilterPicker
        :chosen-user="chosenUser[0]"
        @set-user="$emit('set-user', $event)"
        @quit-user="userPopover = false"
      ></UserFilterPicker>
      <base-button
        slot="reference"
        icon="user"
        menu
        :pressed="userPopover"
        :text="$t('knowledge.search-admin.quick-filters.user.title')"
        variant="secondary"
      />
    </el-popover>

    <el-popover
      popper-class="p-0 mt-1"
      v-model="hasVerificationPolicyPopover"
      placement="bottom-start"
    >
      <BooleanFilterPicker
        :hasVerificationPolicy="hasVerificationPolicy[0]"
        @set-has-verification-policy="
          $emit('set-has-verification-policy', $event)
        "
        @quit-has-verification-policy="hasVerificationPolicyPopover = false"
      ></BooleanFilterPicker>
      <base-button
        slot="reference"
        icon="cog"
        menu
        :pressed="hasVerificationPolicyPopover"
        :text="
          $t(
            'knowledge.search-admin.quick-filters.verification-policy.has.title',
          )
        "
        variant="secondary"
      />
    </el-popover>
    <el-popover
      v-if="displayVerificationPolicyFilters"
      popper-class="p-0 mt-1"
      v-model="verificationPolicyDueDatePopover"
      placement="bottom-start"
    >
      <DateFilterPicker
        :chosen-dates="chosenVerificationPolicyDueDate[0]"
        :main-options="verificationDueDateMainOptions"
        :sub-options="dueDatesSubOptions"
        @set-dates="$emit('set-verification-due-date', $event)"
        @quit-dates="verificationPolicyDueDatePopover = false"
      ></DateFilterPicker>
      <base-button
        slot="reference"
        icon="calendar"
        menu
        :pressed="verificationPolicyDueDatePopover"
        :text="
          $t(
            'knowledge.search-admin.quick-filters.verification-policy.due-date.title',
          )
        "
        variant="secondary"
      />
    </el-popover>
  </div>
</template>

<script>
import DateFilterPicker from './DateFilterPicker.vue';
import UserFilterPicker from './UserFilterPicker.vue';
import BooleanFilterPicker from './BooleanFilterPicker.vue';
import {
  dateRangeOptions,
  dueDateRangeOptions,
} from '@/utils/dates/dateBetween';
export default {
  name: 'quick-filters',
  props: {
    chosenDates: {
      type: Array,
      default: () => [],
    },
    chosenUser: {
      type: Array,
      default: () => [],
    },
    hasVerificationPolicy: {
      type: Array,
      default: () => [],
    },
    chosenVerificationPolicyDueDate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      datesPopover: false,
      datesMainOptions: [
        {
          key: 'createdAt',
        },
        { key: 'updatedAt' },
      ],
      verificationDueDateMainOptions: [
        {
          key: 'verificationPolicy.verificationDueDate',
        },
      ],
      datesSubOptions: dateRangeOptions,
      dueDatesSubOptions: dueDateRangeOptions,
      userPopover: false,
      hasVerificationPolicyPopover: false,
      verificationPolicyDueDatePopover: false,
    };
  },
  components: { DateFilterPicker, UserFilterPicker, BooleanFilterPicker },
  computed: {
    displayVerificationPolicyFilters() {
      return (
        this.hasVerificationPolicy &&
        this.hasVerificationPolicy[0] &&
        this.hasVerificationPolicy[0].value
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-filter-wrapper {
  gap: 8px;
  align-items: center;
  max-width: calc(100% - 150px);
  overflow: scroll;
  scrollbar-width: thin;
}
.quick-filter {
  gap: 8px;
  padding: 2px 6px;
  border-radius: 8px;
  background-color: $grey-2-mayday;
  height: 25px;
  font-size: 12px;
  border: 1px solid transparent;
  &__selected {
    color: $blue-mayday;
    border: 1px solid $blue-mayday;
  }
}
.filter-title {
  height: 25px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}
.icon {
  height: 25px;
}
</style>
