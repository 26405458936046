<template>
  <div class="content-view-global-drawer">
    <el-tooltip
      :content="$t('knowledge.modals.content-view-setting.call-to-action')"
      placement="top"
    >
      <base-button
        icon="cog"
        variant="secondary"
        icon-only
        :pressed="isSettingsDrawerOpen"
        @click="isSettingsActive = !isSettingsActive"
      />
    </el-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isSettingsDrawerOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      isSettingsActive: false,
    };
  },

  computed: {
    ...mapGetters('knowledgeModule', ['focusContentId']),
  },
  watch: {
    isSettingsActive() {
      this.$emit('content-settings-drawer', this.isSettingsActive);
    },
    focusContentId() {
      this.isSettingsActive = false;
    },
    isSettingsDrawerOpen: {
      handler() {
        this.isSettingsActive = this.isSettingsDrawerOpen;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content-view-global-drawer {
  width: 50px;
  background-color: $grey-1-mayday;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}
.icon-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &__is-active {
    background-color: $blue-mayday;
  }
  &__is-not-active {
    background-color: white;
  }
}
.icon {
  &__is-active {
    color: white;
  }
  &__is-not-active {
    color: black;
  }
}
</style>
