<template>
  <div :class="base">
    <settings-cta
      :description="`${pre}.description`"
      :cta="`${pre}.cta`"
      @click="handleCreate()"
    ></settings-cta>
    <div :class="`${base}__main`">
      <div
        v-loading="isLoading"
        :class="[`${base}__main__profiles`, { 'fill-height': pages > 1 }]"
      >
        <SettingsDropdown
          v-for="profile in profiles"
          :key="profile.id"
          :class="`${base}__main__profiles__item`"
        >
          <div
            slot="title"
            :class="[
              `${base}__main__profiles__item__title`,
              { [`${base}__main__profiles__item__title-error`]: profile.error },
            ]"
          >
            <div :class="`${base}__main__profiles__item__title__container`">
              <div
                :class="`${base}__main__profiles__item__title__container__icon`"
              >
                <font-awesome-icon :icon="['fal', 'passport']" />
              </div>
              <div
                :class="`${base}__main__profiles__item__title__container__text`"
              >
                <TextEllipsis :text="profile.name" font-size="16px" />
                <TextEllipsis
                  v-if="profile.error"
                  :class="`${base}__main__profiles__item__title__container__text-error`"
                  :text="$t(`${pre}.item.invalid`)"
                  font-size="14px"
                />
              </div>
            </div>
            <BaseDropdownOptionsWrapper
              :is-horizontal="true"
              :options="dropdownOptions"
              @select="handleDropdownSelect($event, profile)"
            ></BaseDropdownOptionsWrapper>
          </div>
          <div slot="dropdown-content">
            <SettingsDropdownItemColumnPreview
              :categories="categories(profile)"
            />
          </div>
        </SettingsDropdown>
      </div>
      <el-pagination
        :current-page="page"
        :disabled="isLoading"
        :page-count="pages"
        background
        hide-on-single-page
        layout="prev, pager, next"
        @current-change="page = $event"
      />
    </div>
    <SsoProfileSettingsDrawer
      v-if="show"
      :show="show"
      :selected-profile="selectedProfile"
      @submit="handleSubmit"
      @close="handleClose()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SettingsCta from '@/components/Settings/SettingsCta';
import SettingsDropdown from '@/components/Settings/SettingsDropdown.vue';
import SettingsDropdownItemColumnPreview from '@/components/Settings/SettingsDropdownItemColumnPreview.vue';
import SsoProfileSettingsDrawer from './SsoProfileSettingsDrawer.vue';
import TextEllipsis from '@/components/TextEllipsis.vue';

const i18nPrefix = 'settings-layout.menu-data.authentication.sso-profile';
const base = 'sso-profile-settings';
const size = 5;

export default {
  name: 'sso-profile-settings',
  components: {
    SettingsCta,
    SettingsDropdown,
    SettingsDropdownItemColumnPreview,
    SsoProfileSettingsDrawer,
    TextEllipsis,
  },
  async mounted() {
    try {
      await this.refreshSsoProfiles();
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    pages() {
      return Math.ceil(this.rawProfiles.length / this.size);
    },
    profiles() {
      return this.rawProfiles
        .slice(this.size * (this.page - 1), this.size * this.page)
        .map((profile) => ({
          ...profile,
          error: !profile.groupIds.length || !profile.roleIds.length,
        }));
    },
    categories() {
      return (profile) => {
        const { groupIds, roleIds } = profile;
        return [
          this.resolveCategory('group', groupIds),
          this.resolveCategory('role', roleIds),
        ];
      };
    },
    ...mapGetters('adminModule', ['groups', 'roles']),
  },
  data() {
    return {
      size: Object.freeze(size),
      base: `${base}`,
      pre: `${i18nPrefix}`,
      page: 1,
      isLoading: true,
      show: false,
      selectedProfile: null,
      dropdownOptions: [
        {
          key: 'edit',
          label: this.$t('generic.edit'),
          icon: ['fal', 'pen'],
        },
        {
          key: 'delete',
          label: this.$t('generic.delete'),
          icon: ['fal', 'trash'],
          type: 'danger',
          divider: true,
        },
      ],
      rawProfiles: [],
    };
  },
  methods: {
    resolveCategory(type, ids) {
      const category = {
        title: this.$tc(`main.${type}`, 2),
        items: [],
        empty: this.$t(`${this.pre}.item.no-${type}`),
      };

      switch (type) {
        case 'group':
          category.items = this.groups.filter((el) =>
            ids.map(Number).includes(parseInt(el.id, 10)),
          );
          break;
        case 'role':
          category.items = this.roles.filter((el) =>
            ids.map(Number).includes(parseInt(el.id, 10)),
          );
          break;
        default:
          break;
      }

      return category;
    },
    handleCreate() {
      this.show = true;
    },
    async handleDropdownSelect(evt, profile) {
      const choice = evt.detail[0];
      switch (choice) {
        case 'delete':
          await this.handleDelete(profile.id);
          break;
        case 'edit':
          this.selectedProfile = profile;
          this.show = true;
          break;
        default:
          break;
      }
    },
    async refreshSsoProfiles() {
      const results = await this.getSsoProfiles();
      this.rawProfiles = results.results.map((el) => ({ ...el, former: el }));
    },
    handleClose() {
      this.selectedProfile = null;
      this.show = false;
    },
    async handleDelete(profileId) {
      try {
        this.isLoading = true;
        await this.deleteSsoProfile(profileId);
        await this.refreshSsoProfiles();
      } catch (err) {
        this.$message({
          message: this.$t(`${this.pre}.errors.delete`),
          type: 'error',
        });
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmit(profile) {
      this.handleClose();
      let method = profile.id ? this.updateSsoProfile : this.createSsoProfile;
      try {
        this.isLoading = true;
        await method(profile);
        await this.refreshSsoProfiles();
      } catch (err) {
        this.$message({
          message: this.$t(
            `${this.pre}.errors.${profile.id ? 'update' : 'create'}`,
          ),
          type: 'error',
        });
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    ...mapActions('adminModule', [
      'getSsoProfiles',
      'createSsoProfile',
      'updateSsoProfile',
      'deleteSsoProfile',
    ]),
  },
  watch: {
    pages() {
      if (this.pages < this.page) this.page = this.pages;
    },
  },
};
</script>

<style lang="scss" scoped>
.sso-profile-settings {
  width: 100%;
  &__main {
    width: 100%;
    &__profiles {
      width: 100%;
      overflow: hidden;
      &.fill-height {
        min-height: calc(5 * 58px);
      }
      &__item {
        margin: 0;
        :deep(.title-container) {
          margin: 0;
        }
        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          overflow: hidden;
          height: 40px;
          color: $grey-8-mayday;
          &__container {
            display: flex;
            align-items: center;
            width: 100%;
            overflow: hidden;
            &__icon {
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid $grey-3-mayday;
              border-radius: 2px;
              margin-right: 8px;
            }
            &__text {
              display: flex;
              flex-direction: column;
              width: calc(100% - 48px);
              overflow: hidden;
              font-weight: 700;
              &-error {
                color: $red-mayday;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}

:deep(.el-pagination) {
  margin: 10px auto 0;
  width: fit-content;
}
</style>
