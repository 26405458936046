<template>
  <div class="contributors__container" @click="handleClick(false)">
    <div :class="['initials-container', { reactive: isReactive }]">
      <div
        v-for="(contributor, i) in contributors.slice(0, maximum)"
        :key="i"
        class="initials-wrapper"
        :style="colorStyle(contributor.username)"
      >
        <div class="initials">
          {{ getInitials(contributor.username) }}
        </div>
      </div>
      <div
        v-if="!contributors.length"
        class="initials-wrapper"
        :style="colorStyle(authorUsername)"
      >
        <div class="initials">
          {{ getInitials(authorUsername) }}
        </div>
      </div>
      <div v-if="total > maximum" class="contributors__more">
        <div class="initials">
          <span class="count">{{ `+${total - maximum}` }}</span>
          <span class="see-more">{{
            $t('knowledge.contributors.see-more')
          }}</span>
        </div>
      </div>
    </div>
    <div class="contributors__infos">
      <p>
        <span>{{ $t('knowledge.contributors.written-by') }}</span>
        <span class="author">
          {{ authorUsername }}
        </span>
        <span v-if="total > 1">
          {{ $t('knowledge.contributors.and') }}
          <b
            >{{ total - 1 }}
            {{ $tc('knowledge.contributors.others', total - 1) }}</b
          ></span
        >
      </p>
      <p v-if="!isArchive || (isArchive && updatedAt)">
        <span> {{ $t(lastUpdateContent) }} </span>
        <b>{{ lastUpdateDate }}</b>
        <span> {{ $t('knowledge.contributors.by') }}</span>
        <span class="author">
          {{
            isArchive
              ? archiveAuthor
              : contributors.length
              ? contributors.at(0).username
              : authorUsername
          }}
        </span>
      </p>
      <p v-else>{{ $t('knowledge.contributors.no-history-archive') }}</p>
      <span v-if="stepId" class="step-details" @click.stop="handleClick(true)">
        {{ $t('knowledge.contributors.step-history') }}</span
      >
    </div>
  </div>
</template>

<script>
import { userColor } from 'utils/utils';

export default {
  name: 'ContentDetails',
  props: {
    contributors: {
      type: Array,
      default: () => [],
    },
    stepId: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    author: {
      type: Object,
      default: () => ({}),
    },
    updatedAt: {
      type: String,
      default: '',
    },
    createdAt: {
      type: String,
      default: '',
    },
    reactive: {
      type: Boolean,
      default: true,
    },
    maximum: {
      type: Number,
      default: 3,
    },
    isArchive: {
      type: Boolean,
      required: false,
      default: false,
    },
    archiveAuthor: {
      type: String,
      required: false,
    },
  },
  computed: {
    lastUpdate() {
      const lastUpdated = this.contributors.length
        ? new Date(parseInt(this.contributors.at(0).updatedAt))
        : new Date(parseInt(this.createdAt));
      return lastUpdated.toLocaleString().slice(0, 10);
    },
    isReactive() {
      return this.total > this.maximum && this.reactive;
    },
    authorUsername() {
      return (
        this.author.username || this.$t('knowledge.contributors.deleted-user')
      );
    },
    lastUpdateContent() {
      return this.isArchive
        ? 'knowledge.contributors.archived'
        : 'knowledge.contributors.last-update';
    },
    lastUpdateDate() {
      return this.isArchive ? this.updatedAt : this.lastUpdate;
    },
  },
  methods: {
    colorStyle(username) {
      return {
        background:
          username !== null && username !== '?'
            ? userColor(username, 70, 50)
            : '#0081f9',
        color: 'white',
      };
    },
    getInitials(username) {
      const fullName = username.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
    handleClick(isStep = false) {
      this.$emit('click', isStep);
    },
  },
};
</script>

<style scoped lang="scss">
.contributors__container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  min-width: 0;
}

.initials-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 4px;

  .initials-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 2px solid white;
    margin-left: -6px;
    transition: margin-left 0.2s ease-in-out;
    &:first-of-type {
      margin-left: 0;
    }
  }
  .contributors__more {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    outline: 2px solid white;
    background: $grey-5-mayday;
    margin-left: -6px;
    transition: width 0.2s ease-in-out, margin-left 0.2s ease-in-out;

    .count,
    .see-more {
      transition: display 0.2s ease-in-out;
    }
    .see-more {
      display: none;
    }
  }

  &.reactive:hover {
    .initials-wrapper {
      margin-left: -12px;
      &:first-of-type {
        margin-left: 0;
      }
    }
    .contributors__more {
      width: 42px;
      margin-left: -12px;
      .count {
        display: none;
      }
      .see-more {
        display: block;
      }
    }
  }
}

.author {
  font-weight: 700;
  color: $grey-8-mayday;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.contributors__infos {
  display: flex;
  flex-direction: column;
  color: $grey-7-mayday;
  min-width: 0;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  b {
    font-weight: 700;
    color: $grey-8-mayday;
  }

  span {
    text-overflow: ellipsis;
  }

  .step-details {
    font-size: 12px;
    color: $grey-9-mayday;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    max-width: 100%;
    &:hover {
      color: $blue-mayday;
    }
  }
}
</style>
