export class TaskService {
  constructor(data) {
    this.data = data;
  }

  async getBacklogTasksCount() {
    return this.data.getBacklogTasksCount();
  }

  async getSettingsTaskViews(page = 1) {
    return this.data.getSettingsTaskViews(page);
  }

  async getTaskViews() {
    return this.data.getTaskViews();
  }

  async getTaskView(id) {
    return this.data.getTaskView(id);
  }

  async createTaskView({ name, filters, description }) {
    return this.data.createTaskView({ name, filters, description });
  }

  async updateTaskView({ id, name, filters, description }) {
    return this.data.updateTaskView({ id, name, filters, description });
  }

  async deleteTaskView(id) {
    return this.data.deleteTaskView(id);
  }
}
