<template>
  <BaseDropdownOptionsWrapper
    :options="options"
    is-horizontal
    :auto-hide="false"
    @select="handleSelectOption"
    @open="$emit('open')"
    @close="$emit('close')"
  />
</template>
<script>
import BaseDropdownOptionsWrapper from '@/components/BaseDropdownOptionsWrapper.vue';
export default {
  name: 'edit-node-options',
  components: {
    BaseDropdownOptionsWrapper,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    isOutdated: {
      type: Boolean,
      default: false,
    },
    userPermissions: {
      type: Object,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelectOption(e) {
      const key = e.detail[0];

      if (!key) return;

      if (key === 'RENAME') {
        return this.$emit('edit-label');
      } else if (key === 'CLONE') {
        return this.$emit('clone-kb');
      } else if (key === 'DUPLICATE') {
        return this.$emit('duplicate-in-same-location');
      } else if (key === 'EDIT_ICON') {
        return this.$emit('update-icon');
      } else if (key === 'EDIT_MINI_ICON') {
        return this.$emit('update-mini-icon');
      } else if (key === 'SET_OUTDATED') {
        return this.$emit('update-content-verification', true);
      } else if (key === 'DELETE') {
        return this.$emit('delete-node');
      } else if (key === 'SOFT_DELETE') {
        return this.$emit('delete-node', { softDeleted: true });
      }
    },
  },
  computed: {
    isFolder() {
      return !this.type;
    },
    options() {
      return [
        {
          key: 'RENAME',
          label: this.$t('knowledge.actions.rename'),
          icon: ['fal', 'pen'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        {
          key: 'CLONE',
          label: this.$t('knowledge.actions.clone-in-other-kb'),
          icon: ['fal', 'copy'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        {
          key: 'DUPLICATE',
          label: this.$t('knowledge.actions.duplicate-in-same-location'),
          icon: ['fal', 'object-group'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        ...(this.isRoot
          ? [
              {
                key: 'EDIT_ICON',
                label: this.$t('knowledge.actions.change-icon'),
                icon: ['fal', 'image-polaroid'],
              },
              {
                key: 'EDIT_MINI_ICON',
                label: this.$t('knowledge.actions.change-mini-icon'),
                icon: ['fal', 'image-polaroid'],
              },
            ]
          : []),
        ...(this.type && !this.isOutdated
          ? [
              {
                key: 'SET_OUTDATED',
                label: this.$t('knowledge.actions.outdate'),
                icon: ['fal', 'times-circle'],
              },
            ]
          : []),
        {
          key: 'DELETE',
          label: this.type
            ? this.$t('knowledge.actions.archive')
            : this.$t('knowledge.actions.delete'),
          icon: ['fal', 'archive'],
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          divider: true,
          type: 'danger',
        },
        {
          key: 'SOFT_DELETE',
          label: this.$t('knowledge.actions.soft-delete'),
          icon: ['fal', 'trash'],
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          type: 'danger',
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped></style>
