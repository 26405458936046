<template>
  <el-select
    :class="{ 'el-select__icon-options': optionsWithIcon }"
    :value="value"
    :filterable="searchable"
    :multiple="multiple"
    :value-key="valueKey"
    :size="size"
    style="width: 100%"
    @change="$emit('change', $event)"
  >
    <el-option
      v-for="option in options"
      :key="option[valueKey]"
      :label="option.label"
      :value="option"
      :size="size"
    >
      <font-awesome-icon v-if="option.icon" :icon="option.icon" />
      <span v-if="option.iconLabel" class="ml-2">{{ option.iconLabel }}</span>
    </el-option>
  </el-select>
</template>
<script>
export default {
  name: 'BaseSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    size: {
      type: String,
    },
  },
  computed: {
    optionsWithIcon() {
      return this.options.every((el) => !!el.icon);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-select__icon-options {
  :deep() .el-input__suffix {
    visibility: hidden;
  }
}

.el-select--md {
  :deep().el-input--md {
    height: 30px;
    .el-input__suffix-inner {
      .el-input__icon {
        line-height: 30px;
      }
    }
    .el-input__inner {
      height: 100%;
    }
  }
}
</style>
