<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="element-title">
        {{ $t('settings.card-settings-options.content-parameters.subtitle') }}
      </div>
      <base-button
        variant="primary"
        size="sm"
        :text="
          $t('settings.card-settings-options.content-parameters.add-category')
        "
        @click="openNewCategoryForm"
        class="mb-1"
      ></base-button>
    </div>

    <div class="element-description mb-3">
      {{ $t('settings.card-settings-options.content-parameters.description') }}
    </div>

    <div v-loading="isLoading" class="main">
      <div class="d-flex flex-column alin-items-left content-state">
        <div
          class="d-flex flex-row align-items-center py-1 content-state-header"
        >
          <IconWrapper
            class="title-icon-background p-1"
            :icon="contentStateIcon"
            :size="16"
          />
          <h4 class="content-state-label px-2 mb-0">
            {{
              $t(
                'settings.card-settings-options.content-parameters.content-state-label',
              )
            }}
          </h4>
          <el-badge class="col-auto default-tag" type="primary" size="mini">
            {{
              $t('settings.card-settings-options.content-parameters.default')
            }}
          </el-badge>
        </div>
        <div class="d-flex flex-column content-state-publication mt-3 ml-2">
          <p class="sub-header mb-0">
            {{
              $t(
                'settings.card-settings-options.content-parameters.header-publication',
              )
            }}
          </p>
          <div class="d-flex flex-row align-items-center mb-3 mt-1">
            <span
              class="label-tags d-flex flex-row align-items-center"
              :style="{
                backgroundColor:
                  contentStateLabels.publication.published.backgroundColor,
              }"
            >
              <font-awesome-icon
                :icon="[
                  contentStateLabels.publication.published.icon.iconType,
                  contentStateLabels.publication.published.icon.value,
                ]"
                class="pr-1"
                :style="{
                  color: contentStateLabels.publication.published.icon.color,
                }"
              />
              <p class="mb-0 label-name">
                {{
                  $t(
                    'settings.card-settings-options.content-parameters.published',
                  )
                }}
              </p>
            </span>
            <span
              class="label-tags d-flex flex-row align-items-center ml-2"
              :style="{
                backgroundColor:
                  contentStateLabels.publication.draft.backgroundColor,
              }"
            >
              <font-awesome-icon
                :icon="[
                  contentStateLabels.publication.draft.icon.iconType,
                  contentStateLabels.publication.draft.icon.value,
                ]"
                class="pr-1"
                :style="{
                  color: contentStateLabels.publication.draft.icon.color,
                }"
              />
              <p class="mb-0 label-name">
                {{
                  $t('settings.card-settings-options.content-parameters.draft')
                }}
              </p></span
            >
          </div>
        </div>
        <div class="d-flex flex-column content-state-verification ml-2 mb-2">
          <p class="sub-header mb-0">
            {{
              $t(
                'settings.card-settings-options.content-parameters.header-verification',
              )
            }}
          </p>
          <div class="d-flex flex-row align-items-center mt-1">
            <span
              class="label-tags d-flex flex-row align-items-center"
              :style="{
                backgroundColor:
                  contentStateLabels.verification.verified.backgroundColor,
              }"
            >
              <font-awesome-icon
                :icon="[
                  contentStateLabels.verification.verified.icon.iconType,
                  contentStateLabels.verification.verified.icon.value,
                ]"
                class="pr-1"
                :style="{
                  color: contentStateLabels.verification.verified.icon.color,
                }"
              />
              <p class="mb-0 label-name">
                {{
                  $t(
                    'settings.card-settings-options.content-parameters.verified',
                  )
                }}
              </p></span
            >
            <span
              class="label-tags d-flex flex-row align-items-center ml-2"
              :style="{
                backgroundColor:
                  contentStateLabels.verification.toVerify.backgroundColor,
              }"
            >
              <font-awesome-icon
                :icon="[
                  contentStateLabels.verification.toVerify.icon.iconType,
                  contentStateLabels.verification.toVerify.icon.value,
                ]"
                class="pr-1"
                :style="{
                  color: contentStateLabels.verification.toVerify.icon.color,
                }"
              />
              <p class="mb-0 label-name">
                {{
                  $t(
                    'settings.card-settings-options.content-parameters.to-verify',
                  )
                }}
              </p></span
            >
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div v-for="category in categories" :key="category.id">
          <Category
            :category="category"
            @create-label="createLabel"
            @update-category="updateCategory"
            @update-label="updateLabel"
            @delete-category="deleteCategory"
            @delete-label="openDeleteLabelModal"
          />
        </div>
      </div>
      <NewCategory
        :displayNewCategoryForm="displayNewCategoryForm"
        @add-category="createCategory"
        @reset-new-category-form="resetNewCategoryForm"
      />
      <!-- <DeleteContentLabelModal
        v-if="showDeleteLabelModal"
        :open="showDeleteLabelModal"
        :relatedContentsCount="relatedContentsCount"
        :valueToHave="labelToDelete"
        @delete="deleteLabel"
        @close="showDeleteLabelModal = false"
      /> -->
      <DangerDeleteDialog
        v-if="showDeleteLabelModal"
        :display="showDeleteLabelModal"
        :description="
          $t(
            'settings.card-settings-options.case-parameters.modals.delete.description',
          )
        "
        :title="
          $t(
            'settings.card-settings-options.case-parameters.modals.delete.title',
          )
        "
        :hasCallout="true"
        :callout="
          $tc(
            'settings.card-settings-options.case-parameters.modals.delete.callout',
            relatedContentsCount,
            { count: relatedContentsCount },
          )
        "
        :hasConfirm="true"
        @confirm="deleteLabel"
        @close="showDeleteLabelModal = false"
      />
    </div>
  </div>
</template>

<script>
import Category from '@/components/Settings/ContentParameters/CategoryDropdown';
import NewCategory from '@/components/Settings/ContentParameters/NewCategory';
import IconWrapper from '@/components/IconWrapper';
import DangerDeleteDialog from '@/components/Modals/DangerDeleteDialog';

export default {
  name: 'contentParameters',
  components: {
    Category,
    NewCategory,
    DangerDeleteDialog,
    IconWrapper,
  },
  data() {
    return {
      i18nRoot: 'settings.card-settings-options.content-parameters',
      mainTtitle: this.$t(
        'settings.card-settings-options.content-parameters.title',
      ),
      mainDesc: this.$t(
        'settings.card-settings-options.content-parameters.description',
      ),
      secTitle: this.$t(
        'settings.card-settings-options.content-parameters.subtitle',
      ),
      secDesc: this.$t(
        'settings.card-settings-options.content-parameters.subdescription',
      ),
      anchor: 'content-parameters',
      icon: 'tags',
      contentStateIcon: {
        iconType: 'fa',
        value: 'file-check',
        color: '#7d89a0',
      },
      contentStateLabels: {
        publication: {
          published: {
            icon: {
              iconType: 'fas',
              value: 'file-check',
              color: '#0081f9',
            },
            backgroundColor: '#0081f91a',
          },
          draft: {
            icon: {
              iconType: 'fas',
              value: 'drafting-compass',
              color: '#7d89a0',
            },
            backgroundColor: '#f2f5f9',
          },
        },
        verification: {
          verified: {
            icon: {
              iconType: 'fas',
              value: 'check-circle',
              color: '#67c23a',
            },
            backgroundColor: '#67c23a1a',
          },
          toVerify: {
            icon: {
              iconType: 'fas',
              value: 'times-circle',
              color: '#fd0044',
            },
            backgroundColor: '#fd00441a',
          },
        },
      },
      labels: [],
      categories: [],
      categoryOfNewLabel: null,
      displayNewCategoryForm: false,
      labelToUpdate: {
        categoryId: null,
        labelId: null,
      },
      updatedLabel: {
        id: null,
        name: '',
        icon: 'tags',
        color: '#0081F9',
      },
      updatedCategory: '',
      categoryToUpdate: null,
      showDeleteLabelModal: false,
      relatedContentsCount: 1,
      labelToDelete: '',
      deletionPayload: {},
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.categories =
      await this.$services.contentParameterCategories.getContentParameterCategories();
    this.isLoading = false;
  },
  methods: {
    formatCategoriesFromLabels() {
      const categories = [];
      const categoryNames = [
        ...new Set(this.labels.map((label) => label.categoryName)),
      ];
      categoryNames.forEach((category) => {
        categories.push({
          id: category,
          labels: this.labels.filter(
            (label) => label.categoryName === category,
          ),
        });
      });
      return categories;
    },
    resetForm() {
      this.resetNewLabel();
      this.categoryOfNewLabel = null;
    },
    resetNewLabel() {
      this.newLabel = {
        name: '',
        icon: 'tags',
        color: '#0081f9',
      };
    },
    resetEditLabelForm() {
      this.updatedLabel = {
        id: null,
        name: '',
        icon: 'tags',
        color: '#0081f9',
      };
      this.labelToUpdate = {
        categoryId: null,
        labelId: null,
      };
    },
    resetNewCategoryForm() {
      this.newCategory = '';
      this.displayNewCategoryForm = false;
    },
    openNewLabelForm(categoryId) {
      this.resetForm();
      this.categoryOfNewLabel = categoryId;
    },
    openEditLabelForm(event) {
      const { categoryId, labelId } = event;
      this.updatedLabel = JSON.parse(
        JSON.stringify(
          this.categories
            .filter((category) => category.id === categoryId)[0]
            .labels.filter((label) => label.id === labelId)[0],
        ),
      );
      this.labelToUpdate = { categoryId, labelId };
    },
    openNewCategoryForm() {
      this.displayNewCategoryForm = true;
    },
    openEditCategoryForm(categoryId) {
      this.updatedCategory = this.categories.filter(
        (category) => category.id === categoryId,
      )[0].name;
      this.categoryToUpdate = this.updatedCategory;
    },
    resetEditCategoryForm() {
      this.updatedCategory = '';
      this.categoryToUpdate = null;
    },
    async createCategory(event) {
      const name = event.trim();

      if (name) {
        let id = 0;
        if (this.categories.length) {
          id = this.categories[this.categories.length - 1].id + 1;
        }
        this.categories.push({
          id,
          name,
          labels: [],
          // can update/delete a category with no labels
          // if no labels, no entity in db, this is a fake category
          // see src/views/Settings/GeneralSettings/KnowledgeManageSettings/KnowledgeManageSettingsRowAction.vue
          userPermissions: {
            canUpdateContent: true,
            canDeleteContent: true,
          },
        });
      }

      this.resetNewCategoryForm();
    },
    async updateCategory({ oldName, newLabel }) {
      if (oldName !== undefined && newLabel) {
        const local_categories = [...this.categories];
        const categoryIdx = local_categories.findIndex(
          (c) => c.name === oldName,
        );
        if (categoryIdx !== -1) {
          local_categories[categoryIdx].name = newLabel;
          await this.$services.contentParameterCategories.updateContentParameterCategory(
            oldName,
            newLabel,
          );
        }
      } else {
        this.$message({
          message: 'Veuillez rentrez un nom valide',
          type: 'danger',
        });
      }
    },
    async deleteCategory({ id }) {
      const category = this.categories.find((category) => category.id === id);

      if (!category) return;

      const categoryName = category.name;

      this.categories = this.categories.filter((c) => c.name !== categoryName);

      if (!category.labels.length) return;

      try {
        await this.$services.contentParameterCategories.deleteContentParameterCategory(
          categoryName,
        );
      } catch (e) {
        this.$message({
          message: 'Une erreur est survenue, veuillez réessayer plus tard',
          type: 'danger',
        });
      }
    },

    async createLabel(event) {
      try {
        const newLabel =
          await this.$services.contentParameterLabels.createContentParameterLabel(
            event,
          );
        const categoryIdx = this.categories.findIndex(
          (c) => c.name === newLabel.categoryName,
        );
        if (categoryIdx !== -1) {
          this.categories[categoryIdx].labels.push(newLabel);
        }
      } catch (e) {
        return e;
      } finally {
        this.resetForm();
      }
    },
    async updateLabel(event) {
      try {
        const newEvent =
          await this.$services.contentParameterLabels.updateContentParameterLabel(
            event,
          );
        const indices = this.getLabelIndices(
          newEvent.categoryName,
          newEvent.id,
        );
        if (indices) {
          const { categoryIdx, labelIdx } = indices;
          const local_categories = [...this.categories];
          local_categories[categoryIdx].labels[labelIdx] = newEvent;
          this.categories = JSON.parse(JSON.stringify(local_categories));
        }
      } catch (e) {
        return e;
      } finally {
        this.resetEditLabelForm();
      }
    },
    async openDeleteLabelModal(payload) {
      this.labelToDelete = this.categories
        .find((category) => category.name === payload.categoryName)
        .labels.find((label) => label.id === payload.labelId).labelName;
      this.deletionPayload = payload;
      this.relatedContentsCount =
        await this.$services.parametricContents.filterContentsCount(
          undefined,
          [],
          [],
          undefined,
          undefined,
          undefined,
          [payload.labelId],
          undefined,
        );
      this.showDeleteLabelModal = true;
    },

    async deleteLabel() {
      const { categoryName, labelId } = this.deletionPayload;
      const indices = this.getLabelIndices(categoryName, labelId);
      if (indices) {
        const { categoryIdx, labelIdx } = indices;
        const local_categories = [...this.categories];
        local_categories[categoryIdx].labels.splice(labelIdx, 1);
        try {
          await this.$services.contentParameterLabels.deleteContentParameterLabel(
            labelId,
          );
          if (this.categories[categoryIdx].labels.length === 0) {
            await this.$services.contentParameterCategories.deleteContentParameterCategory(
              categoryName,
            );
            local_categories.splice(categoryIdx, 1);
          }
        } catch (e) {
          return e;
        } finally {
          this.categories = JSON.parse(JSON.stringify(local_categories));
        }
      }
    },
    getLabelIndices(categoryName, labelId) {
      const categoryIdx = this.categories.findIndex(
        (c) => c.name === categoryName,
      );
      if (categoryIdx === -1) return null;
      const labelIdx = this.categories[categoryIdx].labels.findIndex(
        (l) => l.id === labelId,
      );
      if (labelIdx === -1) return null;
      return {
        categoryIdx,
        labelIdx,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.label-name {
  font-size: 14px;
}

.label__span {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  border: 1px solid;
  margin-right: 8px;
  &:hover {
    box-shadow: 0px 5px 15px $shadow-4-mayday;
    cursor: pointer;
  }
}

.label__icon {
  width: 16px;
  height: 16px;
}

.settings-card {
  width: 70%;
}

.settings-card-header-description {
  color: $grey-6-mayday;
  font-size: 80%;
}

.subtitle {
  text-transform: uppercase;
  color: $grey-5-mayday;
}

.label {
  height: 28px;
}

.label {
  height: 28px;
}

.category .option,
.label .option {
  visibility: hidden;
  display: contents;
}

.category:hover .option,
.label:hover .option {
  visibility: visible;
}

.empty-category-info {
  font-style: italic;
  color: $grey-5-mayday;
  font-weight: bold;
  font-size: 12px;
}

.option__button {
  max-width: 20px;
  cursor: pointer;
  padding: 2px;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.trash {
  &:hover {
    color: $red-mayday;
  }
}

.add-label {
  color: $grey-5-mayday;

  &:hover {
    color: $blue-mayday;
  }
}

.label--input {
  border: none;
  color: $grey-9-mayday;
  padding: 0;
  &::placeholder {
    color: $grey-5-mayday;
  }
}

.main {
  min-height: 150px;
}

.title-icon-background {
  background: $grey-1-mayday;
  border-radius: 2px;
}

.title-icon-background :deep() .logo-container {
  color: $grey-4-mayday;
  max-width: 16px;
  max-height: 16px;
  display: flex;
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: bold;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
}
.content-state {
  background-color: $grey-1-mayday;
  padding: 2px 6px;
  border: solid 1px $grey-2-mayday;
  border-radius: 4px;
}

.content-state-label {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
}

.content-state-header {
  border-bottom: 1px solid $grey-2-mayday;
  padding-bottom: 2px;
}

.sub-header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
}

.label-tags {
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  box-sizing: border-box;
}
</style>
