<template>
  <div class="d-flex align-items-center redirection-url w-100 p-2">
    <!-- CONTENT INFO LOADED -->
    <el-input
      v-show="success"
      v-model="entityObject.label"
      @click.native="success = false"
      size="mini"
    >
      <div class="content-icon-wrapper" slot="prefix">
        <img
          v-if="entityObject.type === 'Article'"
          src="~assets/article-icon-v2.svg"
          class="content-icon"
        />
        <img
          v-else-if="entityObject.type === 'Diagnostic'"
          slot="prefix"
          src="~assets/diag-icon-v2.svg"
          class="content-icon"
        />
        <img
          v-else
          slot="prefix"
          src="~assets/diag-step-v2.svg"
          class="content-icon"
        />
      </div>
      <div class="content-icon-wrapper" slot="suffix">
        <font-awesome-icon
          :icon="['fa', 'times']"
          @click="resetStates"
          class="content-icon clearable"
        />
      </div>
    </el-input>
    <!-- URL INFO -->
    <el-input
      v-if="!success"
      v-model="redirectUrl"
      :placeholder="$t('knowledge.child.redirect.popover.placeholder')"
      :class="{ error }"
      size="mini"
      @keyup.enter.native="debounceValidateUrl()"
    >
      <font-awesome-icon
        slot="prefix"
        v-if="loading"
        class="content-icon"
        icon="spinner"
        spin
      />
    </el-input>
    <base-button
      :loading="loading"
      :text="$t('knowledge.child.redirect.popover.confirm')"
      @click="setContentRedirection()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash.debounce';

const errorTypes = [
  'url',
  'origin',
  'content',
  'id',
  'same-content',
  'knowledge',
];

export default {
  name: 'redirect-by-link-wrapper',
  props: {
    child: Object,
  },
  data() {
    return {
      loading: false,
      redirectUrl: this.child.redirection ? this.child.redirection.path : '',
      error: false,
      success: false,
      entityObject: {},
    };
  },
  methods: {
    resetStates() {
      this.redirectUrl = '';
      this.loading = false;
      this.error = false;
      this.success = false;
    },
    debounceValidateUrl: debounce(function () {
      return this.validateUrl();
    }, 500),
    async validateUrl() {
      if (!this.redirectUrl) return;

      this.loading = true;
      try {
        let url;
        // NOT AN URL
        try {
          url = new URL(this.redirectUrl);
        } catch (err) {
          throw new Error('url');
        }
        const rawUrl = url.pathname.split('/');
        const currentUrl = new URL(window.location.href);
        // NOT A MAYDAY URL
        if (url.origin !== currentUrl.origin) throw new Error('origin');
        // NOT A MAYDAY CONTENT
        if (!rawUrl.includes('content')) throw new Error('content');
        const entityId = rawUrl.pop();

        const regex = /knowledge\/(.*?)\/content/;
        const match = this.redirectUrl.match(regex);

        const lang = match ? match[1] : '';

        this.entityObject = await this.getContent({ id: entityId, lang });
        // NOT A VALID CONTENT
        if (this.entityObject.message) throw new Error('id');

        // IS THE SAME CONTENT
        if (this.child.id === this.entityObject.id)
          throw new Error('same-content');

        const childObject = await this.getContent({ id: this.child.id, lang });

        // NOT FROM THE SAME KNOWLEDGE
        if (childObject.knowledgeId !== this.entityObject.knowledgeId)
          throw new Error('knowledge');

        this.success = true;

        this.loading = false;
        return true;
      } catch (err) {
        this.error = true;
        this.loading = false;
        if (!errorTypes.includes(err.message)) {
          this.$message({
            message: this.$t(`knowledge.child.redirect.error.default`),
            type: 'error',
          });
          return false;
        }
        this.$message({
          message: this.$t(`knowledge.child.redirect.error.${err.message}`),
          type: 'error',
        });
        return false;
      }
    },
    async setContentRedirection() {
      this.error = false;

      if (!this.redirectUrl) {
        return this.$emit('update-redirection', { redirection: {} });
      }

      const validation = await this.validateUrl();
      if (validation) {
        const {
          id: entityId,
          type: entityType,
          returnLanguage: lang,
        } = this.entityObject;
        this.$emit('update-redirection', {
          redirection: { entityId, entityType, lang },
        });
      }
      this.success = false;
    },
    async getRedirection() {
      if (this.child.redirection && !this.child.redirection.isDeleted) {
        const { entityId, entityType, lang } = this.child.redirection;
        const path = await this.getTranslatedEntityUrl({
          entityId,
          entityType,
          lang,
        });
        this.redirectUrl = window.location.origin + path;
      }
    },
    ...mapActions('knowledgeModule', ['getContent', 'getTranslatedEntityUrl']),
  },
  async mounted() {
    await this.getRedirection();
  },
  watch: {
    redirectUrl() {
      if (this.redirectUrl === '') return this.resetStates();
      return this.debounceValidateUrl();
    },
  },
};
</script>

<style lang="scss" scoped>
button:focus {
  outline: none;
}

.error {
  :deep() input {
    border: 1px solid $red-mayday !important;
  }
}

.content-icon-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.content-icon {
  width: 14px;
  height: 14px;
}

.redirection-url {
  gap: 8px;
  width: 25vw;
}

.clearable {
  cursor: pointer;
  &:hover {
    color: $red-mayday;
  }
}
</style>
