<template>
  <div class="common-node-options">
    <!-- NEW NODES -->
    <AddNodeOptions
      v-if="!nodeType"
      class="mr-1"
      :parent-ids="parentIds"
      :can-create-content="Boolean(userPermissions.canCreateContent)"
      @open="$emit('open')"
      @close="$emit('close')"
      @add-child="$emit('add-child', $event)"
      @add-child-from-template="$emit('add-child-from-template')"
    />

    <!-- NODE EDITION -->
    <EditNodeOptions
      :type="nodeType"
      :is-outdated="isOutdated"
      :user-permissions="userPermissions"
      :is-root="isRoot"
      @open="$emit('open')"
      @close="$emit('close')"
      @edit-label="$emit('edit-label')"
      @update-content="$emit('update-content', $event)"
      @update-content-verification="
        $emit('update-content-verification', $event)
      "
      @clone-kb="handleOpenClone"
      @duplicate-in-same-location="handleDuplicateInSameLocation"
      @delete-node="handleDeleteNode"
      @update-icon="handleOpenModal('icon')"
      @update-mini-icon="handleOpenModal('miniIcon')"
    />

    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="deleteNode"
    />
    <ModalEvents
      modal-name="SoftDeleteContentModal"
      :uid="deleteModalUniqueKey"
      @choice="softDeleteNode"
    />
    <CloneKnowledgeBaseModal
      v-if="display.cloneTo"
      :display="display.cloneTo"
      :sourceType="sourceToClone.type"
      :sourceLabel="sourceToClone.label"
      :sourceId="sourceToClone.sourceId"
      @close="handleCloseModal"
      @clone-to-target="cloneTo"
    />

    <IconEditionModal
      v-if="display.icon || display.miniIcon"
      :current-icon="focusedIcon"
      :display="display.icon || display.miniIcon"
      @choice="updateContentIcon"
      @close="handleCloseModal"
      @delete="handleDeleteIcon"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddNodeOptions from './AddNodeOptions';
import EditNodeOptions from './EditNodeOptions';
import CloneKnowledgeBaseModal from 'components/Modals/CloneKnowledgeBaseModal/CloneKnowledgeBaseModal';
import ModalEvents from '@/components/Modals/ModalEvents';
import IconEditionModal from 'components/Modals/IconEditionModal/IconEditionModal.vue';

export default {
  name: 'common-node-options',
  components: {
    IconEditionModal,
    AddNodeOptions,
    EditNodeOptions,
    CloneKnowledgeBaseModal,
    ModalEvents,
  },
  props: {
    label: String,
    nodeId: String,
    nodeType: String,
    icon: Object,
    miniIcon: Object,
    isRoot: Boolean,
    isCase: Boolean,
    parentIds: Array,
    isOutdated: Boolean,
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.nodeId}`;
    },
    cloneType() {
      return !this.nodeType ? 'case' : 'contents';
    },
    focusedIcon() {
      let currentIcon = 'icon';
      if (this.display.miniIcon) currentIcon = 'miniIcon';
      return this[currentIcon] && this[currentIcon].iconType === 'fa'
        ? this[currentIcon]
        : { iconType: 'fa', value: 'folder', color: null };
    },
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeId',
      'focusKnowledgeValue',
      'focusContent',
    ]),
  },
  data() {
    return {
      display: {
        delete: false,
        cloneTo: false,
        icon: false,
        miniIcon: false,
      },
      sourceType: '',
      sourceToClone: {
        type: '',
        label: '',
        sourceId: null,
      },
    };
  },
  methods: {
    handleDuplicateInSameLocation() {
      this.sourceToClone = { type: this.cloneType };

      const clonePayload = {
        targetCaseId: this.parentIds.length > 2 ? this.parentIds.at(-2) : null, // Direct parent of cloned node or null if it is a rootCase
        targetKnowledgeId: this.focusKnowledgeId,
        targetKnowledge: this.focusKnowledgeValue,
      };

      if (this.cloneType === 'case') {
        clonePayload.caseName = this.label;
      }

      this.cloneTo(clonePayload);
    },
    handleOpenClone() {
      this.sourceType = this.cloneType;
      if (!this.nodeType) {
        // Node is a Case
        this.sourceToClone = {
          type: 'case',
          label: this.label,
          sourceId: this.nodeId,
        };
      } else {
        this.sourceToClone = {
          type: 'contents',
          label: this.$tc('knowledge.modals.clone-kb.contents-count'),
        };
      }
      this.handleOpenModal('cloneTo');
    },
    cloneTo(payload) {
      const payloadToSend = {
        ...payload,
      };

      if (!this.nodeType) {
        payloadToSend.caseId = this.nodeId;
      } else {
        payloadToSend.contentIds = [this.nodeId];
      }

      this.$emit('clone-to-target', {
        payload: payloadToSend,
        type: this.sourceToClone.type,
        closeCallback: this.handleCloseModal,
      });
    },
    async handleDeleteNode({ softDeleted } = {}) {
      let props = {
        display: true,
        label: this.label,
      };
      // CASE DELETE
      if (this.isCase) {
        let loadingMessage = this.$message({
          duration: 0,
          dangerouslyUseHTMLString: true,
          iconClass: 'display: none',
          message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
            'components.modals.delete-modal.loading',
          )}`,
        });
        const count =
          await this.$services.parametricContents.filterContentsCount(
            undefined,
            [],
            [this.nodeId],
            this.focusKnowledgeId,
          );
        loadingMessage.close();
        props.isCase = true;
        props.caseCount = count;
      } else {
        const contentId = this.nodeId;

        const { relatedCount, automationsCount } =
          await this.countLinkedReferences({
            contentId,
          });

        props = {
          ...props,
          id: contentId,
          relatedCount: relatedCount,
          automationsCount,
        };
      }

      const payload = {
        component: softDeleted
          ? 'SoftDeleteContentModal'
          : 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props,
      };
      this.$emit('open-modal');
      await this.openModal(payload);
    },
    deleteNode(choice) {
      if (choice) this.$emit('delete-node');
    },
    softDeleteNode(choice) {
      if (choice)
        this.$emit('delete-node', {
          softDeleted: true,
        });
    },
    updateContentIcon(icon) {
      let field = 'miniIcon';
      if (this.display.icon) field = 'icon';
      this.$emit('update-content-icon', { icon, field });
      this.display.icon = false;
      this.display.miniIcon = false;
    },
    handleDeleteIcon() {
      let field = 'icon';
      if (this.display.miniIcon) field = 'miniIcon';
      this.$emit('delete-content-icon', { field });
      this.display.miniIcon = false;
      this.display.icon = false;
    },
    handleOpenModal(name) {
      this.display[name] = true;
      this.$emit('open-modal');
    },
    handleCloseModal() {
      this.display.cloneTo = false;
      this.display.delete = false;
      this.display.icon = false;
      this.display.miniIcon = false;
      this.$emit('close-modal');
    },
    ...mapActions('modalsModule', ['openModal']),
    ...mapActions('knowledgeModule', ['countLinkedReferences']),
  },
};
</script>
