<template>
  <div class="labels-editor">
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      :disabled="true"
      permission-key="canUpdateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <el-tag
          v-for="tag in tags"
          :key="tag"
          class="mr-2 mt-2"
          :closable="hasPermissions"
          :type="type === 'diagnostic' ? '' : 'warning'"
          :disable-transitions="false"
          :disabled="hasPermissions"
          effect="plain"
          @close="handleClose(tag)"
          >{{ tag }}
        </el-tag>
      </template>
    </not-allowed-tooltip>
    <not-allowed-tooltip
      :user-permissions="userPermissions"
      permission-key="canUpdateContent"
    >
      <template v-slot:main-content="{ hasPermissions }">
        <el-input
          class="input-new-tag mt-2"
          :class="{ 'input-error': !isInputValueValid }"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        ></el-input>
        <base-button
          v-else
          class="mt-2"
          size="md"
          variant="secondary"
          :disabled="!hasPermissions"
          :text="$t('knowledge.diagnostic-builder.side-bar.search.cta')"
          @click="showInput"
        />
      </template>
    </not-allowed-tooltip>
  </div>
</template>

<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'labels-editor',
  components: {
    NotAllowedTooltip,
  },
  props: {
    type: {
      type: String,
      default: 'diagnostic',
    },
    tags: Array,
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: '',
    };
  },
  computed: {
    isInputValueValid() {
      return (
        !this.tags.includes(this.inputValue) && this.inputValue.trim() !== ''
      );
    },
  },
  methods: {
    handleClose(tag) {
      this.$emit('delete-tag', tag);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue && this.isInputValueValid) {
        this.$emit('add-tag', inputValue);
        this.inputVisible = false;
        this.inputValue = '';
      } else if (inputValue.trim() == '') {
        this.inputVisible = false;
        this.inputValue = '';
      }
    },
  },
};
</script>

<style lang="scss">
.labels-editor {
  max-width: 400px;
  max-height: 300px;
  overflow: auto;
}

.el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: #fff !important;
  border: 1px solid #dcdfe6;
  color: #606266 !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border: 1px solid #dcdfe6;
}

.input-new-tag {
  width: 120px;
  vertical-align: bottom;
  height: 100%;
}
</style>
