var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],class:_vm.base},[_c('div',{class:`${_vm.base}__header`},_vm._l((_vm.layout),function(section){return _c('div',{key:section.key,class:[
        `${_vm.base}__header__title`,
        section.icon ? `${_vm.base}__header__title-icon` : '',
        section.col,
      ]},[(!section.icon)?_c('span',[_vm._v(_vm._s(_vm.$t(`${_vm.pre}.${section.key}`)))]):_vm._e()])}),0),_c('div',{class:`${_vm.base}__items-wrapper`},_vm._l((_vm.mappings),function(mapping){return _c('div',{key:mapping.id,class:[
        `${_vm.base}__items-wrapper__item`,
        { [`${_vm.base}__items-wrapper__item-editable`]: mapping.edit },
      ]},[_c('div',{class:`${_vm.base}__items-wrapper__item col-4`},[_c('SsoAttributesMappingInput',{attrs:{"editable":mapping.edit,"type":"input","value":mapping.idpAttribute,"placeholder":_vm.$t(`${_vm.pre}.idpAttribute`)},on:{"input":function($event){return _vm.handleInput(mapping, 'idpAttribute', $event)}}})],1),_c('div',{class:`${_vm.base}__items-wrapper__item col-1 px-2`},[_c('SsoAttributesMappingInput',{attrs:{"editable":mapping.edit,"type":"select","options":_vm.operatorOptions,"value":_vm.resolveOperator(mapping.operator),"placeholder":_vm.$t(`${_vm.pre}.operator`)},on:{"input":function($event){return _vm.handleInput(mapping, 'operator', $event ? $event.id : null)}}})],1),_c('div',{class:`${_vm.base}__items-wrapper__item col-3`},[_c('SsoAttributesMappingInput',{attrs:{"editable":mapping.edit,"type":"input","value":mapping.value,"placeholder":_vm.$t(`${_vm.pre}.value`)},on:{"input":function($event){return _vm.handleInput(mapping, 'value', $event)}}})],1),_c('div',{class:`${_vm.base}__items-wrapper__item-icon`},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'arrow-right']}})],1),_c('div',{class:`${_vm.base}__items-wrapper__item col-2`},[_c('SsoAttributesMappingInput',{attrs:{"editable":mapping.edit,"type":"select","options":_vm.selectableUserLabels,"value":_vm.resolveUserLabel(mapping.companyUserLabelId),"placeholder":_vm.$t(`${_vm.pre}.companyUserLabelId`)},on:{"input":function($event){return _vm.handleInput(
              mapping,
              'companyUserLabelId',
              $event ? $event.id : null,
            )}}})],1),_c('div',{class:`${_vm.base}__items-wrapper__item-cta col-1`},[(!mapping.edit)?_c('BaseIconButton',{staticClass:"ml-1",attrs:{"icon":"pencil","variant":"tertiary","size":"sm"},on:{"click":function($event){return _vm.handleEdit(mapping)}}}):_vm._e(),(!mapping.edit)?_c('BaseIconButton',{staticClass:"ml-1",attrs:{"icon":"trash","variant":"tertiary","size":"sm"},on:{"click":function($event){return _vm.handleDelete(mapping)}}}):_vm._e(),(mapping.edit)?_c('BaseIconButton',{staticClass:"ml-1",attrs:{"icon":"save","variant":"tertiary","size":"sm"},on:{"click":function($event){return _vm.handleSave(mapping)}}}):_vm._e(),(mapping.edit)?_c('BaseIconButton',{staticClass:"ml-1",attrs:{"icon":"times","variant":"tertiary","size":"sm"},on:{"click":function($event){return _vm.handleCancel(mapping)}}}):_vm._e()],1)])}),0),_c('div',{class:`${_vm.base}__add-btn`},[_c('base-button',{attrs:{"variant":"primary","size":"sm","text":_vm.$t(`${_vm.pre}.add`),"disabled":_vm.ongoingEdition},on:{"click":_vm.handleAddMapping}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }