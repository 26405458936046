<template>
  <base-dropdown-options
    :options="optionsAsString"
    :icon="computedIcon"
    :trigger-text="triggerText"
    :disabled="disabled"
    :icon-only="iconOnly"
    :variant="variant"
    @select="$emit('select', $event)"
    @open="$emit('open')"
    @close="$emit('close')"
  ></base-dropdown-options>
</template>
<script>
export default {
  name: 'BaseDropdownOptionsWrapper',
  props: {
    options: [],
    isHorizontal: { type: Boolean, default: false },
    textOnly: { type: Boolean, default: false },
    triggerText: String,
    disabled: Boolean,
    icon: String,
    iconOnly: Boolean,
    variant: String,
  },
  computed: {
    optionsAsString() {
      return JSON.stringify(this.options);
    },
    computedIcon() {
      if (this.textOnly) {
        return '';
      }

      if (this.icon) {
        return this.icon;
      }

      return this.isHorizontal ? 'ellipsis-h' : 'ellipsis-v';
    },
  },
};
</script>
