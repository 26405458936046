<template>
  <BaseDropdownOptionsWrapper
    v-if="hasOptions"
    :options="dropdownOptions"
    isHorizontal
    iconOnly
    @select="handleSelectOption"
  />
</template>

<script>
import BaseDropdownOptionsWrapper from '@/components/BaseDropdownOptionsWrapper.vue';

export default {
  name: 'thread-status-dropdown',
  comments: {
    BaseDropdownOptionsWrapper,
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        SOLVED: true,
        ARCHIVED: true,
      }),
    },
  },
  computed: {
    hasOptions() {
      return Object.values(this.options).some(Boolean);
    },
    dropdownOptions() {
      return [
        ...(this.options.SOLVED
          ? [
              {
                key: 'SOLVED',
                icon: 'check',
                label: this.$t('threads.update-statuses.SOLVED'),
              },
            ]
          : []),
        ...(this.options.ARCHIVED
          ? [
              {
                key: 'ARCHIVED',
                icon: 'archive',
                label: this.$t('threads.update-statuses.ARCHIVED'),
                type: 'danger',
              },
            ]
          : []),
      ];
    },
  },
  methods: {
    handleSelectOption(e) {
      const key = e.detail[0];

      if (!key) return;

      this.$emit('update-status', key);
    },
  },
};
</script>

<style lang="scss" scoped></style>
