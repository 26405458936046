<template>
  <DangerDeleteDialog
    :display="localDisplay"
    :title="dialogContent.title"
    :description="dialogContent.description"
    :hasCallout="dialogContent.hasCallout"
    :callout="dialogContent.callout"
    hasConfirm
    @confirm="userChoice(true)"
    @close="userChoice(false)"
  />
</template>

<script>
import DangerDeleteDialog from '@/components/Modals/DangerDeleteDialog.vue';

export default {
  name: 'soft-delete-modal',
  components: {
    DangerDeleteDialog,
  },
  props: {
    display: Boolean,
    isCase: {
      type: Boolean,
      default: false,
    },
    caseCount: {
      type: Number,
      required: false,
    },
    automationsCount: {
      type: Number,
      required: false,
    },
    relatedCount: {
      type: Number,
      required: false,
    },
    label: String,
    bulkCount: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      localDisplay: this.display,
    };
  },
  computed: {
    calloutMessage() {
      if (this.isCase || (!this.relatedCount && !this.automationsCount)) {
        return '';
      }

      let message = '';

      if (this.relatedCount) {
        message += this.$tc(
          'components.modals.soft-delete-modal.content.callout.related-count',
          this.relatedCount,
          { count: this.relatedCount },
        );
      }

      if (this.automationsCount) {
        if (this.relatedCount) {
          message += ' \n ';
        }
        message += this.$tc(
          'components.modals.soft-delete-modal.content.callout.automations-count',
          this.automationsCount,
          { count: this.automationsCount },
        );
      }

      return message;
    },
    dialogContent() {
      return this.isCase
        ? {
            title: this.$tc(
              'components.modals.soft-delete-modal.case.title',
              this.bulkCount,
              { count: this.bulkCount, label: this.label },
            ),
            description: this.$t(
              'components.modals.soft-delete-modal.description',
            ),
            hasCallout: this.caseCount > 0,
            callout: this.$tc(
              'components.modals.soft-delete-modal.case.callout',
              this.caseCount,
            ),
          }
        : {
            title: this.$tc(
              'components.modals.soft-delete-modal.content.title',
              this.bulkCount,
              { count: this.bulkCount, label: this.label },
            ),
            description: this.$t(
              'components.modals.soft-delete-modal.description',
            ),
            hasCallout: this.relatedCount > 0 || this.automationsCount > 0,
            callout: this.calloutMessage,
          };
    },
  },
  methods: {
    userChoice(choice) {
      this.$emit('events', {
        eventName: 'choice',
        eventData: choice,
      });
      this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped></style>
