import { render, staticRenderFns } from "./StepMoverBaseCascader.vue?vue&type=template&id=4c048508&scoped=true&"
import script from "./StepMoverBaseCascader.vue?vue&type=script&lang=js&"
export * from "./StepMoverBaseCascader.vue?vue&type=script&lang=js&"
import style0 from "./StepMoverBaseCascader.vue?vue&type=style&index=0&id=4c048508&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c048508",
  null
  
)

export default component.exports