<template>
  <div class="mb-2">
    <div v-for="child in children" :key="child.title">
      <component
        :is="child.component"
        v-bind="child.props"
        class="mb-2"
        @click:cta="focus = child"
      />
    </div>
    <setting-drawer
      v-if="currentIntegration"
      :show="!!currentIntegration"
      :integration="currentIntegration"
      @close="closeDrawer()"
      @update-integration="activeIntegrations = $event"
    >
      <template v-slot:default="scope">
        <component
          :is="currentIntegration.is"
          :integration="currentIntegration"
          @close="scope.close()"
        />
      </template>
    </setting-drawer>
  </div>
</template>

<script>
import IpRestrictionSettings from './IpRestrictionSettings/IpRestrictionSettings.vue';
import IpRestrictionSettingsDrawer from './IpRestrictionSettings/IpRestrictionSettingsDrawer.vue';
import SsoAttributesMappingSettings from './SsoAttributesMappingSettings/SsoAttributesMappingSettings.vue';
import IntegrationSettingsDrawer from '@/components/Settings/IntegrationSettings/IntegrationSettingsDrawer/IntegrationSettingsDrawer.vue';

const pre = 'settings-layout.menu-data.authentication';

export default {
  name: 'authentication-settings',
  props: {
    children: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    'setting-drawer': IntegrationSettingsDrawer,
    IpRestrictionSettings,
    IpRestrictionSettingsDrawer,
    SsoAttributesMappingSettings,
  },

  computed: {
    currentIntegration() {
      if (!this.focus) return null;
      const component = `${this.focus.component}Drawer`;
      const i18nPrefix = `${pre}.${this.focus.i18nPre}`;
      return {
        is: component,
        label: this.$t(`${i18nPrefix}.drawer.label`),
        description: this.$t(`${i18nPrefix}.drawer.description`),
        logo: this.focus.icon,
        raw: true,
        active: true,
        features: [],
        links: [],
      };
    },
  },

  data() {
    return {
      isOpen: false,
      focus: null,
    };
  },

  methods: {
    closeDrawer() {
      this.focus = null;
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
