<template>
  <div :style="heightStyle">
    <div v-if="label" class="label font-bold">
      {{ label }}
    </div>
    <div class="overflow" :class="{ 'fix-height': !showMore }">
      <div ref="tags" class="d-flex flex-wrap tags-wrapper">
        <div class="p-1 flex-none" v-for="tag in tags" :key="tag.id">
          <slot name="tag" :tag="tag"></slot>
        </div>
      </div>
    </div>
    <base-button
      v-if="tagsHeight > WRAPPER_HEIGHT"
      class="mt-1"
      variant="tertiary"
      :text="
        showMore
          ? $t('knowledge.search-admin.show-less')
          : $t('knowledge.search-admin.show-more')
      "
      @click="showMore = !showMore"
    />
  </div>
</template>

<script>
export default {
  name: 'tags-wrapper',
  props: {
    label: String,
    tags: Array,
    selectedTags: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      default: 36,
    },
    defaultShowMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: false,
      WRAPPER_HEIGHT: this.size,
      tagsHeight: this.size,
    };
  },
  watch: {
    tags() {
      this.$nextTick(() => {
        this.tagsHeight = this.$refs.tags.clientHeight;
      });
    },
  },
  computed: {
    heightStyle() {
      return { '--height': this.size + 'px' };
    },
  },
  mounted() {
    this.tagsHeight = this.$refs.tags.clientHeight;
    this.showMore = this.defaultShowMore;
  },
};
</script>

<style lang="scss" scoped>
.tags-wrapper {
  margin: 0px -4px;
}
.fix-height {
  height: var(--height);
}
.overflow {
  overflow: hidden;
}
.flex-none {
  flex: none;
}
.label {
  font-size: 12px;
  color: $grey-7-mayday;
}
.font-bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
