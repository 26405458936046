<template>
  <div slot="title" class="d-flex flex-column mx-4 mt-4 drawer-header">
    <div class="d-flex justify-content-between">
      <div class="title">{{ title }}</div>

      <base-button
        v-if="close"
        variant="tertiary"
        icon="times"
        icon-only
        @click="sendCloseEvent"
      />
    </div>

    <span class="desc text-muted">{{ desc }}</span>
  </div>
</template>

<script>
export default {
  name: 'drawer-header',
  props: {
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    close: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    sendCloseEvent() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-header {
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.5px;
  }

  .desc {
    font-family: Lato;
    font-style: small;
    font-size: 12px;
    line-height: 1.3;
  }

  .close-icon-notification {
    cursor: pointer;
    color: $grey-4-mayday;
    &:hover {
      color: $grey-6-mayday;
    }
  }
}
</style>
