export class SettingServiceData {
  constructor(client) {
    this.client = client;
  }

  async getCompanyIpRanges() {
    const res = await this.client.get('/settings/company/ip-ranges');
    return res.data;
  }

  async setCompanyIpRanges(ipRanges) {
    const res = await this.client.post('/settings/company/ip-ranges', {
      ipRanges,
    });
    return res.data;
  }

  async deleteCompanyIpRanges() {
    const res = await this.client.delete('/settings/company/ip-ranges');
    return res.data;
  }

  async getApiOAuthAppRegistrations() {
    const res = await this.client.get('/settings/api-oauth-app-registrations');
    return res.data;
  }

  async createApiOAuthAppRegistration() {
    const res = await this.client.post('/settings/api-oauth-app-registrations');
    return res.data;
  }

  async updateApiOAuthAppRegistration(id, key, value) {
    const res = await this.client.patch(
      `/settings/api-oauth-app-registrations/${id}`,
      {
        key,
        value,
      },
    );
    return res.data;
  }

  async deleteApiOAuthAppRegistration(id) {
    const res = await this.client.delete(
      `/settings/api-oauth-app-registrations/${id}`,
    );
    return res.data;
  }

  // SsoProfile

  async getSsoProfiles() {
    const res = await this.client.get(
      '/settings/company/sso-profiles?size=100',
    );
    return res.data;
  }

  async createSsoProfile(name, groupIds, roleIds) {
    const res = await this.client.post('/settings/company/sso-profiles', {
      name,
      groupIds,
      roleIds,
    });
    return res.data;
  }

  async updateSsoProfile(id, name, groupIds, roleIds) {
    const res = await this.client.put(`/settings/company/sso-profiles/${id}`, {
      name,
      groupIds,
      roleIds,
    });
    return res.data;
  }

  async deleteSsoProfile(id) {
    const res = await this.client.delete(
      `/settings/company/sso-profiles/${id}`,
    );
    return res.data;
  }

  // SsoProfileMapping

  async getSsoProfileMappings() {
    const res = await this.client.get(
      '/settings/company/sso-group-mappings?size=100',
    );
    return res.data;
  }

  async createSsoProfileMapping(idpAttribute, operator, value, profileId) {
    const res = await this.client.post('/settings/company/sso-group-mappings', {
      idpAttribute,
      operator,
      value,
      profileId,
    });
    return res.data;
  }

  async updateSsoProfileMapping(id, idpAttribute, operator, value, profileId) {
    const res = await this.client.put(
      `/settings/company/sso-group-mappings/${id}`,
      {
        idpAttribute,
        operator,
        value,
        profileId,
      },
    );
    return res.data;
  }

  async deleteSsoProfileMapping(id) {
    const res = await this.client.delete(
      `/settings/company/sso-group-mappings/${id}`,
    );
    return res.data;
  }

  // SsoUserLabelMapping

  async getSsoUserLabelMappings() {
    const res = await this.client.get(
      '/settings/company/sso-user-label-mappings?size=100',
    );
    return res.data;
  }

  async createSsoUserLabelMapping(idpAttribute, operator, value, labelId) {
    const res = await this.client.post(
      '/settings/company/sso-user-label-mappings',
      {
        idpAttribute,
        operator,
        value,
        labelId,
      },
    );
    return res.data;
  }

  async updateSsoUserLabelMapping(id, idpAttribute, operator, value, labelId) {
    const res = await this.client.put(
      `/settings/company/sso-user-label-mappings/${id}`,
      {
        idpAttribute,
        operator,
        value,
        labelId,
      },
    );
    return res.data;
  }

  async deleteSsoUserLabelMapping(id) {
    const res = await this.client.delete(
      `/settings/company/sso-user-label-mappings/${id}`,
    );
    return res.data;
  }
}
