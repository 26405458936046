const buildCasePathFromCase = (collection) =>
  collection && collection.path
    ? collection.path.map((path) => ({
        id: path.id,
        label: path.label,
      }))
    : [];

export default {
  focusStep: (state) => state.focusStep, // TMP only for web-parametric bug feedbacks on steps
  /**
   * KNOWLEDGE
   */

  knowledges: (state) => state.knowledges,
  mappedKnowledges: (state) =>
    state.knowledges.reduce((acc, knowledge) => {
      acc[knowledge.id] = knowledge;
      return acc;
    }, {}),
  publicKnowledges(getters) {
    const { knowledges } = getters;
    return knowledges.filter((k) => k.publicData.isPublic);
  },
  focusKnowledge: (state) => state.focusKnowledge,
  focusKnowledgeLanguages: (state) =>
    state.focusKnowledge ? state.focusKnowledge.supportedLanguages : [],
  focusKnowledgeDefaultLanguage: (state) =>
    state.focusKnowledge ? state.focusKnowledge.defaultLanguage : null,
  focusKnowledgeLabel: (state) => {
    const { focusKnowledge, editingLanguage } = state;

    if (!focusKnowledge) return null;

    if (!focusKnowledge.labelTranslations) return focusKnowledge.label;

    return (
      focusKnowledge.labelTranslations[editingLanguage] || focusKnowledge.label
    );
  },

  focusKnowledgeValue: (state) =>
    state.focusKnowledge ? state.focusKnowledge.value : null,
  focusKnowledgeId: (state) =>
    state.focusKnowledge ? state.focusKnowledge.id : null,
  focusKnowledgeIsPublic: (state) =>
    state.focusKnowledge ? state.focusKnowledge.publicData.isPublic : false,
  focusKnowledgeRoots: (state) => state.focusKnowledgeRoots,
  knowledgeEditorIsLoading: (state) => state.knowledgeEditorIsLoading,
  focusKnowledgeRootsIsLoading: (state) => state.focusKnowledgeRootsIsLoading,
  editingLanguage: (state, getters) =>
    state.editingLanguage || getters.focusKnowledgeDefaultLanguage || 'default',
  navigationLanguage: (state, getters) =>
    state.navigationLanguage || getters.focusKnowledgeDefaultLanguage,
  focusKnowledgeIsMultilinguale: (state) =>
    !!state.focusKnowledge &&
    state.focusKnowledge.supportedLanguages &&
    state.focusKnowledge.supportedLanguages.length > 1,
  hasPlugins: (state) => (type) =>
    state.focusKnowledge.plugins.findIndex((p) => p.type === type) > -1,
  knowledgeLanguages: (state) => {
    const { knowledges } = state;

    if (!knowledges || !knowledges.length) return [];

    const uniqueLanguages = new Set();

    knowledges.forEach((knowledge) => {
      knowledge.supportedLanguages.forEach((lang) => uniqueLanguages.add(lang));
    });

    return Array.from(uniqueLanguages.values());
  },
  searchableLanguages: (_state, getters, _rootState, rootGetters) => {
    const { hasCompanyPreferenceWithValue } = rootGetters;
    const { knowledgeLanguages } = getters;
    const languages = [...knowledgeLanguages];

    // Avoid showing language dropdown for clients who have KBs in
    // default + fr-fr
    if (languages.length > 1 && languages.includes('default')) return [];

    // Only one language across KB, no need for language dropdown
    if (languages.length === 1) return;

    // Special case for post migration default to languages
    if (hasCompanyPreferenceWithValue('HAS_ALL_LANGUAGES_SEARCH')) {
      languages.unshift('all');
    }

    return languages;
  },

  /**
   * COLLECTIONS NAVIGATION
   */
  casePath: (state, getters, _rootState, rootGetters) => {
    if (rootGetters.isParametric) {
      return rootGetters['kbStore/casePath'];
    }

    const { focusCollectionId } = state;

    const { focusContent, focusCollection, getNavigatorLookupMapCase } =
      getters;

    if (focusCollectionId) {
      return buildCasePathFromCase(focusCollection);
    } else {
      if (
        !focusContent ||
        !focusContent.caseParameters ||
        !focusContent.caseParameters[0]
      ) {
        return [];
      }

      const parentCollection = getNavigatorLookupMapCase(
        focusContent.caseParameters[0].split('/').at(-1),
      );

      const casePath = buildCasePathFromCase(parentCollection);

      casePath.push({ id: focusContent.id, label: focusContent.label });

      return casePath;
    }
  },
  focusPath: (state, getters) => {
    const { focusCollectionId } = state;

    const { focusContent, focusCollection } = getters;

    if (focusCollectionId) {
      return focusCollection && focusCollection.path
        ? focusCollection.path.map((path) => path.id)
        : [];
    } else {
      return focusContent &&
        focusContent.caseParameters &&
        focusContent.caseParameters[0]
        ? focusContent.caseParameters[0].split('/')
        : [];
    }
  },
  collectionsViewerIsLoading: (state) => state.collectionsViewerIsLoading,
  focusCollectionId: (state) => state.focusCollectionId,
  focusCollection: (state) => state.focusCollection,
  focusCollectionLanguages: (state) =>
    state.focusCollection ? state.focusCollection.setLanguages : [],
  focusContentId: (state) => state.focusContentId,
  focusContent: (state) => state.focusContent,
  focusContentConcepts: (state) => {
    if (state.focusContentConcepts) {
      return state.focusContentConcepts;
    }
    return state.focusContent.denormalizedConcepts;
  },
  updateStatus: (state) => state.updateStatus,
  focusContentContributors: (state) => state.focusContentContributors,
  focusContentBacklinks: (state) => state.focusContentBacklinks,
  focusContentLanguages: (state) =>
    state.focusContent ? state.focusContent.setLanguages : [],
  focusItemId: (state) => (isFolder) => {
    return isFolder ? state.focusCollectionId : state.focusContentId;
  },

  getNavigatorLookupMapKnowledge: (state) => (knowledgeId) => {
    return state.navigatorLookupMap[`Knowledge-${knowledgeId}`];
  },

  getNavigatorLookupMapCase: (state) => (caseId) => {
    return state.navigatorLookupMap[`Case-${caseId}`];
  },

  getNavigatorLookupMapContent: (state) => (contentId) => {
    return state.navigatorLookupMap[`Content-${contentId}`];
  },

  getNavigatorLookupMapCaseParent: (state) => (caseId) => {
    return Object.values(state.navigatorLookupMap).find(
      (el) =>
        ['Case', 'Hierarchy'].includes(el.__typename) &&
        el.childrenOrder.find(
          (c) => c.entityType === 'Case' && c.id === caseId,
        ),
    );
  },

  getNavigatorLookupMapNode: (state) => (key) => {
    return state.navigatorLookupMap[key];
  },

  getCaseOrderedChildren: (state, getters) => (orderedChildrenLookupKeys) => {
    if (typeof orderedChildrenLookupKeys === 'string') {
      const { getNavigatorLookupMapCase } = getters;
      const collection = getNavigatorLookupMapCase(orderedChildrenLookupKeys);
      if (!collection) return [];
      orderedChildrenLookupKeys = collection.childrenOrder.map(
        ({ id, entityType }) => `${entityType}-${id}`,
      );
    }

    if (!orderedChildrenLookupKeys || !orderedChildrenLookupKeys.length)
      return [];

    return orderedChildrenLookupKeys
      .map((lookupKey) => state.navigatorLookupMap[lookupKey])
      .filter(Boolean);
  },
  focusCollectionContentsResults: (state) =>
    state.focusCollectionContentsResults,
  focusCollectionContentsTotal: (state) => state.focusCollectionContentsTotal,
  focusCollectionContentsPages: (state) => state.focusCollectionContentsPages,
  collectionSearch: (state) => state.collectionSearch,
  collectionSearchFilter: (state) => state.collectionSearch.filter,
  collectionSearchPage: (state) => state.collectionSearch.page,
  getContentParameterLabels:
    ({ contentParameterLabels }) =>
    (labelIds) =>
      contentParameterLabels.filter((label) => labelIds.includes(label.id)),
  toggleNavigationBar: (state) => state.toggleNavigationBar,
  /**
   * ARCHIVE FOCUS
   */
  archiveContent: (state) => state.archiveContent,
  archiveContentKnowledge: (state) => state.archiveContent.knowledgeId,
  archiveHasFormerCaseParents: (state) =>
    state.archiveFormerCaseParents.length > 0,
  archiveDescendents: (state) => state.archiveDescendents,
  archiveFormerMainAncestor: (state) => state.archiveFormerMainAncestor,
  archiveDeletionDate: (state) => {
    try {
      const timestampDate = state.archiveDeletionDate;
      if (!timestampDate) return null;
      const date = parseInt(timestampDate, 10);
      return new Date(date).toLocaleDateString();
    } catch (err) {
      return null;
    }
  },
  archiveError: (state) => state.archiveError,
  mainCaseArchiveLabel: (state) =>
    state.mainCaseArchive.case ? state.mainCaseArchive.case.label : '',

  /**
   * DRAG N DROP
   */

  dragAndDrop: (state) => {
    return state.dragAndDrop;
  },

  getDragAndDropNode: (state) => (id, type) => {
    const { dragAndDrop } = state;
    const entityType = type ? 'Content' : 'Case';
    return dragAndDrop[`${entityType}-${id}`];
  },

  navigatorNodeIsLoading: (state) => state.navigatorNodeIsLoading,

  /**
   * IMPORT ARTICLE
   */

  importArticleIsLoading: (state) => state.importArticleIsLoading,

  /**
   * TEMPLATE
   */

  templates: (state) => state.templates,
  focusTemplate: (state) => state.focusTemplate,
  templatesDict: (state) =>
    Object.fromEntries(
      state.templates.map((template) => [template.id, template]),
    ),
  templatesPath: (state, getters) =>
    state.templatesPath.map((id) => ({
      id,
      label: getters.templatesDict[id].content.label,
    })),
  templatesPathIds: (state) => state.templatesPath.map((id) => id),

  /**
   * PRODUCT
   */
  collectionParents: (state) => state.collectionParents,

  /**
   * MAYDAY_EDITOR
   */
  fileUploadError: (state) => state.fileUploadError,
};
