var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category"},[_c('settings-dropdown',{attrs:{"force-open":_vm.forceOpen},on:{"close":function($event){_vm.forceOpen = false}}},[_c('template',{slot:"title"},[_c('settings-dropdown-capitalized-column',{staticClass:"category-name",attrs:{"text":_vm.category.name,"icon":_vm.icon,"color":'#b8bfcd',"is-editing":_vm.editCategoryName,"can-edit":""},on:{"edit-text":function($event){_vm.editCategoryName = true},"cancel":function($event){_vm.editCategoryName = false},"update-text":_vm.handleUpdateCategory}}),_c('div',{staticClass:"labels-count"},[_vm._v(" "+_vm._s(_vm.$tc('settings.permissions.user-labels.count', _vm.count, { count: _vm.count }))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('el-tooltip',{attrs:{"content":_vm.$t(
              'settings.card-settings-options.content-parameters.create-tooltip',
            ),"placement":"top"}},[_c('base-button',{attrs:{"size":"sm","variant":"tertiary","icon-only":true,"icon":"plus"},on:{"click":_vm.handleCreateLabel}})],1),_c('KnowledgeManageSettingsRowAction',{staticClass:"ml-1",attrs:{"displayIsDefault":false,"displayModifyIcon":false,"user-permissions":_vm.category.userPermissions},on:{"edit-label":function($event){_vm.editCategoryName = true},"trigger-delete":function($event){_vm.displayDangerDeleteModal = true}}})],1)],1),_c('template',{slot:"dropdown-content"},[(_vm.labels.length === 0 && !_vm.edit.label)?_c('EmptyCategory'):_vm._e(),_vm._l((_vm.labels),function(label){return _c('div',{key:label.id,staticClass:"row justify-content-start mr-0 align-items-center label-line"},[_c('LabelContent',{attrs:{"category":_vm.category,"label":label},on:{"edit-label":_vm.updateLabel,"remove-label":function($event){return _vm.$emit('delete-label', $event)}}})],1)}),(_vm.edit.label)?_c('NewLabel',{on:{"add-label":function($event){return _vm.createLabel($event, _vm.category)},"reset-form":function($event){_vm.edit.label = false}}}):_vm._e()],2)],2),(_vm.displayDangerDeleteModal)?_c('DangerDeleteDialog',{attrs:{"display":_vm.displayDangerDeleteModal,"description":_vm.$t('components.modals.delete-modal.category.description', {
        name: _vm.category.name,
      }),"title":_vm.$t('components.modals.delete-modal.category.title'),"hasCallout":true,"callout":_vm.$tc('components.modals.delete-modal.category.callout', _vm.labels.length, {
        count: _vm.count,
      }),"hasConfirm":true},on:{"confirm":_vm.deleteCategory,"close":function($event){_vm.displayDangerDeleteModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }