import gql from 'graphql-tag';

export default {
  getBacklogTasksCount: gql`
    query getBacklogTasksCount {
      backlogTasksCount: getBacklogTasksCount
    }
  `,

  getSettingsTaskViews: gql`
    query getSettingsTaskViews($page: Int) {
      getSettingsTaskViews(page: $page) {
        page
        pages
        results {
          id
          name
          description
          filters
        }
      }
    }
  `,

  getTaskViews: gql`
    query getTaskViews {
      getTaskViews {
        views {
          id
          name
          description
          filters
        }
        restricted
      }
    }
  `,

  getTaskView: gql`
    query getTaskView($id: ID!) {
      taskView(id: $id) {
        id
        name
        description
        filters
      }
    }
  `,

  createTaskView: gql`
    mutation createTaskView($input: TaskViewInput) {
      createTaskView(input: $input) {
        id
        name
        description
        filters
      }
    }
  `,

  updateTaskView: gql`
    mutation updateTaskView($input: TaskViewInput) {
      updateTaskView(input: $input) {
        id
        name
        description
        filters
      }
    }
  `,

  deleteTaskView: gql`
    mutation deleteTaskView($id: ID!) {
      deleteTaskView(id: $id)
    }
  `,
};
