<template>
  <div>
    <div
      v-for="userLabelCategory in userLabelCategories"
      :key="userLabelCategory.id"
    >
      <LabelCategorySettingsDropdown
        v-bind="userLabelCategory"
        @update-category="$emit('update-category', $event)"
        @update-label="$emit('update-label', $event)"
        @create-label="$emit('create-label', $event)"
        @delete-label="$emit('delete-label', $event)"
        @edit-icon="handleEditIconCategory"
        @trigger-delete="handleDeleteCategory"
      />
    </div>

    <DangerDeleteDialog
      v-if="modals.delete"
      :display="modals.delete"
      :title="$t('components.modals.delete-modal.label.title')"
      :description="
        $t('components.modals.delete-modal.label.description', {
          name: toDeleteCategory.name,
        })
      "
      :callout="
        $tc(
          'components.modals.delete-modal.label.callout',
          toDeleteCategory.companyUserLabels.length,
          {
            count: toDeleteCategory.companyUserLabels.length,
          },
        )
      "
      :hasConfirm="true"
      :hasCallout="true"
      @confirm="deleteCategory"
      @close="modals.delete = false"
    />

    <IconEditionModal
      v-if="modals.icon"
      :display="modals.icon"
      :current-icon="toUpdateCategory"
      :editionOptions="iconOptions"
      title="settings.permissions.user-labels.icon-edition-title"
      description="settings.permissions.user-labels.icon-edition-description"
      @choice="updateCategoryIcon"
      @delete="$emit('delete-content-icon')"
      @close="modals.icon = false"
    />
  </div>
</template>

<script>
import LabelCategorySettingsDropdown from './LabelCategorySettingsDropdown';
import IconEditionModal from '@/components/Modals/IconEditionModal/IconEditionModal';
import DangerDeleteDialog from '../../../../../components/Modals/DangerDeleteDialog.vue';

export default {
  name: 'label-category-settings-table',
  components: {
    LabelCategorySettingsDropdown,
    IconEditionModal,
    DangerDeleteDialog,
  },
  props: {
    userLabelCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modals: {
        icon: false,
        delete: false,
      },
      toUpdateCategory: {},
      toDeleteCategory: {},
      iconOptions: {
        emoji: false,
        fa: true,
        upload: false,
        link: false,
      },
    };
  },
  methods: {
    // TRIGGER MODAL
    handleDeleteCategory(category) {
      this.toDeleteCategory = category;
      this.modals.delete = true;
    },

    handleEditIconCategory(category) {
      this.toUpdateCategory = {
        ...category,
        value: category.icon,
        iconType: 'fa',
      };
      this.modals.icon = true;
    },

    // CATEGORY ACTIONS MODAL
    deleteCategory() {
      this.$emit('delete-category', this.toDeleteCategory.id);
      this.modals.delete = false;
    },

    updateCategoryIcon({ value, color }) {
      this.$emit('update-category', {
        ...this.toUpdateCategory,
        icon: value,
        color,
      });
      this.modals.icon = false;
    },
  },
};
</script>
