import { rawFilesToFormData } from '../../../utils/rawFilesToFormData';
import { ContentDataLoaders } from './content.dataloaders';

export class ParametricContentService {
  constructor(data) {
    this.data = data;
    this.dataLoader = new ContentDataLoaders(this);
  }

  /**
   * CREATE METHODS
   */
  async createContent({
    knowledge,
    knowledgeId,
    type,
    label,
    parameters,
    caseParameters,
    accessRestrictions,
    labels,
    templateId,
    lang,
  }) {
    return this.data.createContent(
      knowledge,
      knowledgeId,
      type,
      label,
      parameters,
      caseParameters,
      accessRestrictions,
      labels,
      templateId,
      lang,
    );
  }

  async addChild(parentId, type, label, lang, isStaged = false) {
    return this.data.addChild(parentId, type, label, lang, isStaged);
  }

  async createContribution({
    knowledge,
    label,
    body,
    parameters,
    attachments,
  }) {
    return this.data.createContribution(
      knowledge,
      label,
      body,
      parameters,
      attachments,
    );
  }

  async attachFiles(files) {
    return this.data.attachFiles(files);
  }

  async importFiles(files) {
    return this.data.importFiles(files);
  }

  async brainImportFiles({
    contentId,
    contentType,
    lang,
    importType,
    rawFiles,
    settings,
  }) {
    const files = rawFilesToFormData(rawFiles);

    return this.data.brainImportFiles({
      contentId,
      contentType,
      lang,
      importType,
      files,
      settings,
    });
  }

  async brainMergeFiles({ contentId, lang, rawFiles, settings }) {
    const files = rawFilesToFormData(rawFiles);
    return this.data.brainMergeFiles({
      contentId,
      lang,
      files,
      settings,
    });
  }

  async addSatisfactionMark({ contentId, thumbType, lang }) {
    return this.data.addSatisfactionMark(contentId, thumbType, lang);
  }

  async setContentRedirection({ contentId, entityId, entityType, lang }) {
    return this.data.setContentRedirection(
      contentId,
      entityId,
      entityType,
      lang,
    );
  }

  /**
   * READ METHODS
   */
  async get(id, lang, isStaged) {
    return this.data.getContentById(id, lang, isStaged);
  }
  async getContentChildren(id, lang) {
    return this.data.getContentChildrenById(id, lang);
  }

  async getLight(id, lang) {
    return this.dataLoader.getById(lang).load(id);
  }

  async getManyLight(ids, lang) {
    return this.data.getManyLightContentsById(ids, lang);
  }

  async getConcepts(id, lang) {
    return this.data.getContentConceptsById(id, lang);
  }

  async getBacklinks(id) {
    const res = await this.data.getBacklinksById(id);
    return res.documents;
  }

  async getWithAncestors(id, lang, rootId = null, isStaged = false) {
    return this.data.getContentByIdWithAncestors(id, lang, rootId, isStaged);
  }

  async getWithEnriched(id, skipAncestors) {
    return this.data.getWithEnriched(id, skipAncestors);
  }

  async getAttachedContentsByProductId(productId, count = 20, search = '') {
    return this.data.getAttachedContentsByProductId(productId, count, search);
  }

  async getContentsByProductId(productId, count = 20, search = '') {
    return this.data.getContentsByProductId(productId, count, search);
  }

  async getContentsByPartialProduct(parameters, count = 20, search = '') {
    return this.data.getContentsByPartialProduct(parameters, count, search);
  }

  async getAttachedContentsByPartialProduct(parameters, count) {
    return this.data.getAttachedContentsByPartialProduct(parameters, count);
  }

  async filterContents(
    searchFilter,
    knowledgeFilters,
    productFilters,
    caseFilters,
    statusFilters,
    accessRestrictionsFilters,
    labelsFilters,
    contributionFilter,
    cached = true,
    pageSize = 20,
    page = 1,
  ) {
    return this.data.filterContentsQuery(
      searchFilter,
      knowledgeFilters,
      productFilters,
      caseFilters,
      statusFilters,
      accessRestrictionsFilters,
      labelsFilters,
      contributionFilter,
      cached,
      pageSize,
      page,
    );
  }

  async filterContentsCount(
    knowledgeFilters,
    productFilters,
    caseFilters,
    knowledgeId,
    statusFilters,
    accessRestrictionsFilters,
    labelsFilters,
    contributionFilter,
  ) {
    return this.data.filterContentsCount(
      knowledgeFilters,
      productFilters,
      caseFilters,
      statusFilters,
      accessRestrictionsFilters,
      labelsFilters,
      contributionFilter,
      knowledgeId,
    );
  }

  async getDescendents(contentId, lang, isStaged = false) {
    return this.data.retrieveContentDescendents(contentId, lang, isStaged);
  }

  async getArchives(knowledge, pageSize = 20, page = 1, search = '') {
    return this.data.getArchives(knowledge, pageSize, page, search);
  }

  async getArchiveById(archiveId, lang) {
    return this.data.getArchiveById(archiveId, lang);
  }

  async countRelatedContentReferences(contentId, knowledgeId) {
    return this.data.countRelatedContentReferences(contentId, knowledgeId);
  }

  async getTopContents(knowledgeId) {
    return this.data.getTopContents(knowledgeId);
  }

  async getLoadingLanguages(documentId) {
    return this.data.getLoadingLanguages(documentId);
  }

  // UPDATE METHODS

  async update(variables) {
    return this.data.updateContent(variables);
  }

  async updateTranslation(variables) {
    if (window.forceError) {
      throw new Error('');
    }
    return this.data.updateContentTranslation(variables);
  }

  async updateStagedContent(id, lang, label, body) {
    if (window.forceError) {
      throw new Error('');
    }
    return this.data.updateContentStagedContent(id, lang, label, body);
  }

  async publishStagedVersion(id, lang) {
    return this.data.publishContentStagedVersion(id, lang);
  }

  async deleteStagedVersion(id) {
    return this.data.deleteContentStagedVersion(id);
  }

  async toggleKeyStep(id, type) {
    return this.data.toggleKeyStep(id, type);
  }

  async updateSettings(variables) {
    return this.data.updateContentSettings(variables);
  }

  async upsertContentAction(
    contentId,
    actionId,
    actionPayload,
    langs,
    type,
    title,
  ) {
    return this.data.upsertContentAction(
      contentId,
      actionId,
      actionPayload,
      langs,
      type,
      title,
    );
  }

  async deleteContentAction(contentId, actionId) {
    return this.data.deleteContentAction(contentId, actionId);
  }

  async updatePlugins(contentId, pluginId) {
    return this.data.updateContentPlugins(contentId, pluginId);
  }

  async relocate(childId, formerParentId, newParentId) {
    return this.data.relocateContent(childId, formerParentId, newParentId);
  }

  async upsertVerificationPolicy({
    contentId,
    verificationRange,
    verificationDueDate,
    setToDraftAtDueDate,
    customRoleId,
    userId,
    lang,
  }) {
    return this.data.upsertVerificationPolicy({
      contentId,
      verificationRange,
      verificationDueDate,
      setToDraftAtDueDate,
      customRoleId,
      userId,
      lang,
    });
  }

  async removeVerificationPolicy(contentId, lang) {
    return this.data.removeVerificationPolicy(contentId, lang);
  }

  async upsertPublicationPolicy({
    contentId,
    publicationDueDate,
    userId,
    lang,
  }) {
    return this.data.upsertPublicationPolicy({
      contentId,
      publicationDueDate,
      userId,
      lang,
    });
  }

  async removePublicationPolicy(contentId, lang) {
    return this.data.removePublicationPolicy(contentId, lang);
  }

  async createSyncContent(payload) {
    return this.data.createSyncContent(payload);
  }

  async syncContent(contentId, lang) {
    return this.data.syncContent(contentId, lang);
  }

  async unlinkSyncContent(contentId, lang) {
    return this.data.unlinkSyncContent(contentId, lang);
  }

  async unsetLanguage(contentId, lang) {
    return this.data.unsetLanguage(contentId, lang);
  }

  async restoreArchiveContent(archiveId, caseParameters = null) {
    return this.data.restoreArchiveContent(archiveId, caseParameters);
  }

  async bulkUpdateAttributes(
    contentIds,
    toAdd,
    toRemove,
    isGranularAccessRestrictions,
  ) {
    return this.data.bulkUpdateAttributes(
      contentIds,
      toAdd,
      toRemove,
      isGranularAccessRestrictions,
    );
  }

  async bulkUpdateStatus(contentIds, key, value, lang) {
    return this.data.bulkUpdateStatus(contentIds, key, value, lang);
  }

  async bulkUpdateParameters(contentIds, knowledge, parameters) {
    return this.data.bulkUpdateParameters(contentIds, knowledge, parameters);
  }

  async bulkArchiveContents(contentIds, softDeleted = false, isStaged = false) {
    return this.data.bulkArchiveContents(contentIds, softDeleted, isStaged);
  }

  async bulkRestoreContents(archiveIds) {
    return this.data.bulkRestoreContents(archiveIds);
  }

  async bulkDeleteContents(archiveIds) {
    return this.data.bulkDeleteContents(archiveIds);
  }

  async sessionHistorization(payload) {
    return this.data.sessionHistorization(payload);
  }

  async setContentAccess(id, accessType, lang) {
    return this.data.setContentAccess(id, accessType, lang);
  }

  async setPublicDescription(id, description, metaLabel, lang) {
    return this.data.setPublicDescription(id, description, metaLabel, lang);
  }

  async addAccessToken(id, name, expirationDate) {
    return this.data.addAccessToken(id, name, expirationDate);
  }

  async revokeAccessToken(id, accessToken) {
    return this.data.revokeAccessToken(id, accessToken);
  }

  async updateCover(id, url) {
    return this.data.updateCover(id, url);
  }

  async setRelatedContents(id, relatedContents) {
    return this.data.setRelatedContents(id, relatedContents);
  }

  // TRIGGER
  async getEligibleActionsFromLocation(location) {
    return this.data.getEligibleActionsFromLocation(location);
  }

  async triggerContentAction(contentId, actionId) {
    try {
      const response = await this.data.triggerContentAction(
        contentId,
        actionId,
      );
      return response.data.detail;
    } catch (err) {
      const { response } = err;
      const { data } = response;
      const message = data.detail ? data.detail.message : data.message;
      return { status: response.status, message };
    }
  }

  // WORKFLOW ACTIONS
  async createWorkflowAction(contentId, workflowAction) {
    return this.data.createWorkflowAction(contentId, workflowAction);
  }

  async updateWorkflowAction(contentId, workflowActionId, workflowAction) {
    return this.data.updateWorkflowAction(
      contentId,
      workflowActionId,
      workflowAction,
    );
  }

  async deleteWorkflowAction(contentId, workflowActionId) {
    return this.data.deleteWorkflowAction(contentId, workflowActionId);
  }

  async orderWorkflowActions(contentId, orderedWorkflowActionIds) {
    return this.data.orderWorkflowActions(contentId, orderedWorkflowActionIds);
  }

  async getWorkflowActionLibrary() {
    return this.data.getWorkflowActionLibrary();
  }

  async addWorkflowActionLibraryItem(item) {
    return this.data.addWorkflowActionLibraryItem(item);
  }

  async updateWorkflowActionLibraryItem(id, item) {
    return this.data.updateWorkflowActionLibraryItem(id, item);
  }
}
