<template>
  <div :class="base">
    <settings-cta
      :title="`${pre}.title`"
      :description="`${pre}.description`"
      :cta="`${pre}.cta`"
      @click="show = true"
    ></settings-cta>
    <SsoAttributesMappingSettingsDrawer
      v-if="show"
      :show="show"
      @close="show = false"
    />
  </div>
</template>

<script>
import SettingsCta from '@/components/Settings/SettingsCta';
import SsoAttributesMappingSettingsDrawer from './SsoAttributesMappingSettingsDrawer.vue';

const base = 'sso-profile-sso-mapping';
const i18nPrefix = 'settings-layout.menu-data.authentication.sso-mapping';

export default {
  name: 'SsoAttributesMappingSettings',
  components: {
    SettingsCta,
    SsoAttributesMappingSettingsDrawer,
  },
  data() {
    return {
      base: `${base}`,
      pre: `${i18nPrefix}`,
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
