<template>
  <div class="first-navigator-node mb-3">
    <!-- HEADER -->
    <div class="d-flex align-items-center justify-content-between">
      <!-- ROOT LABEL -->
      <div class="node-item">
        <div>
          <h3 class="mb-0 pointer" @click="headerLabelClick()">{{ label }}</h3>
        </div>
      </div>

      <slot></slot>
      <!-- NODE OPTIONS -->
      <AddNodeOptions
        :parent-ids="parentIds"
        :can-create-content="Boolean(userPermissions.canCreateContent)"
        @add-child="$emit('add-child', $event)"
      />
    </div>
  </div>
</template>
<script>
import AddNodeOptions from '../NavigatorNode/CommonNode/CommonNodeOptions/AddNodeOptions';

export default {
  name: 'header-label',
  props: {
    label: {
      type: String,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    parentIds: Array,
  },
  components: {
    AddNodeOptions,
  },
  methods: {
    headerLabelClick() {
      this.$emit('focus');
    },
  },
};
</script>
<style lang="scss" scoped>
.vertical-divider {
  max-height: 18px;
}

.node-item {
  max-width: 85%;
}

.node-item .focus {
  z-index: 0;
}

/*Navigation*/

.message {
  color: #535353;
  font-size: 14px;
}

#options_selection {
  max-width: 15px;
  cursor: pointer;
}

.options_selection {
  max-width: 15px;
  cursor: pointer;
  color: #9aa4b6;
}

/* Dropdown menu*/

.dropdown {
  padding: 0px;
}

.dropdown-item:hover {
  background-color: rgba($blue-mayday, 0.1);
  cursor: pointer;
}
.dropdown-item :hover i {
  color: $blue-mayday;
}

.dropdown-item p {
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item span {
  margin-left: 6px;
}

.dropdown-button {
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
}

.pointer {
  cursor: pointer;
}
</style>
