<template>
  <button
    :class="[base, `${base}-${size}`, `${base}-${variant}`]"
    @click="$emit('click')"
  >
    <font-awesome-icon :icon="['fal', icon]" />
  </button>
</template>
<script>
const base = 'base-icon-button';
export default {
  name: 'BaseIconButton',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      base: `${base}`,
    };
  },
};
</script>
<style lang="scss" scoped>
.base-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;

  &-sm {
    width: 22px;
    height: 22px;
    font-size: 14px;
    border-radius: 4px;
  }

  &-tertiary {
    background-color: transparent;
    color: $grey-9-mayday;
    &:hover {
      background-color: rgba($grey-5-mayday, 0.15);
    }
  }
}
</style>
