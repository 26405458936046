<template>
  <div>
    <div v-if="$route.name === 'knowledge-home'">
      <router-view></router-view>
    </div>
    <div class="knowledge-card" v-else>
      <div class="whole-card-pannel public">
        <div class="d-flex m-0 knowledge-editor">
          <!-- NAVIGATOR NODE -->
          <NavigationBar
            v-if="displayNavigationBar"
            :large-editor-bar="toggleNavigationBar"
            :isSettingsDrawerOpen="isSettingsDrawerOpen"
            @toggle-nav-bar="
              setToggleNavigationBar({
                toggleNavigationBar: !toggleNavigationBar,
              })
            "
          />
          <div v-else class="small-navigation-bar">
            <div class="focus-element-icon mx-auto">
              <RouteTypeIcon
                :src="focusNavNodeIcon"
                :fa-icon="focusNavNodeFaIcon"
                class="reduce-navigator-icon"
              />
            </div>
            <div class="archive-button d-flex justify-content-center">
              <el-tooltip
                content="Consulter les archives"
                placement="right"
                v-if="!focusIsArchives && !focusIsTemplates"
              >
                <font-awesome-icon
                  :icon="['fas', 'archive']"
                  class="archive-icon"
                  @click="showArchives"
                />
              </el-tooltip>
              <el-tooltip
                :content="$t('knowledge.actions.return-to-kb')"
                placement="right"
                v-else
              >
                <font-awesome-icon
                  :icon="['fad', 'arrow-to-left']"
                  class="archive-icon"
                  @click="showKnowledge"
                />
              </el-tooltip>
            </div>
          </div>
          <!-- // END SMALL NAVIGATOR NODE \\ -->
          <!-- // START CONTENT AND FOLDER EDITION\\ -->
          <div class="content-wrapper d-flex">
            <div class="main-edition-card sticky-top">
              <!-- // ✅ HEADER DE LA CARD CONTENANT LE BREADCRUMB \\ -->
              <ParametricKnowledgeEditorHeader
                v-if="!displayEmptyState"
                :case-path="casePath"
                :route-type="routeType"
                :collection-search-filter="collectionSearchFilter"
                :is-settings-drawer-open="isSettingsDrawerOpen"
                :has-staged-version="hasStagedVersion"
                @launch-search="launchSearch"
                @open-delete-modal="openDeleteModal"
                @open-clone-modal="modals.cloneCase = true"
                @open-integration-modal="modals.importArticle = true"
                @open-content-version="openContentVersion"
                @open-content-settings="isSettingsDrawerOpen = $event"
                @archive="archiveContent"
                @create-staged-version="createStagedVersion"
                @delete-staged-version="handleDeleteStagedVersion"
              />
              <KnowledgeEmptyState v-if="displayEmptyState" />
              <router-view
                v-else
                :editable="$route.path.split('/')[1] === 'knowledge'"
                @change-route-type="changeRouteType"
                @open-content-settings="handleOpenContentSettings"
              >
              </router-view>
            </div>
            <Transition name="collapse" mode="out-in">
              <div
                class="drawer-wrapper"
                v-if="isSettingsDrawerOpen && focusContentId"
              >
                <ContentViewSettingsDrawer
                  :open="isSettingsDrawerOpen"
                  :content="
                    isSettingsDrawerFocusedOnStep ? focusStep : focusContent
                  "
                  :is-staged-content="isStagedContent"
                  @update-content-view-setting="updateContentSettings"
                  @close="isSettingsDrawerOpen = false"
                />
              </div>
            </Transition>
            <ContentViewGlobalDrawer
              v-if="!focusIsArchives && focusContentId && focusContent"
              :is-settings-drawer-open="isSettingsDrawerOpen"
              @content-settings-drawer="isSettingsDrawerOpen = $event"
            ></ContentViewGlobalDrawer>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$route.path.split('/')[1] === 'knowledge'">
      <not-allowed-tooltip
        v-if="isParametric"
        :user-permissions="focusKnowledge ? focusKnowledge.userPermissions : {}"
        class="floating-button"
        permission-key="canCreateContent"
        placement="top"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <MaydayFloatingButton
            @click="hasPermissions ? (modals.addContribution = true) : ''"
            :state="modals.addContribution"
          />
        </template>
      </not-allowed-tooltip>
      <AdminContributionModal
        v-if="modals.addContribution"
        :open="modals.addContribution"
        :groups="userGroups"
        @close="modals.addContribution = false"
        @create-contribution="handleCreateContent"
      />
      <ModalEvents
        modal-name="DeleteContentModal"
        :uid="deleteModalUniqueKey"
        @choice="deleteCase"
      />
      <ModalEvents
        modal-name="SoftDeleteContentModal"
        :uid="deleteModalUniqueKey"
        @choice="softDeleteCase"
      />
      <CloneKnowledgeBaseModal
        v-if="modals.cloneCase"
        :display="modals.cloneCase"
        sourceType="case"
        :sourceLabel="currentCaseLabel"
        :sourceId="currentCaseId"
        @close="modals.cloneCase = false"
        @clone-to-target="handleCloneCaseToTarget($event)"
      />
      <ContentVersionModal
        v-if="modals.versionContent"
        :content-id="contentId"
        :display="modals.versionContent"
        :user-permissions="focusContent.userPermissions"
        :staged-data-version="stagedDataVersion"
        @restore-version="restoreContentVersion"
        @close="closeContentVersion"
      />

      <ImportedArticle
        v-if="modals.importArticle"
        :display="modals.importArticle"
        @import-article="importArticle"
        @close="modals.importArticle = false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import RouteTypeIcon from './RouteTypeIcon';
import MaydayFloatingButton from '@/components/MaydayFloatingButton';
import AdminContributionModal from '@/components/Modals/AdminContributionModal/AdminContributionModal';
import NavigationBar from '@/views/KnowledgeEditor/NavigationBar/NavigationBar';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

import ModalEvents from '@/components/Modals/ModalEvents';
import CloneKnowledgeBaseModal from '@/components/Modals/CloneKnowledgeBaseModal/CloneKnowledgeBaseModal';
import ContentVersionModal from '@/components/Modals/ContentVersionModal/ContentVersionModal';
import ImportedArticle from '@/components/Modals/ImportedArticle';
import ParametricKnowledgeEditorHeader from './ParametricKnowledgeEditorHeader/ParametricKnowledgeEditorHeader';
import ContentViewSettingsDrawer from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsDrawer';
import ContentViewGlobalDrawer from '../../components/Drawers/ContentViewSettingDrawer/ContentViewGlobalDrawer';
import KnowledgeEmptyState from './KnowledgeEmptyState.vue';

// IMAGES
const diagIcon = require('assets/diag-icon-v2.svg');
const articleIcon = require('assets/article-icon-v2.svg');
const folderIcon = require('assets/open-folder-focused.svg');

export default {
  name: 'parametric-knowledge-editor',
  components: {
    RouteTypeIcon,
    ParametricKnowledgeEditorHeader,
    MaydayFloatingButton,
    AdminContributionModal,
    NavigationBar,
    ModalEvents,
    ContentVersionModal,
    ImportedArticle,
    CloneKnowledgeBaseModal,
    ContentViewSettingsDrawer,
    NotAllowedTooltip,
    ContentViewGlobalDrawer,
    KnowledgeEmptyState,
  },
  data() {
    return {
      routeType: '',
      modals: {
        addContribution: false,
        cloneCase: false,
        versionContent: false,
        importArticle: false,
      },
      relatedContentsCount: {},
      preparedCaseFilters: [],
      contentId: '',
      stagedDataVersion: 0,
      loadingPourcent: 0,
      isSettingsDrawerOpen: false,
      isSettingsDrawerFocusedOnStep: false,
      loadingMessage: null,
    };
  },

  beforeCreate() {
    this.$store.dispatch('knowledgeModule/getKnowledges');
  },
  methods: {
    handleOpenContentSettings(isFocusedOnStep) {
      this.isSettingsDrawerOpen = !this.isSettingsDrawerOpen;
      this.isSettingsDrawerFocusedOnStep = this.isSettingsDrawerOpen
        ? isFocusedOnStep
        : false;
    },
    async importArticle(externalId, integrationSource) {
      const { contentId } = this.$route.params;

      try {
        await this.createSyncContent({
          id: contentId,
          source: integrationSource.toLowerCase(),
          externalId,
        });
      } catch (e) {
        this.$message({
          message: `Une erreur est survenue, verifier que le contenu est bien disponible`,
          type: 'error',
        });
      }

      this.$root.$emit('content-restored');
    },
    async restoreContentVersion({ label, body }) {
      const { stepId } = this.$route.params;
      const { staged } = this.$route.query;
      staged
        ? await this.updateStagedContent({
            id: this.contentId,
            label,
            body,
            isRestore: true,
          })
        : await this.updateContent({
            id: this.contentId,
            payload: { label, body, isRestore: true },
          });

      if (stepId) {
        this.$root.$emit('step-restored');
      } else {
        this.$root.$emit('content-restored');
      }

      this.closeContentVersion();
    },
    closeContentVersion() {
      this.contentId = '';
      this.modals.versionContent = false;
    },
    openContentVersion() {
      const { contentId, stepId } = this.$route.params;
      this.stagedDataVersion = this.$route.query.staged
        ? this.focusContent.stagedDataVersion
        : 0;
      this.contentId = stepId ? stepId : contentId;
      this.modals.versionContent = true;
    },
    async handleDeleteStagedVersion() {
      await this.deleteStagedVersion();
      this.$message({
        message: this.$t('knowledge.staged-version.delete-succeed'),
        type: 'success',
      });
    },
    launchSearch(filter) {
      this.updateCollectionSearch({ filter, page: 1 });
    },
    showArchives() {
      window.location.href = `/archives/${this.editingLanguage}/${this.focusKnowledgeId}`;
    },
    showKnowledge() {
      this.$router.push({
        name: 'knowledge-home',
        params: { lang: this.editingLanguage },
      });
    },
    changeRouteType(routeType) {
      this.routeType = routeType;
    },
    async handleCreateContent(payload) {
      const content = await this.createContent(payload);
      if (content.caseParameters.length === 1) {
        const caseIds = content.caseParameters[0].split('/');
        const caseId = caseIds[caseIds.length - 1];
        const rebuiltCasePath = await this.getRebuiltCasePathFromCaseId(caseId);
        this.updateCasePath(rebuiltCasePath ? rebuiltCasePath : []);
      } else {
        await this.updateCasePath([]);
      }

      this.goToTranslatedEntity({
        entityId: content.id,
        entityType: content.type,
      });
    },
    async openDeleteModal({ softDeleted } = {}) {
      this.preparedCaseFilters = this.casePath.map((c) => c.id).join('/');
      let loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'components.modals.delete-modal.loading',
        )}`,
      });
      this.relatedContentsCount =
        await this.$services.parametricContents.filterContentsCount(
          undefined,
          [],
          this.preparedCaseFilters,
          this.focusKnowledge.id,
        );
      loadingMessage.close();
      const payload = {
        component: softDeleted
          ? 'SoftDeleteContentModal'
          : 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: {
          display: true,
          caseCount: this.relatedContentsCount,
          isCase: true,
          label: this.currentCaseLabel,
        },
      };
      if (softDeleted) {
        payload.props = {
          ...payload.props,
          type: 'content',
          toDeleteName: 'SELECTED-COLLECTION',
        };
      }
      this.openModal(payload);
    },
    async softDeleteCase(choice) {
      if (!choice) return;
      this.deleteCase('softDeleted');
    },
    async deleteCase(choice) {
      if (choice) {
        const payload =
          choice === 'softDeleted'
            ? {
                caseId: this.currentCaseId,
                softDeleted: true,
              }
            : { caseId: this.currentCaseId };
        await this.deleteCollection(payload);
        this.$router.push('/knowledge');
      }
    },
    async handleCloneCaseToTarget(payload) {
      const loadingMessage = this.$message({
        duration: 0,
        message: this.$t('knowledge.actions.clone-loading'),
      });
      try {
        await this.cloneCaseToTarget({
          caseId: this.currentCaseId,
          ...payload,
        });
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-success'),
          type: 'success',
        });
      } catch (e) {
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.actions.clone-error'),
          type: 'error',
        });
      }
      this.modals.cloneCase = false;
    },
    async archiveContent({ softDeleted } = {}) {
      const { contentId } = this.$route.params;
      const content = await this.$services.parametricContents.get(contentId);
      this.loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        showClose: true,
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'knowledge.actions.archive-loader',
        )}`,
      });

      await this.bulkArchiveContents({
        contentIds: [contentId],
        softDeleted: softDeleted,
      });
      this.loadingMessage.close();

      try {
        const caseId = content.caseParameters[0].split('/').pop();
        this.$router.push({ path: `/knowledge/case/${caseId}` });
      } catch {
        this.$router.push({ path: '/knowledge' });
      }
    },
    async updateContentSettings(payload) {
      const { contentId } = this.$route.params;
      await this.updateContentSettingsAction({
        id: contentId,
        payload,
      });
    },

    ...mapActions('kbStore', ['updateCasePath']),
    ...mapActions('knowledgeModule', [
      'createContent',
      'updateCollectionSearch',
      'deleteCollection',
      'cloneCaseToTarget',
      'bulkArchiveContents',
      'setFocusKnowledge',
      'updateContent',
      'updateStagedContent',
      'createSyncContent',
      'updateContentSettingsAction',
      'flushNavigatorLookupMap',
      'getKnowledges',
      'setToggleNavigationBar',
      'switchEditingLanguage',
      'goToTranslatedEntity',
      'getRebuiltCasePathFromCaseId',
      'createStagedVersion',
      'deleteStagedVersion',
    ]),
    ...mapActions('modalsModule', ['openModal']),
  },
  computed: {
    displayEmptyState() {
      return (
        !this.loading &&
        [
          'knowledge-content',
          'knowledge-content-step',
          'knowledge-case',
        ].includes(this.$route.name) &&
        ((this.isContent && !this.focusContent) ||
          (this.isCase && !this.focusCollection))
      );
    },
    loading() {
      return this.knowledgeEditorIsLoading || this.collectionsViewerIsLoading;
    },
    hasStagedVersion() {
      return !!this.focusContent && !!this.focusContent.stagedData;
    },
    isStagedContent() {
      const { staged } = this.hasStagedVersion && this.$route.query;
      return !!staged;
    },
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.currentCaseId}`;
    },
    currentCaseId() {
      return this.focusCollection ? this.focusCollection.id : null;
    },
    currentCaseLabel() {
      return this.focusCollection ? this.focusCollection.label : null;
    },
    focusIsArchives() {
      return this.$route.name.includes('archive');
    },
    focusIsTemplates() {
      return this.$route.name.includes('template');
    },
    focusNavNodeIcon() {
      switch (this.routeType) {
        case 'Diagnostic':
          return diagIcon;
        case 'Article':
          return articleIcon;
        case 'Folder':
          return folderIcon;
        default:
          return null;
      }
    },
    focusNavNodeFaIcon() {
      switch (this.routeType) {
        case 'Filter':
          return 'search';
        case 'Product':
          return 'barcode-scan';
        case 'Case':
          return 'folder';
        case 'Archive':
          return 'archive';
        case 'Template':
          return 'folder';
        default:
          return null;
      }
    },
    displayNavigationBar() {
      return !this.isSplitKnowledgeViewer && !this.focusIsArchives;
    },
    drawerRelatedStates() {
      return (
        !this.isSplitKnowledgeViewer &&
        !this.focusIsArchives &&
        !this.isSettingsDrawerOpen
      );
    },
    isCase() {
      return this.$route.path.split('/').includes('case');
    },
    isContent() {
      return this.$route.path.split('/').includes('content');
    },

    ...mapState(['userGroups']),
    ...mapGetters(['isSplitKnowledgeViewer', 'isParametric']),
    ...mapGetters('knowledgeModule', [
      'collectionSearchFilter',
      'focusCollection',
      'focusKnowledge',
      'focusContent',
      'focusContentId',
      'focusStep',
      'knowledges',
      'knowledgeEditorIsLoading',
      'collectionsViewerIsLoading',
      'focusKnowledgeDefaultLanguage',
      'focusKnowledgeLanguages',
      'casePath',
      'toggleNavigationBar',
      'editingLanguage',
      'focusKnowledgeId',
    ]),
  },
  beforeDestroy() {
    this.flushNavigatorLookupMap();
  },
  watch: {
    $route(to) {
      if (to.name === 'knowledge-filter')
        this.setToggleNavigationBar({ toggleNavigationBar: false });
      if (to.query.from === 'tab') {
        this.getKnowledges();
        //If we remove this, knowledge page won't reload if we are already on the
        // '/knowledge?from=tab' page because watcher won't be triggered
        this.$router.push('/knowledge');
      }
    },
    drawerRelatedStates() {
      this.setToggleNavigationBar({
        toggleNavigationBar: this.drawerRelatedStates,
      });
    },
    focusContentId() {
      this.isSettingsDrawerOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 90px;
}
.search-field {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
}
.archive-button {
  position: fixed;
  bottom: 144px;
  left: 84px;
  cursor: pointer;
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
  }
}

.knowledge-card {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-radius: 0px;
}

/* SIDEBAR */
.small-navigation-bar {
  width: 60px;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  background-color: #f8f9fe;
}

.focus-element-icon {
  margin-top: calc(2.2rem + 60px);
  width: 32px;
}

.reduce-navigator-icon {
  width: 100%;
}

/* MAIN */
.main-edition-card {
  width: 100%;
  flex-shrink: 30;
  overflow: auto;
}

.editor-navigator-vue-header {
  background-color: white;
  height: 60px;
  display: flex;
}

.whole-card-pannel {
  width: calc(100vw - 60px);
  height: calc(100vh);
  border-radius: 0px;
}

.content-wrapper {
  min-width: 0;
  background: white;
  width: 100%;
  padding-left: 15px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.hosting-content-row {
  height: calc(100vh - 60px);
  overflow: auto;
}

.breadcrumb-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.page-title {
  font-size: 12px;
  font-weight: bold;
  color: $grey-6-mayday;
}

.translation-row {
  margin-top: -12px;
  padding-right: 12px;
}

.drawer-wrapper {
  overflow: hidden;
  height: 100%;
}

.collapse-enter-active {
  animation: collapse-enter 0.4s ease-in-out;
}

.collapse-leave-active {
  animation: collapse-enter 0.4s ease-in-out reverse;
}

@keyframes collapse-enter {
  0% {
    width: 0px;
  }
  100% {
    width: 400px;
  }
}
</style>
