var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.userLabelCategories),function(userLabelCategory){return _c('div',{key:userLabelCategory.id},[_c('LabelCategorySettingsDropdown',_vm._b({on:{"update-category":function($event){return _vm.$emit('update-category', $event)},"update-label":function($event){return _vm.$emit('update-label', $event)},"create-label":function($event){return _vm.$emit('create-label', $event)},"delete-label":function($event){return _vm.$emit('delete-label', $event)},"edit-icon":_vm.handleEditIconCategory,"trigger-delete":_vm.handleDeleteCategory}},'LabelCategorySettingsDropdown',userLabelCategory,false))],1)}),(_vm.modals.delete)?_c('DangerDeleteDialog',{attrs:{"display":_vm.modals.delete,"title":_vm.$t('components.modals.delete-modal.label.title'),"description":_vm.$t('components.modals.delete-modal.label.description', {
        name: _vm.toDeleteCategory.name,
      }),"callout":_vm.$tc(
        'components.modals.delete-modal.label.callout',
        _vm.toDeleteCategory.companyUserLabels.length,
        {
          count: _vm.toDeleteCategory.companyUserLabels.length,
        },
      ),"hasConfirm":true,"hasCallout":true},on:{"confirm":_vm.deleteCategory,"close":function($event){_vm.modals.delete = false}}}):_vm._e(),(_vm.modals.icon)?_c('IconEditionModal',{attrs:{"display":_vm.modals.icon,"current-icon":_vm.toUpdateCategory,"editionOptions":_vm.iconOptions,"title":"settings.permissions.user-labels.icon-edition-title","description":"settings.permissions.user-labels.icon-edition-description"},on:{"choice":_vm.updateCategoryIcon,"delete":function($event){return _vm.$emit('delete-content-icon')},"close":function($event){_vm.modals.icon = false}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }