<template>
  <BaseDialog
    :title="content.title"
    :description="content.description"
    :confirm-text="content.ctas.confirm"
    :cancel-text="content.ctas.cancel"
    :message-box="content.messageBox"
    :open="localDisplay"
    variant="danger"
    @confirm="userChoice(true)"
    @cancel="userChoice(false)"
    @close="userChoice(false)"
  ></BaseDialog>
</template>
<script>
export default {
  name: 'delete-content-modal',
  props: {
    display: Boolean,
    bulkCount: {
      type: Number,
      default: 1,
    },
    isCase: Boolean,
    caseCount: Number,
    deleteType: {
      type: String,
      default: 'content',
    },
    id: String,
    relatedCount: {
      type: Number,
      default: 0,
    },
    automationsCount: {
      type: Number,
      default: 0,
    },
    lang: String,
    label: String,
  },

  data() {
    return {
      localDisplay: this.display,
      isLoading: false,
    };
  },
  computed: {
    linkedContentsCount() {
      return this.relatedCount;
    },
    labelDisplay() {
      if (this.label) {
        return `"${this.label}".`;
      } else if (this.bulkCount === 1) {
        return `${this.$t(
          'components.modals.delete-modal.content.default-label',
        )}.`;
      } else return '';
    },
    messageBoxDisplay() {
      if (this.deleteType !== 'content') return null;

      if (
        !this.linkedContentsCount &&
        !this.automationsCount &&
        !this.caseCount
      ) {
        return null;
      }

      let message = '';

      if (this.isCase && this.caseCount) {
        message += this.$tc(
          'components.modals.delete-modal.content.count',
          this.caseCount,
          { count: this.caseCount },
        );
      }

      if (this.linkedContentsCount) {
        message += this.$tc(
          'components.modals.delete-modal.content.related-count',
          this.linkedContentsCount,
          { count: this.linkedContentsCount },
        );
      }

      if (this.automationsCount) {
        message +=
          ' ' +
          this.$tc(
            'components.modals.delete-modal.content.automations-count',
            this.automationsCount,
            { count: this.automationsCount },
          );
      }

      return {
        variant: 'error',
        message,
      };
    },
    content() {
      switch (this.deleteType) {
        case 'content': {
          return {
            title: this.$t('components.modals.delete-modal.content.title'),
            description: `${this.$tc(
              'components.modals.delete-modal.content.description',
              this.bulkCount,
              { count: this.bulkCount },
            )}${this.labelDisplay ? ` ${this.labelDisplay}` : ''}`,
            warning: this.$t('components.modals.delete-modal.content.warning'),
            messageBox: this.messageBoxDisplay,
            count: this.$tc(
              'components.modals.delete-modal.content.count',
              this.caseCount,
              { count: this.caseCount },
            ),
            relatedCount: this.$tc(
              'components.modals.delete-modal.content.related-count',
              this.relatedCount,
              { count: this.linkedContentsCount },
            ),
            automationsCount: this.$tc(
              'components.modals.delete-modal.content.automations-count',
              this.automationsCount,
              { count: this.automationsCount },
            ),
            ctas: {
              confirm: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        }
        case 'user':
          return {
            title: this.$t('components.modals.delete-modal.user.title'),
            description: this.$tc(
              'components.modals.delete-modal.user.description',
              this.bulkCount,
              { count: this.bulkCount },
            ),
            warning: this.$t('components.modals.delete-modal.user.warning'),
            ctas: {
              confirm: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        case 'template':
          return {
            title: this.$t('components.modals.delete-modal.template.title'),
            description: this.$tc(
              'components.modals.delete-modal.template.description',
              this.bulkCount,
              { count: this.bulkCount },
            ),
            warning: this.$t('components.modals.delete-modal.template.warning'),
            ctas: {
              confirm: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        case 'customRole':
          return {
            title: this.$t('components.modals.delete-modal.custom-role.title'),
            description: this.$t(
              'components.modals.delete-modal.custom-role.description',
            ),
            ctas: {
              confirm: this.$t('generic.delete'),
              cancel: this.$t('generic.cancel'),
            },
          };
        case 'language':
          return {
            title: this.$t('components.modals.delete-modal.language.title'),
            description: this.$t(
              'components.modals.delete-modal.language.description',
              { lang: this.$t(`knowledge.languages.${this.lang}`) },
            ),
            warning: this.$t('components.modals.delete-modal.language.warning'),
            ctas: {
              confirm: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        default:
          return {};
      }
    },
  },
  methods: {
    userChoice(choice) {
      this.isLoading = true;
      this.$emit('events', {
        eventName: 'choice',
        eventData: choice,
      });
      this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped></style>
