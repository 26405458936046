<template>
  <BaseDropdownOptionsWrapper
    id="dropdown-button"
    :options="options"
    is-horizontal
    @select="handleSelectOption"
    @open="$emit('open')"
    @close="$emit('close')"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import BaseDropdownOptionsWrapper from '@/components/BaseDropdownOptionsWrapper';

export default {
  name: 'item-options',
  components: {
    BaseDropdownOptionsWrapper,
  },
  props: {
    itemType: {
      type: String,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleSelectOption(e) {
      const key = e.detail[0];

      if (!key) return;

      if (key === 'RENAME') {
        this.$emit('edit-label');
      } else if (key === 'CLONE') {
        this.$emit('open-clone-to-target-modal');
      } else if (key === 'DUPLICATE') {
        this.$emit('duplicate-in-same-location');
      } else if (key === 'RESTORE') {
        this.$emit('restore');
      } else if (key === 'EDIT_CONTENT_SETTINGS') {
        this.$emit('open-content-view-parameter-modal');
      } else if (key === 'ARCHIVE') {
        this.$emit('delete');
      } else if (key === 'SOFT_DELETE') {
        this.$emit('delete', { softDeleted: true });
      }
    },
  },
  computed: {
    options() {
      return [
        {
          key: 'RENAME',
          label: this.$t('knowledge.actions.rename'),
          icon: ['fal', 'pen'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        ...(!this.isParametric
          ? [
              {
                key: 'CLONE',
                label: this.$t('knowledge.actions.clone-in-other-kb'),
                icon: ['fal', 'clone'],
                disabled: Boolean(!this.userPermissions.canCreateContent),
              },
            ]
          : []),
        ...(!(this.isParametric && this.itemType === 'case')
          ? [
              {
                key: 'DUPLICATE',
                label: this.$t('knowledge.actions.duplicate-in-same-location'),
                icon: ['fal', 'object-group'],
                disabled: Boolean(!this.userPermissions.canCreateContent),
              },
            ]
          : []),
        ...(this.itemType === 'archive'
          ? [
              {
                key: 'RESTORE',
                label: this.$t('knowledge.actions.restore'),
                icon: ['fal', 'undo'],
              },
            ]
          : []),
        ...(this.itemType === 'content' && this.isParametric
          ? [
              {
                key: 'EDIT_CONTENT_SETTINGS',
                label: this.$t('knowledge.search.content.edit'),
                icon: ['fal', 'cog'],
              },
            ]
          : []),
        {
          key: 'ARCHIVE',
          label: this.deleteLabel,
          icon: ['fal', 'archive'],
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          type: 'danger',
          divider: true,
        },
        {
          key: 'SOFT_DELETE',
          label: this.$t('knowledge.actions.soft-delete'),
          icon: this.deleteIcon,
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          type: 'danger',
        },
      ];
    },
    deleteLabel() {
      let label = '';
      switch (this.itemType) {
        case 'content':
          label = this.$t('knowledge.actions.archive');
          break;
        case 'archive':
          label = this.$t('knowledge.search.delete-definitively');
          break;
        default:
          label = this.$t('knowledge.actions.archive');
          break;
      }
      return label;
    },
    deleteIcon() {
      return this.itemType === 'archive'
        ? ['fal', 'archive']
        : ['fal', 'trash'];
    },
    ...mapGetters(['isParametric']),
  },
};
</script>

<style lang="scss" scoped>
#dropdown-button {
  visibility: hidden;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
}

.item-row:hover {
  &:hover {
    #dropdown-button {
      visibility: visible;
    }
  }
}
</style>
