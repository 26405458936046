<template>
  <base-tabs
    :active-tab="activeTab"
    :tabs="JSON.stringify(tabs)"
    @update-tab="handleUpdateTab"
  />
</template>
<script>
export default {
  name: 'BaseTabsWrapper',
  props: {
    activeTab: {
      type: String,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleUpdateTab(evt) {
      if (!evt || !evt.detail || !evt.detail.length) return;
      this.$emit('update-tab', evt.detail[0]);
    },
  },
};
</script>
