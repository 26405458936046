var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"element-title"},[_vm._v(" "+_vm._s(_vm.$t('settings.card-settings-options.content-parameters.subtitle'))+" ")]),_c('base-button',{staticClass:"mb-1",attrs:{"variant":"primary","size":"sm","text":_vm.$t('settings.card-settings-options.content-parameters.add-category')},on:{"click":_vm.openNewCategoryForm}})],1),_c('div',{staticClass:"element-description mb-3"},[_vm._v(" "+_vm._s(_vm.$t('settings.card-settings-options.content-parameters.description'))+" ")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"main"},[_c('div',{staticClass:"d-flex flex-column alin-items-left content-state"},[_c('div',{staticClass:"d-flex flex-row align-items-center py-1 content-state-header"},[_c('IconWrapper',{staticClass:"title-icon-background p-1",attrs:{"icon":_vm.contentStateIcon,"size":16}}),_c('h4',{staticClass:"content-state-label px-2 mb-0"},[_vm._v(" "+_vm._s(_vm.$t( 'settings.card-settings-options.content-parameters.content-state-label', ))+" ")]),_c('el-badge',{staticClass:"col-auto default-tag",attrs:{"type":"primary","size":"mini"}},[_vm._v(" "+_vm._s(_vm.$t('settings.card-settings-options.content-parameters.default'))+" ")])],1),_c('div',{staticClass:"d-flex flex-column content-state-publication mt-3 ml-2"},[_c('p',{staticClass:"sub-header mb-0"},[_vm._v(" "+_vm._s(_vm.$t( 'settings.card-settings-options.content-parameters.header-publication', ))+" ")]),_c('div',{staticClass:"d-flex flex-row align-items-center mb-3 mt-1"},[_c('span',{staticClass:"label-tags d-flex flex-row align-items-center",style:({
              backgroundColor:
                _vm.contentStateLabels.publication.published.backgroundColor,
            })},[_c('font-awesome-icon',{staticClass:"pr-1",style:({
                color: _vm.contentStateLabels.publication.published.icon.color,
              }),attrs:{"icon":[
                _vm.contentStateLabels.publication.published.icon.iconType,
                _vm.contentStateLabels.publication.published.icon.value,
              ]}}),_c('p',{staticClass:"mb-0 label-name"},[_vm._v(" "+_vm._s(_vm.$t( 'settings.card-settings-options.content-parameters.published', ))+" ")])],1),_c('span',{staticClass:"label-tags d-flex flex-row align-items-center ml-2",style:({
              backgroundColor:
                _vm.contentStateLabels.publication.draft.backgroundColor,
            })},[_c('font-awesome-icon',{staticClass:"pr-1",style:({
                color: _vm.contentStateLabels.publication.draft.icon.color,
              }),attrs:{"icon":[
                _vm.contentStateLabels.publication.draft.icon.iconType,
                _vm.contentStateLabels.publication.draft.icon.value,
              ]}}),_c('p',{staticClass:"mb-0 label-name"},[_vm._v(" "+_vm._s(_vm.$t('settings.card-settings-options.content-parameters.draft'))+" ")])],1)])]),_c('div',{staticClass:"d-flex flex-column content-state-verification ml-2 mb-2"},[_c('p',{staticClass:"sub-header mb-0"},[_vm._v(" "+_vm._s(_vm.$t( 'settings.card-settings-options.content-parameters.header-verification', ))+" ")]),_c('div',{staticClass:"d-flex flex-row align-items-center mt-1"},[_c('span',{staticClass:"label-tags d-flex flex-row align-items-center",style:({
              backgroundColor:
                _vm.contentStateLabels.verification.verified.backgroundColor,
            })},[_c('font-awesome-icon',{staticClass:"pr-1",style:({
                color: _vm.contentStateLabels.verification.verified.icon.color,
              }),attrs:{"icon":[
                _vm.contentStateLabels.verification.verified.icon.iconType,
                _vm.contentStateLabels.verification.verified.icon.value,
              ]}}),_c('p',{staticClass:"mb-0 label-name"},[_vm._v(" "+_vm._s(_vm.$t( 'settings.card-settings-options.content-parameters.verified', ))+" ")])],1),_c('span',{staticClass:"label-tags d-flex flex-row align-items-center ml-2",style:({
              backgroundColor:
                _vm.contentStateLabels.verification.toVerify.backgroundColor,
            })},[_c('font-awesome-icon',{staticClass:"pr-1",style:({
                color: _vm.contentStateLabels.verification.toVerify.icon.color,
              }),attrs:{"icon":[
                _vm.contentStateLabels.verification.toVerify.icon.iconType,
                _vm.contentStateLabels.verification.toVerify.icon.value,
              ]}}),_c('p',{staticClass:"mb-0 label-name"},[_vm._v(" "+_vm._s(_vm.$t( 'settings.card-settings-options.content-parameters.to-verify', ))+" ")])],1)])])]),_c('div',{staticClass:"mt-2"},_vm._l((_vm.categories),function(category){return _c('div',{key:category.id},[_c('Category',{attrs:{"category":category},on:{"create-label":_vm.createLabel,"update-category":_vm.updateCategory,"update-label":_vm.updateLabel,"delete-category":_vm.deleteCategory,"delete-label":_vm.openDeleteLabelModal}})],1)}),0),_c('NewCategory',{attrs:{"displayNewCategoryForm":_vm.displayNewCategoryForm},on:{"add-category":_vm.createCategory,"reset-new-category-form":_vm.resetNewCategoryForm}}),(_vm.showDeleteLabelModal)?_c('DangerDeleteDialog',{attrs:{"display":_vm.showDeleteLabelModal,"description":_vm.$t(
          'settings.card-settings-options.case-parameters.modals.delete.description',
        ),"title":_vm.$t(
          'settings.card-settings-options.case-parameters.modals.delete.title',
        ),"hasCallout":true,"callout":_vm.$tc(
          'settings.card-settings-options.case-parameters.modals.delete.callout',
          _vm.relatedContentsCount,
          { count: _vm.relatedContentsCount },
        ),"hasConfirm":true},on:{"confirm":_vm.deleteLabel,"close":function($event){_vm.showDeleteLabelModal = false}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }