export class SettingService {
  constructor(data) {
    this.data = data;
  }

  // ApiOAuthAppRegistration

  async getApiOAuthAppRegistrations() {
    return this.data.getApiOAuthAppRegistrations();
  }

  async createApiOAuthAppRegistration() {
    return this.data.createApiOAuthAppRegistration();
  }

  async updateApiOAuthAppRegistration(id, key, value) {
    return this.data.updateApiOAuthAppRegistration(id, key, value);
  }

  async deleteApiOAuthAppRegistration(id) {
    return this.data.deleteApiOAuthAppRegistration(id);
  }

  // CompanyIpRange

  async getCompanyIpRanges() {
    return this.data.getCompanyIpRanges();
  }

  async setCompanyIpRanges(ipRanges) {
    return this.data.setCompanyIpRanges(ipRanges);
  }

  async deleteCompanyIpRanges() {
    return this.data.deleteCompanyIpRanges();
  }

  // SsoProfile

  static isValidSsoProfileArgs(name, groupIds, roleIds) {
    if (!name || !groupIds || !roleIds) throw new Error('INVALID_ARGS');
    if (groupIds.length !== 1) throw new Error('INVALID_ARGS');
    if (!roleIds.length) throw new Error('INVALID_ARGS');
  }

  async getSsoProfiles() {
    return this.data.getSsoProfiles();
  }

  async createSsoProfile(name, groupIds, roleIds) {
    SettingService.isValidSsoProfileArgs(name, groupIds, roleIds);
    return this.data.createSsoProfile(name, groupIds, roleIds);
  }

  async updateSsoProfile(id, name, groupIds, roleIds) {
    if (!id) throw new Error('INVALID_ARGS');
    SettingService.isValidSsoProfileArgs(name, groupIds, roleIds);
    return this.data.updateSsoProfile(id, name, groupIds, roleIds);
  }

  async deleteSsoProfile(id) {
    if (!id) throw new Error('INVALID_ARGS');
    return this.data.deleteSsoProfile(id);
  }

  // SsoProfileMapping

  static isValidSsoProfileMappingArgs(
    idpAttribute,
    operator,
    value,
    profileId,
  ) {
    if (!idpAttribute || !operator || !value || !profileId)
      throw new Error('INVALID_ARGS');
    if (!['equal', 'include'].includes(operator))
      throw new Error('INVALID_ARGS');
  }

  async getSsoProfileMappings() {
    return this.data.getSsoProfileMappings();
  }

  async createSsoProfileMapping(idpAttribute, operator, value, profileId) {
    SettingService.isValidSsoProfileMappingArgs(
      idpAttribute,
      operator,
      value,
      profileId,
    );
    return this.data.createSsoProfileMapping(
      idpAttribute,
      operator,
      value,
      profileId,
    );
  }

  async updateSsoProfileMapping(id, idpAttribute, operator, value, profileId) {
    if (!id) throw new Error('INVALID_ARGS');
    SettingService.isValidSsoProfileMappingArgs(
      idpAttribute,
      operator,
      value,
      profileId,
    );
    return this.data.updateSsoProfileMapping(
      id,
      idpAttribute,
      operator,
      value,
      profileId,
    );
  }

  async deleteSsoProfileMapping(id) {
    if (!id) throw new Error('INVALID_ARGS');
    return this.data.deleteSsoProfileMapping(id);
  }

  // SsoUserLabelMapping

  static isValidSsoUserLabelMappingArgs(
    idpAttribute,
    operator,
    value,
    labelId,
  ) {
    if (!idpAttribute || !operator || !value || !labelId)
      throw new Error('INVALID_ARGS');
    if (!['equal', 'startswith', 'endswith'].includes(operator))
      throw new Error('INVALID_ARGS');
  }

  async getSsoUserLabelMappings() {
    return this.data.getSsoUserLabelMappings();
  }

  async createSsoUserLabelMapping(idpAttribute, operator, value, labelId) {
    SettingService.isValidSsoUserLabelMappingArgs(
      idpAttribute,
      operator,
      value,
      labelId,
    );
    return this.data.createSsoUserLabelMapping(
      idpAttribute,
      operator,
      value,
      labelId,
    );
  }

  async updateSsoUserLabelMapping(id, idpAttribute, operator, value, labelId) {
    if (!id) throw new Error('INVALID_ARGS');
    SettingService.isValidSsoUserLabelMappingArgs(
      idpAttribute,
      operator,
      value,
      labelId,
    );
    return this.data.updateSsoUserLabelMapping(
      id,
      idpAttribute,
      operator,
      value,
      labelId,
    );
  }

  async deleteSsoUserLabelMapping(id) {
    return this.data.deleteSsoUserLabelMapping(id);
  }
}
