export default {
  async getExportStatus(_, { knowledgeId, type }) {
    if (type) {
      const result = await this.$services.exportClient.getKBExport({
        knowledgeId,
        type,
      });
      return result;
    }
  },

  async requestExport(_, { knowledgeId, type }) {
    if (type) {
      const result = await this.$services.exportClient.putKBExport({
        knowledgeId,
        type,
      });
      return result;
    }
  },

  async getAcademyExportsPredefinedFilters() {
    try {
      const exports =
        await this.$services.exportClient.getExportsPredefinedFilters(
          'academy_global_attempts',
        );

      return exports;
    } catch (error) {
      return error;
    }
  },

  async getExportsWithPredefinedFilters(_, predefinedFilterId) {
    try {
      const { file } =
        await this.$services.exportClient.getExportGlobalAttempts(
          predefinedFilterId,
        );

      return file;
    } catch (error) {
      return error;
    }
  },
};
