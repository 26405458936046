<template>
  <el-drawer
    :append-to-body="true"
    :show-close="false"
    :visible="show"
    :with-header="false"
    :before-close="handleClose"
    :size="size"
    v-on="$listeners"
  >
    <div v-loading="isLoading" :class="base">
      <SettingsDrawerHeader
        :class="`${base}__header`"
        :icon="icon"
        :title="title"
        :placeholder="titleOptions.placeholder"
        :editable="titleOptions.editable"
        :validation-method="titleOptions.validationMethod"
        :description="description"
        :error-description="errorDescription"
        @input:title="handleInputTitle"
      />
      <div v-if="show" :class="`${base}__main`">
        <div :class="`${base}__main__left-col`">
          <slot name="left-col"></slot>
        </div>
        <div :class="`${base}__main__wrapper`">
          <slot name="main"> </slot>
        </div>
      </div>
      <div :class="`${base}__footer`">
        <base-button
          :text="$t('generic.quit')"
          variant="tertiary"
          size="md"
          class="mr-1"
          @click="$emit('close')"
        ></base-button>
        <base-button
          v-if="saveable"
          :text="$t(`generic.${this.itemId ? 'save' : 'create'}`)"
          variant="primary"
          size="md"
          :disabled="disabled"
          @click="handleSave"
        ></base-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import SettingsDrawerHeader from '@/components/Settings/SettingsDrawer/SettingsDrawerHeader.vue';

const base = 'settings-drawer';

export default {
  name: 'SettingsDrawer',
  components: { SettingsDrawerHeader },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    titleOptions: {
      type: Object,
      default: () => ({}),
    },
    description: {
      type: String,
      required: false,
    },
    errorDescription: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    saveable: {
      type: Boolean,
      default: true,
    },
    itemId: {
      default: null,
    },
    size: {
      type: String,
      default: '50%',
    },
  },
  data() {
    return {
      base: `${base}`,
      isLoading: false,
    };
  },
  methods: {
    handleClose() {
      this.isLoading = false;
      this.localTitle = this.$emit('close');
    },
    handleInputTitle(title) {
      this.$emit('input:title', title);
    },
    handleSave() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-drawer {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__main {
    flex: 1;
    overflow-y: auto;
    display: flex;
    margin-bottom: 8px;
    padding-top: 12px;
    padding-left: 30px;
    padding-right: 30px;
    &__left-col {
      padding-top: 60px;
      width: 170px;
      padding-right: 30px;
    }
    &__wrapper {
      flex: 1;
      & > div {
        margin-bottom: 16px;
      }
    }
  }

  &__footer {
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    background-color: $grey-1-mayday;
  }
}
</style>
