<template>
  <div
    class="dashboard-container"
    v-if="isLoggedIn"
    v-loading="!filterValuesArePopulated"
  >
    <el-menu
      v-if="tabRoutes && tabRoutes.length"
      router
      :default-active="activeLink"
      mode="horizontal"
      class="d-flex"
    >
      <el-menu-item
        v-for="route in tabRoutes"
        :key="route.name"
        :index="`${parentRoutePath}/${route.path}`"
        class="tab-menu-item"
      >
        <font-awesome-icon
          :icon="['fad', route.meta.icon]"
          class="dashboard-tab-icon"
        />
        {{ $t(`new-dashboard.tab-name.${route.meta.tabName}`) }}</el-menu-item
      >
      <base-button
        class="filters-open-link"
        variant="secondary"
        @click="openFiltersDrawer"
        icon="sliders-v"
        size="md"
        :text="$t('new-dashboard.open-filters-label')"
      />
    </el-menu>
    <div class="dashboard-tab-container">
      <router-view
        :external-doc-url="externalDocUrl"
        @open-filters-drawer="openFiltersDrawerFromChild"
      ></router-view>
    </div>
    <filters-drawer
      :show.sync="showFiltersDrawer"
      :filters="filters"
      :values="filterValues"
      :additional-values="filterAdditionalValues"
      :daterange-shortcut-values="filterDateRangeShortcutValues"
      :filters-are-ready="filtersAreReady"
      @change="updateFilterValue"
      @change-additional="updateAdditionalFilterValue"
      @reset="resetFilterValue"
      @close="closeFiltersDrawer"
    />
  </div>
</template>

<script>
import FiltersDrawer from '../../components/Filters/Drawer';
import {
  createNamespacedHelpers,
  mapState,
  mapGetters as mapVueXGetters,
} from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard');
import { EXTERNAL_DOC_URL } from '@/store/dashboard/constants';

export default {
  name: 'new-dashboard',

  components: {
    FiltersDrawer,
  },

  data() {
    return {
      showFiltersDrawer: false,
      activeLink: null,
      tabRoutes: [],
      parentRoutePath: '/dashboard',
      externalDocUrl: EXTERNAL_DOC_URL,
      publicPaths: ['analytics-contents', 'analytics-ask'],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.activeLink = to.path;
      vm.updateActiveTab(to.name.replace('analytics-', ''));
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (to.path !== '/login') {
      this.activeLink = to.path;
      this.updateActiveTab(to.name.replace('analytics-', ''));
    }
    next();
  },

  beforeRouteLeave(to, from, next) {
    this.activeLink = null;
    this.updateActiveTab(null);
    next();
  },

  created() {
    this.tabRoutes = this.extractRoutes();
    this.prepareFilters();
  },

  mounted() {
    const button = this.$refs.openFiltersButton;
    button.classList.add('shake');
    window.setTimeout(() => button.classList.remove('shake'), 5000);
  },

  computed: {
    ...mapGetters([
      'filterValuesArePopulated',
      'filterDateRangeShortcutValues',
      'filtersAreReady',
      'filters',
      'filterValues',
      'filterAdditionalValues',
      'tableFile',
      'isPublicSelected',
    ]),
    ...mapVueXGetters(['hasCompanyPreferenceWithValue', 'hasPermission']),
    ...mapState(['companyPreferences']),
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
  },

  methods: {
    ...mapActions([
      'prepareFilters',
      'updateFilterValue',
      'updateAdditionalFilterValue',
      'resetFilterValue',
      'resetTableFile',
      'updateActiveTab',
    ]),

    closeFiltersDrawer() {
      if (this.showFiltersDrawer) {
        this.showFiltersDrawer = false;
      }
    },

    openFiltersDrawerFromChild() {
      if (!this.showFiltersDrawer) {
        this.showFiltersDrawer = true;
      }
    },

    extractRoutes() {
      const routes = this.$router.options.routes
        .find(({ path, redirect }) => redirect === '/home-v2' && path === '/')
        .children.find(({ path }) => path === this.parentRoutePath)
        // remove default redirect route
        .children.filter(({ path }) => {
          const isAllowedForPublic = this.isPublicSelected
            ? path !== '' && this.publicPaths.includes(path)
            : path !== '';
          if (!isAllowedForPublic) return false;
          if (path === 'analytics-ask') {
            return this.hasCompanyPreferenceWithValue('BRAIN_ENABLE_ASK');
          }
          if (path === 'analytics-workflow') {
            return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
          }
          if (path === 'analytics-workflow-session') {
            return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
          }
          if (path === 'analytics-download-table') {
            return this.hasPermission('READ_ANALYTICS_EXPORTS');
          }
          return true;
        });

      return routes;
    },

    openFiltersDrawer(event) {
      event.preventDefault();
      this.showFiltersDrawer = true;
    },
  },

  watch: {
    tableFile(value) {
      if (value == null) return;
      const currentDate = new Date();
      const file = new File(
        [value],
        `${currentDate.getFullYear()}_${
          currentDate.getMonth() + 1
        }_${currentDate.getDate()}__mayday_export.xlsx`,
      );

      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = URL.createObjectURL(file);
      link.download = file.name;

      // It needs to be added to the DOM so it can be clicked
      document.body.appendChild(link);
      link.click();

      // To make this work on Firefox we need to wait
      // a little while before removing it.
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
      }, 0);
      this.resetTableFile();
    },
    isPublicSelected() {
      this.tabRoutes = this.extractRoutes();
      if (
        this.isPublicSelected &&
        this.$router.currentRoute.path !== 'analytics-contents'
      ) {
        this.$router.push({ path: 'analytics-contents' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0) rotate(1deg);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0) rotate(-1deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0) rotate(1deg);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0) rotate(-1deg);
  }
}

.dashboard-container {
  padding: 0 30px;
  background-color: #fff;
  min-height: 100vh;

  .el-menu {
    border-bottom: unset;

    .el-menu-item,
    .el-menu-item:hover,
    .el-menu-item:focus {
      border-bottom: none;
    }

    .el-menu-item {
      padding-left: 0;
    }

    .tab-menu-item {
      font-size: 16px;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding-right: 30px;
      &:hover {
        color: $blue-mayday;
      }
      .dashboard-tab-icon {
        margin-right: 8px;
        max-width: 16px;
        max-height: 16px;
      }
    }

    .filters-open-link {
      padding-right: 0px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      padding: 4px 8px;
    }

    .el-menu-item.is-active {
      color: $blue-mayday;
    }
  }

  .dashboard-filters-icon {
    margin-right: 10px;
  }
}
</style>
