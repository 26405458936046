<template>
  <div class="update-content-spinner-main mt-1">
    <Transition name="fade">
      <div class="update-content-spinner" v-if="label">
        <font-awesome-icon
          v-if="updateStatus === 'loading'"
          class="mr-1"
          :icon="['fal', 'spinner-third']"
          spin
        />
        <div class="updating-label">{{ label }}</div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UpdateContentSpinner',
  mounted() {
    window.addEventListener('beforeunload', this.resetUpdateStatus);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.resetUpdateStatus);
  },
  methods: {
    ...mapActions('knowledgeModule', ['resetUpdateStatus']),
  },
  computed: {
    label() {
      if (this.updateStatus === 'loading') {
        return this.$t('knowledge.update-status.updating');
      } else if (this.updateStatus === 'success') {
        return this.$t('knowledge.update-status.updated');
      }
      return '';
    },
    ...mapGetters('knowledgeModule', ['updateStatus']),
  },
  watch: {
    updateStatus(newVal) {
      if (newVal === 'success') {
        setTimeout(() => {
          this.resetUpdateStatus();
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.update-content-spinner-main {
  color: $grey-6-mayday;
}

.update-content-spinner {
  display: flex;
}

.updating-label {
  font-size: small;
}
</style>
