import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

//SOLID ICONS
// START SOLID ICON MODAL
import { faFolderOpen as fasFolderOpen } from '@fortawesome/pro-solid-svg-icons/faFolderOpen';
import { faMailbox as fasMailbox } from '@fortawesome/pro-solid-svg-icons/faMailbox';
import { faAnchor as fasAnchor } from '@fortawesome/pro-solid-svg-icons/faAnchor';
import { faBagsShopping as fasBagsShopping } from '@fortawesome/pro-solid-svg-icons/faBagsShopping';
import { faBookmark as fasBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faStars as fasStars } from '@fortawesome/pro-solid-svg-icons/faStars';
import { faMagic as fasMagic } from '@fortawesome/pro-solid-svg-icons/faMagic';
import { faBooks as fasBooks } from '@fortawesome/pro-solid-svg-icons/faBooks';
import { faImagePolaroid as fasImagePolaroid } from '@fortawesome/pro-solid-svg-icons/faImagePolaroid';
import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faMedal as fasMedal } from '@fortawesome/pro-solid-svg-icons/faMedal';
import { faLockAlt as fasLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faCameraHome as fasCameraHome } from '@fortawesome/pro-solid-svg-icons/faCameraHome';
import { faWrench as fasWrench } from '@fortawesome/pro-solid-svg-icons/faWrench';
import { faShoppingCart as fasShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { faChartPie as fasChartPie } from '@fortawesome/pro-solid-svg-icons/faChartPie';
import { faChartArea as fasChartArea } from '@fortawesome/pro-solid-svg-icons/faChartArea';
import { faAbacus as fasAbacus } from '@fortawesome/pro-solid-svg-icons/faAbacus';
import { faBadgePercent as fasBadgePercent } from '@fortawesome/pro-solid-svg-icons/faBadgePercent';
import { faPaperPlane as fasPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faRocket as fasRocket } from '@fortawesome/pro-solid-svg-icons/faRocket';
import { faFingerprint as fasFingerprint } from '@fortawesome/pro-solid-svg-icons/faFingerprint';
import { faFeatherAlt as fasFeatherAlt } from '@fortawesome/pro-solid-svg-icons/faFeatherAlt';
import { faRadar as fasRadar } from '@fortawesome/pro-solid-svg-icons/faRadar';
import { faGlobeStand as fasGlobeStand } from '@fortawesome/pro-solid-svg-icons/faGlobeStand';
import { faDesktop as fasDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop';
import { faCode as fasCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faFile as fasFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faDatabase as fasDatabase } from '@fortawesome/pro-solid-svg-icons/faDatabase';
import { faUpload as fasUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faSpinnerThird as fasSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import { faSlidersVSquare as fasSlidersVSquare } from '@fortawesome/pro-solid-svg-icons/faSlidersVSquare';
import { faPassport as fasPassport } from '@fortawesome/pro-solid-svg-icons/faPassport';

// END SOLID ICON MODAL
import { faClipboardListCheck as fasClipboardListCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardListCheck';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faGlobeEurope as fasGlobeEurope } from '@fortawesome/pro-solid-svg-icons/faGlobeEurope';
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faFolder as fasFolder } from '@fortawesome/pro-solid-svg-icons/faFolder';
import { faUserTag as fasUserTag } from '@fortawesome/pro-solid-svg-icons/faUserTag';
import { faBadgeCheck as fasBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';
import { faBell as fasBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faStopwatch as fasStopwatch } from '@fortawesome/pro-solid-svg-icons/faStopwatch';
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faLevelUp as fasLevelUp } from '@fortawesome/pro-solid-svg-icons/faLevelUp';
import { faEllipsisH as fasEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faFilter as fasFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faPen as fasPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faImages as fasImages } from '@fortawesome/pro-solid-svg-icons/faImages';
import { faSync as fasSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faSignOutAlt as fasSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt';
import { faSearchPlus as fasSearchPlus } from '@fortawesome/pro-solid-svg-icons/faSearchPlus';
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faArchive as fasArchive } from '@fortawesome/pro-solid-svg-icons/faArchive';
import { faMinus as fasMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faLongArrowLeft as fasLongArrowLeft } from '@fortawesome/pro-solid-svg-icons/faLongArrowLeft';
import { faKey as fasKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faBackward as fasBackward } from '@fortawesome/pro-solid-svg-icons/faBackward';
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faPenFancy as fasPenFancy } from '@fortawesome/pro-solid-svg-icons/faPenFancy';
import { faLock as fasLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faLink as fasLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faRedoAlt as fasRedoAlt } from '@fortawesome/pro-solid-svg-icons/faRedoAlt';
import { faTags as fasTags } from '@fortawesome/pro-solid-svg-icons/faTags';
import { faSyncAlt as fasSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt';
import { faExpand as fasExpand } from '@fortawesome/pro-solid-svg-icons/faExpand';
import { faComment as fasComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faUserFriends as fasUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends';
import { faCogs as fasCogs } from '@fortawesome/pro-solid-svg-icons/faCogs';
import { faEnvelopeOpenText as fasEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpenText';
import { faBellSlash as fasBellSlash } from '@fortawesome/pro-solid-svg-icons/faBellSlash';
import { faFileCheck as fasFileCheck } from '@fortawesome/pro-solid-svg-icons/faFileCheck';
import { faDraftingCompass as fasDraftingCompass } from '@fortawesome/pro-solid-svg-icons/faDraftingCompass';
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faUndo as fasUndo } from '@fortawesome/pro-solid-svg-icons/faUndo';
import { faRedo as fasRedo } from '@fortawesome/pro-solid-svg-icons/faRedo';
import { faBook as fasBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faChartBar as fasChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faCheckSquare as fasCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faBullhorn as fasBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn';
import { faCog as fasCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faEye as fasEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faParagraph as fasParagraph } from '@fortawesome/pro-solid-svg-icons/faParagraph';
import { faListUl as fasListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faListOl as fasListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faH1 as fasH1 } from '@fortawesome/pro-solid-svg-icons/faH1';
import { faH2 as fasH2 } from '@fortawesome/pro-solid-svg-icons/faH2';
import { faH3 as fasH3 } from '@fortawesome/pro-solid-svg-icons/faH3';
import { faH4 as fasH4 } from '@fortawesome/pro-solid-svg-icons/faH4';
import { faImage as fasImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faQuoteRight as fasQuoteRight } from '@fortawesome/pro-solid-svg-icons/faQuoteRight';
import { faTable as fasTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faLightbulb as fasLightbulb } from '@fortawesome/pro-solid-svg-icons/faLightbulb';
import { faWindow as fasWindow } from '@fortawesome/pro-solid-svg-icons/faWindow';
import { faPaperclip as fasPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';
import { faCompressAlt as fasCompressAlt } from '@fortawesome/pro-solid-svg-icons/faCompressAlt';
import { faGrinAlt as fasGrinAlt } from '@fortawesome/pro-solid-svg-icons/faGrinAlt';
import { faExternalLink as fasExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faHorizontalRule as fasHorizontalRule } from '@fortawesome/pro-solid-svg-icons/faHorizontalRule';
import { faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faServer as fasServer } from '@fortawesome/pro-solid-svg-icons/faServer';
import { faExternalLinkSquare as fasExternalLinkSquare } from '@fortawesome/pro-solid-svg-icons/faExternalLinkSquare';
import { faBookOpen as fasBookOpen } from '@fortawesome/pro-solid-svg-icons/faBookOpen';
import { faCamera as fasCamera } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faDolly as fasDolly } from '@fortawesome/pro-solid-svg-icons/faDolly';
import { faPlayCircle as fasPlayCircle } from '@fortawesome/pro-solid-svg-icons/faPlayCircle';
import { faInfinity as fasInfinity } from '@fortawesome/pro-solid-svg-icons/faInfinity';
import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faHome as fasHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faRulerTriangle as fasRulerTriangle } from '@fortawesome/pro-solid-svg-icons/faRulerTriangle';
import { faGripVertical as fasGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import { faSiren as fasSiren } from '@fortawesome/pro-solid-svg-icons/faSiren';
import { faWasher as fasWasher } from '@fortawesome/pro-solid-svg-icons/faWasher';
import { faFileCertificate as fasFileCertificate } from '@fortawesome/pro-solid-svg-icons/faFileCertificate';
import { faFileAlt as fasFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faMegaphone as fasMegaphone } from '@fortawesome/pro-solid-svg-icons/faMegaphone';
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faPuzzlePiece as fasPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';
import { faChartNetwork as fasChartNetwork } from '@fortawesome/pro-solid-svg-icons/faChartNetwork';
import { faAt as fasAt } from '@fortawesome/pro-solid-svg-icons/faAt';
import { faUnion as fasUnion } from '@fortawesome/pro-solid-svg-icons/faUnion';
import { faBolt as fasBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import { faThumbsUp as fasThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import { faThumbsDown as fasThumbsDown } from '@fortawesome/pro-solid-svg-icons/faThumbsDown';
import { faPenNib as fasPenNib } from '@fortawesome/pro-solid-svg-icons/faPenNib';
import { faSparkles as fasSparkles } from '@fortawesome/pro-solid-svg-icons/faSparkles';
import { faKeynote as fasKeynote } from '@fortawesome/pro-solid-svg-icons/faKeynote';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faClipboard as fasClipboard } from '@fortawesome/pro-solid-svg-icons';
import { faGraduationCap as fasGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faNetworkWired as fasNetworkWired } from '@fortawesome/pro-solid-svg-icons/faNetworkWired';

//REGULAR ICONS
import { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faEllipsisH as farEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faExternalLinkAlt as farExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt';
import { faPalette as farPalette } from '@fortawesome/pro-regular-svg-icons/faPalette';
import { faHighlighter as farHighlighter } from '@fortawesome/pro-regular-svg-icons/faHighlighter';
import { faSmile as farSmile } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faBold as farBold } from '@fortawesome/pro-regular-svg-icons/faBold';
import { faItalic as farItalic } from '@fortawesome/pro-regular-svg-icons/faItalic';
import { faUnderline as farUnderline } from '@fortawesome/pro-regular-svg-icons/faUnderline';
import { faHistory as farHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faH1 as farH1 } from '@fortawesome/pro-regular-svg-icons/faH1';
import { faH2 as farH2 } from '@fortawesome/pro-regular-svg-icons/faH2';
import { faH3 as farH3 } from '@fortawesome/pro-regular-svg-icons/faH3';
import { faList as farList } from '@fortawesome/pro-regular-svg-icons/faList';
import { faListOl as farListOl } from '@fortawesome/pro-regular-svg-icons/faListOl';
import { faHorizontalRule as farHorizontalRule } from '@fortawesome/pro-regular-svg-icons/faHorizontalRule';
import { faExclamationSquare as farExclamationSquare } from '@fortawesome/pro-regular-svg-icons/faExclamationSquare';
import { faUndo as farUndo } from '@fortawesome/pro-regular-svg-icons/faUndo';
import { faRedo as farRedo } from '@fortawesome/pro-regular-svg-icons/faRedo';
import { faWindowAlt as farWindowAlt } from '@fortawesome/pro-regular-svg-icons/faWindowAlt';
import { faImages as farImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faLink as farLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faPaperclip as farPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faBell as farBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faMegaphone as farMegaphone } from '@fortawesome/pro-regular-svg-icons/faMegaphone';
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faMinus as farMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faHome as farHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faSpinner as farSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { faCompressAlt as farCompressAlt } from '@fortawesome/pro-regular-svg-icons/faCompressAlt';
import { faExpandAlt as farExpandAlt } from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import { faLongArrowLeft as farLongArrowLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft';
import { faUserTimes as farUserTimes } from '@fortawesome/pro-regular-svg-icons/faUserTimes';
import { faClipboard as farClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard';
import { faThumbsDown as farThumbsDown } from '@fortawesome/pro-regular-svg-icons/faThumbsDown';
import { faThumbsUp as farThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';

//LIGHT ICONS
// START LIGHT ICON MODAL ----------------------
import { faFolderOpen as falFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faMailbox as falMailbox } from '@fortawesome/pro-light-svg-icons/faMailbox';
import { faAnchor as falAnchor } from '@fortawesome/pro-light-svg-icons/faAnchor';
import { faBagsShopping as falBagsShopping } from '@fortawesome/pro-light-svg-icons/faBagsShopping';
import { faBookmark as falBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faStars as falStars } from '@fortawesome/pro-light-svg-icons/faStars';
import { faMagic as falMagic } from '@fortawesome/pro-light-svg-icons/faMagic';
import { faBooks as falBooks } from '@fortawesome/pro-light-svg-icons/faBooks';
import { faImagePolaroid as falImagePolaroid } from '@fortawesome/pro-light-svg-icons/faImagePolaroid';
import { faMapMarkerAlt as falMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faUsers as falUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faMedal as falMedal } from '@fortawesome/pro-light-svg-icons/faMedal';
import { faLockAlt as falLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faCameraHome as falCameraHome } from '@fortawesome/pro-light-svg-icons/faCameraHome';
import { faWrench as falWrench } from '@fortawesome/pro-light-svg-icons/faWrench';
import { faShoppingCart as falShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import { faChartPie as falChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faChartArea as falChartArea } from '@fortawesome/pro-light-svg-icons/faChartArea';
import { faAbacus as falAbacus } from '@fortawesome/pro-light-svg-icons/faAbacus';
import { faBadgePercent as falBadgePercent } from '@fortawesome/pro-light-svg-icons/faBadgePercent';
import { faPaperPlane as falPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faRocket as falRocket } from '@fortawesome/pro-light-svg-icons/faRocket';
import { faFeatherAlt as falFeatherAlt } from '@fortawesome/pro-light-svg-icons/faFeatherAlt';
import { faRadar as falRadar } from '@fortawesome/pro-light-svg-icons/faRadar';
import { faGlobeStand as falGlobeStand } from '@fortawesome/pro-light-svg-icons/faGlobeStand';
import { faDesktop as falDesktop } from '@fortawesome/pro-light-svg-icons/faDesktop';
import { faCode as falCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faFile as falFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faDatabase as falDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faUpload as falUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faClipboard as falClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faClock as falClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faLevelUp as falLevelUp } from '@fortawesome/pro-light-svg-icons/faLevelUp';
import { faLockOpen as falLockOpen } from '@fortawesome/pro-light-svg-icons/faLockOpen';
import { faHandPointer as falLockPointer } from '@fortawesome/pro-light-svg-icons/faHandPointer';
import { faPassport as falPassport } from '@fortawesome/pro-light-svg-icons/faPassport';
import { faNetworkWired as falNetworkWired } from '@fortawesome/pro-light-svg-icons/faNetworkWired';
import { faArrowFromLeft as falArrowFromLeft } from '@fortawesome/pro-light-svg-icons/faArrowFromLeft';
import { faArrowFromRight as falArrowFromRight } from '@fortawesome/pro-light-svg-icons/faArrowFromRight';

// END LIGHT ICON MODAL ----------------------
import { faChevronUp as falChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown as falChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faArrowLeft as falArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faEllipsisH as falEllipsisH } from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faBrain as falBrain } from '@fortawesome/pro-light-svg-icons/faBrain';
import { faFileAlt as falFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faTags as falTags } from '@fortawesome/pro-light-svg-icons/faTags';
import { faCloudUpload as falCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { faLink as falLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faTrash as falTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faSearch as falSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faInfoCircle as falInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faTimesOctagon as falTimesOctagon } from '@fortawesome/pro-light-svg-icons/faTimesOctagon';
import { faTimesCircle as falTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faBell as falBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faPlusCircle as falPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faExclamationSquare as falExclamationSquare } from '@fortawesome/pro-light-svg-icons/faExclamationSquare';
import { faChevronLeft as falChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight as falChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faPlusSquare as falPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare';
import { faPen as falPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faMapPin as falMapPin } from '@fortawesome/pro-light-svg-icons/faMapPin';
import { faEllipsisV as falEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faUser as falUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserClock as falUserClock } from '@fortawesome/pro-light-svg-icons';
import { faCalendarWeek as falCalendarWeek } from '@fortawesome/pro-light-svg-icons/faCalendarWeek';
import { faHeart as falHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faBook as falBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faIcons as falIcons } from '@fortawesome/pro-light-svg-icons/faIcons';
import { faChartBar as falChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar';
import { faCheckSquare as falCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import { faBullhorn as falBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faCog as falCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faExternalLink as falExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faKey as falKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faGlobeAmericas as falGlobeAmericas } from '@fortawesome/pro-light-svg-icons/faGlobeAmericas';
import { faCircle as falCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faExclamationTriangle as falExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faCopyright as falCopyright } from '@fortawesome/pro-light-svg-icons/faCopyright';
import { faPalette as falPalette } from '@fortawesome/pro-light-svg-icons/faPalette';
import { faGlobeAfrica as falGlobeAfrica } from '@fortawesome/pro-light-svg-icons/faGlobeAfrica';
import { faGlobe as falGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faDumbbell as falDumbbell } from '@fortawesome/pro-light-svg-icons/faDumbbell';
import { faQuestionCircle as falQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faCircleNotch as falCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch';
import { faArrowToBottom as falArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faBold as falBold } from '@fortawesome/pro-light-svg-icons/faBold';
import { faItalic as falItalic } from '@fortawesome/pro-light-svg-icons/faItalic';
import { faUnderline as falUnderline } from '@fortawesome/pro-light-svg-icons/faUnderline';
import { faStrikethrough as falStrikethrough } from '@fortawesome/pro-light-svg-icons/faStrikethrough';
import { faAlignLeft as falAlignLeft } from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import { faAlignCenter as falAlignCenter } from '@fortawesome/pro-light-svg-icons/faAlignCenter';
import { faAlignRight as falAlignRight } from '@fortawesome/pro-light-svg-icons/faAlignRight';
import { faListUl as falListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faListOl as falListOl } from '@fortawesome/pro-light-svg-icons/faListOl';
import { faBrush as falBrush } from '@fortawesome/pro-light-svg-icons/faBrush';
import { faPenFancy as falPenFancy } from '@fortawesome/pro-light-svg-icons/faPenFancy';
import { faWindow as falWindow } from '@fortawesome/pro-light-svg-icons/faWindow';
import { faWindowMaximize as falWindowMaximize } from '@fortawesome/pro-light-svg-icons/faWindowMaximize';
import { faUnlink as falUnlink } from '@fortawesome/pro-light-svg-icons/faUnlink';
import { faWindowClose as falWindowClose } from '@fortawesome/pro-light-svg-icons/faWindowClose';
import { faThLarge as falThLarge } from '@fortawesome/pro-light-svg-icons/faThLarge';
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faText as falText } from '@fortawesome/pro-light-svg-icons/faText';
import { faSpinnerThird as falSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faLightbulb as falLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb';
import { faTint as falTint } from '@fortawesome/pro-light-svg-icons/faTint';
import { faRectangleLandscape as falRectangleLandscape } from '@fortawesome/pro-light-svg-icons/faRectangleLandscape';
import { faBracketsCurly as falBracketsCurly } from '@fortawesome/pro-light-svg-icons';
import { faFileImport as falFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport';
import { faEmptySet as falEmptySet } from '@fortawesome/pro-light-svg-icons/faEmptySet';
import { faAsterisk as falAsterisk } from '@fortawesome/pro-light-svg-icons/faAsterisk';
import { faCalendarAlt as falCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faFilter as falFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faTag as falTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faExpandAlt as falExpandAlt } from '@fortawesome/pro-light-svg-icons/faExpandAlt';
import { faSync as falSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faSyncAlt as falSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { faLock as falLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faEdit as falEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faSlidersVSquare as falSlidersVSquare } from '@fortawesome/pro-light-svg-icons/faSlidersVSquare';
import { faPlug as falPlug } from '@fortawesome/pro-light-svg-icons/faPlug';
import { faUnlockAlt as falUnlockAlt } from '@fortawesome/pro-light-svg-icons/faUnlockAlt';
import { faLanguage as falLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faPowerOff as falPowerOff } from '@fortawesome/pro-light-svg-icons/faPowerOff';
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faPager as falPager } from '@fortawesome/pro-light-svg-icons/faPager';
import { faCheck as falCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faHome as falHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faLockOpenAlt as falLockOpenAlt } from '@fortawesome/pro-light-svg-icons/faLockOpenAlt';
import { faAngleDown as falAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faFolder as falFolder } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faFolderTimes as falFolderTimes } from '@fortawesome/pro-light-svg-icons/faFolderTimes';
import { faBars as falBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faSave as falSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { faArrowsH as falArrowsH } from '@fortawesome/pro-light-svg-icons/faArrowsH';
import { faStar as falStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faShieldCheck as falShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faUserSlash as falUserSlash } from '@fortawesome/pro-light-svg-icons/faUserSlash';
import { faBellExclamation as falBellExclamation } from '@fortawesome/pro-light-svg-icons/faBellExclamation';
import { faUndo as falUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { faMegaphone as falMegaphone } from '@fortawesome/pro-light-svg-icons/faMegaphone';
import { faExternalLinkSquare as falExternalLinkSquare } from '@fortawesome/pro-light-svg-icons/faExternalLinkSquare';
import { faEyeSlash as falEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faEye as falEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faSlidersV as falSlidersV } from '@fortawesome/pro-light-svg-icons/faSlidersV';
import { faThumbtack as falThumbtack } from '@fortawesome/pro-light-svg-icons/faThumbtack';
import { faFilePdf as falFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileImage as falFileImage } from '@fortawesome/pro-light-svg-icons/faFileImage';
import { faDownload as falDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faCommentAlt as falCommentAlt } from '@fortawesome/pro-light-svg-icons/faCommentAlt';
import { faCommentAltCheck as falCommentAltCheck } from '@fortawesome/pro-light-svg-icons/faCommentAltCheck';
import { faCommentAltDots as falCommentAltDots } from '@fortawesome/pro-light-svg-icons/faCommentAltDots';
import { faCommentAltTimes as falCommentAltTimes } from '@fortawesome/pro-light-svg-icons/faCommentAltTimes';
import { faSpinner as falSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faArchive as falArchive } from '@fortawesome/pro-light-svg-icons/faArchive';
import { faPencil as falPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faPaperclip as falPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faSmile as falSmile } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faArrowUp as falArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faChartLine as falChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faThumbsUp as falThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faThumbsDown as falThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown';
import { faHandHoldingMagic as falHandHoldingMagic } from '@fortawesome/pro-light-svg-icons/faHandHoldingMagic';
import { faBolt as falBolt } from '@fortawesome/pro-light-svg-icons/faBolt';
import { faCodeMerge as falCodeMerge } from '@fortawesome/pro-light-svg-icons/faCodeMerge';
import { faAt as falAt } from '@fortawesome/pro-light-svg-icons/faAt';
import { faClipboardListCheck as falClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import { faSparkles as falSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faExternalLinkAlt as falExternalLinkAlt } from '@fortawesome/pro-light-svg-icons/faExternalLinkAlt';
import { faFileSearch as falFileSearch } from '@fortawesome/pro-light-svg-icons/faFileSearch';
import { faArrowRight as falArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faPenNib as falPenNib } from '@fortawesome/pro-light-svg-icons/faPenNib';
import { faRepeat as falRepeat } from '@fortawesome/pro-light-svg-icons/faRepeat';
import { faFileEdit as falFileEdit } from '@fortawesome/pro-light-svg-icons/faFileEdit';
import { faFileCheck as falFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faGift as falGift } from '@fortawesome/pro-light-svg-icons/faGift';
import { faFileMinus as falFileMinus } from '@fortawesome/pro-light-svg-icons/faFileMinus';
import { faPersonDolly as falPersonDolly } from '@fortawesome/pro-light-svg-icons/faPersonDolly';
import { faDigging as falDigging } from '@fortawesome/pro-light-svg-icons/faDigging';
import { faChalkboardTeacher as falChalkboardTeacher } from '@fortawesome/pro-light-svg-icons/faChalkboardTeacher';
import { faKeynote as falKeynote } from '@fortawesome/pro-light-svg-icons/faKeynote';
import { faCaretRight as falCaretRight } from '@fortawesome/pro-light-svg-icons/faCaretRight';
import { faComments as falComments } from '@fortawesome/pro-light-svg-icons';
import { faCommentsAlt as falCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { faHistory as falHistory } from '@fortawesome/pro-light-svg-icons';
import { faImages as falImages } from '@fortawesome/pro-light-svg-icons';
import { faGraduationCap as falGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faUserGraduate as falUserGraduate } from '@fortawesome/pro-light-svg-icons/faUserGraduate';
import { faSchool as falSchool } from '@fortawesome/pro-light-svg-icons/faSchool';
import { faDiploma as falDiploma } from '@fortawesome/pro-light-svg-icons/faDiploma';
import { faFileCertificate as falFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate';
import { faCubes as falCubes } from '@fortawesome/pro-light-svg-icons/faCubes';
import { faBookOpen as falBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen';
import { faPuzzlePiece as falPuzzlePiece } from '@fortawesome/pro-light-svg-icons/faPuzzlePiece';
import { faTrophyAlt as falTrophyAlt } from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeOpenText as falEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faSlidersH as falSlidersH } from '@fortawesome/pro-light-svg-icons';
import { faUserEdit as falUserEdit } from '@fortawesome/pro-light-svg-icons';
import { faUserCheck as falUserCheck } from '@fortawesome/pro-light-svg-icons';
import { faEquals as falEquals } from '@fortawesome/pro-light-svg-icons';
import { faNotEqual as falNotEqual } from '@fortawesome/pro-light-svg-icons';
import { faSiren as falSiren } from '@fortawesome/pro-light-svg-icons';
import { faPhone as falPhone } from '@fortawesome/pro-light-svg-icons';
import { faWasher as falWasher } from '@fortawesome/pro-light-svg-icons';
import { faBarcodeScan as falBarcodeScan } from '@fortawesome/pro-light-svg-icons';
import { faRulerTriangle as falRulerTriangle } from '@fortawesome/pro-light-svg-icons';
import { faInboxIn as falInboxIn } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';

//DUOTONE  ICONS
// START DUOTONE ICON MODAL
import { faFolderOpen as fadFolderOpen } from '@fortawesome/pro-duotone-svg-icons/faFolderOpen';
import { faMailbox as fadMailbox } from '@fortawesome/pro-duotone-svg-icons/faMailbox';
import { faAnchor as fadAnchor } from '@fortawesome/pro-duotone-svg-icons/faAnchor';
import { faBagsShopping as fadBagsShopping } from '@fortawesome/pro-duotone-svg-icons/faBagsShopping';
import { faBookmark as fadBookmark } from '@fortawesome/pro-duotone-svg-icons/faBookmark';
import { faStars as fadStars } from '@fortawesome/pro-duotone-svg-icons/faStars';
import { faMagic as fadMagic } from '@fortawesome/pro-duotone-svg-icons/faMagic';
import { faBooks as fadBooks } from '@fortawesome/pro-duotone-svg-icons/faBooks';
import { faImagePolaroid as fadImagePolaroid } from '@fortawesome/pro-duotone-svg-icons/faImagePolaroid';
import { faMapMarkerAlt as fadMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons/faMapMarkerAlt';
import { faUsers as fadUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import { faMedal as fadMedal } from '@fortawesome/pro-duotone-svg-icons/faMedal';
import { faLockAlt as fadLockAlt } from '@fortawesome/pro-duotone-svg-icons/faLockAlt';
import { faCameraHome as fadCameraHome } from '@fortawesome/pro-duotone-svg-icons/faCameraHome';
import { faWrench as fadWrench } from '@fortawesome/pro-duotone-svg-icons/faWrench';
import { faShoppingCart as fadShoppingCart } from '@fortawesome/pro-duotone-svg-icons/faShoppingCart';
import { faChartPie as fadChartPie } from '@fortawesome/pro-duotone-svg-icons/faChartPie';
import { faChartArea as fadChartArea } from '@fortawesome/pro-duotone-svg-icons/faChartArea';
import { faAbacus as fadAbacus } from '@fortawesome/pro-duotone-svg-icons/faAbacus';
import { faBadgePercent as fadBadgePercent } from '@fortawesome/pro-duotone-svg-icons/faBadgePercent';
import { faPaperPlane as fadPaperPlane } from '@fortawesome/pro-duotone-svg-icons/faPaperPlane';
import { faRocket as fadRocket } from '@fortawesome/pro-duotone-svg-icons/faRocket';
import { faFeatherAlt as fadFeatherAlt } from '@fortawesome/pro-duotone-svg-icons/faFeatherAlt';
import { faRadar as fadRadar } from '@fortawesome/pro-duotone-svg-icons/faRadar';
import { faGlobeStand as fadGlobeStand } from '@fortawesome/pro-duotone-svg-icons/faGlobeStand';
import { faDesktop as fadDesktop } from '@fortawesome/pro-duotone-svg-icons/faDesktop';
import { faCode as fadCode } from '@fortawesome/pro-duotone-svg-icons/faCode';
import { faFile as fadFile } from '@fortawesome/pro-duotone-svg-icons/faFile';
import { faDatabase as fadDatabase } from '@fortawesome/pro-duotone-svg-icons/faDatabase';
import { faUpload as fadUpload } from '@fortawesome/pro-duotone-svg-icons/faUpload';
// END DUOTONE ICON MODAL
import { faAtomAlt as fadAtom } from '@fortawesome/pro-duotone-svg-icons/faAtomAlt';
import { faHome as fadHome } from '@fortawesome/pro-duotone-svg-icons/faHome';
import { faEye as fadEye } from '@fortawesome/pro-duotone-svg-icons/faEye';
import { faFileWord as fadFileWord } from '@fortawesome/pro-duotone-svg-icons/faFileWord';
import { faTrash as fadTrash } from '@fortawesome/pro-duotone-svg-icons/faTrash';
import { faCloudUpload as fadCloudUpload } from '@fortawesome/pro-duotone-svg-icons/faCloudUpload';
import { faArrowSquareLeft as fadArrowSquareLeft } from '@fortawesome/pro-duotone-svg-icons/faArrowSquareLeft';
import { faCheck as fadCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';
import { faExternalLinkAlt as fadExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons/faExternalLinkAlt';
import { faSearch as fadSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch';
import { faInfoCircle as fadInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import { faThList as fadThList } from '@fortawesome/pro-duotone-svg-icons/faThList';
import { faArrowUp as fadArrowUp } from '@fortawesome/pro-duotone-svg-icons/faArrowUp';
import { faArrowDown as fadArrowDown } from '@fortawesome/pro-duotone-svg-icons/faArrowDown';
import { faEquals as fadEquals } from '@fortawesome/pro-duotone-svg-icons/faEquals';
import { faDownload as fadDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload';
import { faClipboard as fadClipboard } from '@fortawesome/pro-duotone-svg-icons/faClipboard';
import { faClone as fadClone } from '@fortawesome/pro-duotone-svg-icons/faClone';
import { faObjectGroup as fadObjectGroup } from '@fortawesome/pro-duotone-svg-icons/faObjectGroup';
import { faHistory as fadHistory } from '@fortawesome/pro-duotone-svg-icons/faHistory';
import { faFolder as fadFolder } from '@fortawesome/pro-duotone-svg-icons/faFolder';
import { faPencil as fadPencil } from '@fortawesome/pro-duotone-svg-icons/faPencil';
import { faEdit as fadEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit';
import { faPlusCircle as fadPlusCircle } from '@fortawesome/pro-duotone-svg-icons/faPlusCircle';
import { faPlus as fadPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus';
import { faBarcodeScan as fadBarcodeScan } from '@fortawesome/pro-duotone-svg-icons/faBarcodeScan';
import { faSiren as fadSiren } from '@fortawesome/pro-duotone-svg-icons/faSiren';
import { faChartNetwork as fadChartNetwork } from '@fortawesome/pro-duotone-svg-icons/faChartNetwork';
import { faCalendarDay as fadCalendarDay } from '@fortawesome/pro-duotone-svg-icons/faCalendarDay';
import { faCalendar as fadCalendar } from '@fortawesome/pro-duotone-svg-icons/faCalendar';
import { faUser as fadUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { faCircle as fadCircle } from '@fortawesome/pro-duotone-svg-icons/faCircle';
import { faSlidersV as fadSlidersV } from '@fortawesome/pro-duotone-svg-icons/faSlidersV';
import { faKey as fadKey } from '@fortawesome/pro-duotone-svg-icons/faKey';
import { faLevelUp as fadLevelUp } from '@fortawesome/pro-duotone-svg-icons/faLevelUp';
import { faListUl as fadListUl } from '@fortawesome/pro-duotone-svg-icons/faListUl';
import { faSlidersVSquare as fadSlidersVSquare } from '@fortawesome/pro-duotone-svg-icons/faSlidersVSquare';
import { faUnlockAlt as fadUnlockAlt } from '@fortawesome/pro-duotone-svg-icons/faUnlockAlt';
import { faCog as fadCog } from '@fortawesome/pro-duotone-svg-icons/faCog';
import { faPowerOff as fadPowerOff } from '@fortawesome/pro-duotone-svg-icons/faPowerOff';
import { faSync as fadSync } from '@fortawesome/pro-duotone-svg-icons/faSync';
import { faMegaphone as fadMegaphone } from '@fortawesome/pro-duotone-svg-icons/faMegaphone';
import { faSignOut as fadSignOut } from '@fortawesome/pro-duotone-svg-icons/faSignOut';
import { faTvRetro as fadTvRetro } from '@fortawesome/pro-duotone-svg-icons/faTvRetro';
import { faExternalLink as fadExternalLink } from '@fortawesome/pro-duotone-svg-icons/faExternalLink';
import { faFont as fadFont } from '@fortawesome/pro-duotone-svg-icons/faFont';
import { faGlobe as fadGlobe } from '@fortawesome/pro-duotone-svg-icons/faGlobe';
import { faPen as fadPen } from '@fortawesome/pro-duotone-svg-icons/faPen';
import { faSparkles as fadSparkles } from '@fortawesome/pro-duotone-svg-icons/faSparkles';
import { faTelescope as fadTelescope } from '@fortawesome/pro-duotone-svg-icons/faTelescope';
import { faSirenOn as fadSirenOn } from '@fortawesome/pro-duotone-svg-icons/faSirenOn';
import { faUserRobot as fadUserRobot } from '@fortawesome/pro-duotone-svg-icons/faUserRobot';
import { faSave as fadSave } from '@fortawesome/pro-duotone-svg-icons/faSave';
import { faArrowToLeft as fadArrowToLeft } from '@fortawesome/pro-duotone-svg-icons/faArrowToLeft';
import { faFileUpload as fadFileUpload } from '@fortawesome/pro-duotone-svg-icons/faFileUpload';
import { faBell as fadBell } from '@fortawesome/pro-duotone-svg-icons/faBell';
import { faLockOpen as fadLockOpen } from '@fortawesome/pro-duotone-svg-icons/faLockOpen';
import { faSyncAlt as fadSyncAlt } from '@fortawesome/pro-duotone-svg-icons/faSyncAlt';
import { faEnvelope as fadEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope';
import { faPaperclip as fadPaperclip } from '@fortawesome/pro-duotone-svg-icons/faPaperclip';
import { faLocationCircle as fadLocationCircle } from '@fortawesome/pro-duotone-svg-icons/faLocationCircle';
import { faArchive as fadArchive } from '@fortawesome/pro-duotone-svg-icons/faArchive';
import { faLineColumns as fadLineColumns } from '@fortawesome/pro-duotone-svg-icons/faLineColumns';
import { faLink as fadLink } from '@fortawesome/pro-duotone-svg-icons/faLink';
import { faCaretLeft as fadCaretLeft } from '@fortawesome/pro-duotone-svg-icons/faCaretLeft';
import { faCaretRight as fadCaretRight } from '@fortawesome/pro-duotone-svg-icons/faCaretRight';
import { faBells as fadBells } from '@fortawesome/pro-duotone-svg-icons/faBells';
import { faCopy as fadCopy } from '@fortawesome/pro-duotone-svg-icons/faCopy';
import { faExternalLinkSquare as fadExternalLinkSquare } from '@fortawesome/pro-duotone-svg-icons/faExternalLinkSquare';
import { faThumbsUp as fadThumbsUp } from '@fortawesome/pro-duotone-svg-icons/faThumbsUp';
import { faThumbsDown as fadThumbsDown } from '@fortawesome/pro-duotone-svg-icons/faThumbsDown';
import { faHomeLg as fadHomeLg } from '@fortawesome/pro-duotone-svg-icons/faHomeLg';
import { faHomeLgAlt as fadHomeLgAlt } from '@fortawesome/pro-duotone-svg-icons/faHomeLgAlt';
import { faStar as fadStar } from '@fortawesome/pro-duotone-svg-icons/faStar';
import { faChartLine as fadChartLine } from '@fortawesome/pro-duotone-svg-icons/faChartLine';
import { faArrowRight as fadArrowRight } from '@fortawesome/pro-duotone-svg-icons/faArrowRight';
import { faFileSearch as fadFileSearch } from '@fortawesome/pro-duotone-svg-icons/faFileSearch';
import { faMoneyCheckEdit as fadMoneyCheckEdit } from '@fortawesome/pro-duotone-svg-icons/faMoneyCheckEdit';
import { faBuilding as fadBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';
import { faIdCardAlt as fadIdCardAlt } from '@fortawesome/pro-duotone-svg-icons/faIdCardAlt';
import { faNotEqual as fadNotEqual } from '@fortawesome/pro-duotone-svg-icons/faNotEqual';
import { faPhone as fadPhone } from '@fortawesome/pro-duotone-svg-icons/faPhone';
import { faUserFriends as fadUserFriends } from '@fortawesome/pro-duotone-svg-icons/faUserFriends';
import { faUsersCog as fadUsersCog } from '@fortawesome/pro-duotone-svg-icons/faUsersCog';
import { faRedoAlt as fadRedoAlt } from '@fortawesome/pro-duotone-svg-icons/faRedoAlt';
import { faLanguage as fadLanguage } from '@fortawesome/pro-duotone-svg-icons/faLanguage';
import { faTags as fadTags } from '@fortawesome/pro-duotone-svg-icons/faTags';
import { faTag as fadTag } from '@fortawesome/pro-duotone-svg-icons/faTag';
import { faTimesCircle as fadTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle';
import { faCalendarWeek as fadCalendarWeek } from '@fortawesome/pro-duotone-svg-icons/faCalendarWeek';
import { faUndo as fadUndo } from '@fortawesome/pro-duotone-svg-icons/faUndo';
import { faProjectDiagram as fadProjectDiagram } from '@fortawesome/pro-duotone-svg-icons/faProjectDiagram';
import { faUserTag as fadUserTag } from '@fortawesome/pro-duotone-svg-icons/faUserTag';
import { faBomb as fadBomb } from '@fortawesome/pro-duotone-svg-icons/faBomb';
import { faLayerGroup as fadLayerGroup } from '@fortawesome/pro-duotone-svg-icons/faLayerGroup';
import { faPlug as fadPlug } from '@fortawesome/pro-duotone-svg-icons/faPlug';
import { faWasher as fadWasher } from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardCheck as fadClipboardCheck } from '@fortawesome/pro-duotone-svg-icons/faClipboardCheck';
import { faInfo as fadInfo } from '@fortawesome/pro-duotone-svg-icons/faInfo';
import { faCheckCircle as fadCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { faExclamationTriangle as fadExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons/faExclamationTriangle';
import { faThLarge as fadThLarge } from '@fortawesome/pro-duotone-svg-icons/faThLarge';
import { faBookOpen as fadBookOpen } from '@fortawesome/pro-duotone-svg-icons/faBookOpen';
import { faCamera as fadCamera } from '@fortawesome/pro-duotone-svg-icons/faCamera';
import { faClipboardListCheck as fadClipboardListCheck } from '@fortawesome/pro-duotone-svg-icons/faClipboardListCheck';
import { faDolly as fadDolly } from '@fortawesome/pro-duotone-svg-icons/faDolly';
import { faGlobeEurope as fadGlobeEurope } from '@fortawesome/pro-duotone-svg-icons/faGlobeEurope';
import { faPlayCircle as fadPlayCircle } from '@fortawesome/pro-duotone-svg-icons/faPlayCircle';
import { faInfinity as fadInfinity } from '@fortawesome/pro-duotone-svg-icons/faInfinity';
import { faFilter as fadFilter } from '@fortawesome/pro-duotone-svg-icons/faFilter';
import { faInboxIn as fadInboxIn } from '@fortawesome/pro-duotone-svg-icons/faInboxIn';
import { faCogs as fadCogs } from '@fortawesome/pro-duotone-svg-icons/faCogs';
import { faClock as fadClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { faFileCheck as fadFileCheck } from '@fortawesome/pro-duotone-svg-icons/faFileCheck';
import { faPuzzlePiece as fadPuzzlePiece } from '@fortawesome/pro-duotone-svg-icons/faPuzzlePiece';
import { faThumbtack as fadThumbtack } from '@fortawesome/pro-duotone-svg-icons/faThumbtack';
import { faBellExclamation as fadBellExclamation } from '@fortawesome/pro-duotone-svg-icons/faBellExclamation';
import { faUserCog as fadUserCog } from '@fortawesome/pro-duotone-svg-icons/faUserCog';
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faHourglass as fadHourglass } from '@fortawesome/pro-duotone-svg-icons/faHourglass';
import { faGraduationCap as fadGraduationCap } from '@fortawesome/pro-duotone-svg-icons/faGraduationCap';
import { faFileEdit as fadFileEdit } from '@fortawesome/pro-duotone-svg-icons/faFileEdit';
import { faEyeSlash as fadEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash';
//BRAND ICONS
import { faChrome } from '@fortawesome/free-brands-svg-icons/faChrome';
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons/faSalesforce';
import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons/faSearchengin';

import { faCommentCheck as fadCommentCheck } from '@fortawesome/pro-duotone-svg-icons/faCommentCheck';
import { faCommentTimes as fadCommentTimes } from '@fortawesome/pro-duotone-svg-icons/faCommentTimes';
import { faComments as fadComments } from '@fortawesome/pro-duotone-svg-icons/faComments';
import { faSmile as fadSmile } from '@fortawesome/pro-duotone-svg-icons/faSmile';

export default function (Vue) {
  //SOLID
  library.add(
    // START SOLID ICON MODAL
    fasFolderOpen,
    fasMailbox,
    fasAnchor,
    fasBagsShopping,
    fasBookmark,
    fasStars,
    fasMagic,
    fasBooks,
    fasImagePolaroid,
    fasMapMarkerAlt,
    fasUsers,
    fasMedal,
    fasLockAlt,
    fasCameraHome,
    fasWrench,
    fasShoppingCart,
    fasChartPie,
    fasChartArea,
    fasAbacus,
    fasBadgePercent,
    fasPaperPlane,
    fasRocket,
    fasFeatherAlt,
    fasRadar,
    fasGlobeStand,
    fasDesktop,
    fasCode,
    fasFile,
    fasDatabase,
    fasUpload,
    fasFingerprint,
    // END SOLID ICON MODAL
    fasClipboardListCheck,
    fasClipboard,
    fasTimesCircle,
    fasFileCheck,
    fasBellSlash,
    fasEnvelopeOpenText,
    fasCogs,
    fasMedal,
    fasUserFriends,
    fasTimes,
    fasChevronRight,
    fasGlobeEurope,
    fasHeart,
    fasCircle,
    fasFolder,
    fasUserTag,
    fasBadgeCheck,
    fasBell,
    fasPaperPlane,
    fasStopwatch,
    fasCheck,
    fasLevelUp,
    fasEllipsisH,
    fasDownload,
    fasSearch,
    fasExclamationCircle,
    fasFilter,
    fasPen,
    fasImages,
    fasSync,
    fasSignOutAlt,
    fasSearchPlus,
    fasTrash,
    fasArchive,
    fasMinus,
    fasPlus,
    fasLongArrowLeft,
    fasKey,
    fasBackward,
    fasUser,
    fasPenFancy,
    fasLock,
    fasLink,
    fasUsers,
    fasInfoCircle,
    fasRedoAlt,
    fasTags,
    fasSyncAlt,
    fasExpand,
    fasComment,
    fasDraftingCompass,
    fasCheckCircle,
    fasRadar,
    fasRedo,
    fasUndo,
    fasBook,
    fasChartBar,
    fasCheckSquare,
    fasBullhorn,
    fasMagic,
    fasCog,
    fasEye,
    fasParagraph,
    fasListUl,
    fasListOl,
    fasH1,
    fasH2,
    fasH3,
    fasH4,
    fasImage,
    fasQuoteRight,
    fasCode,
    fasTable,
    fasLightbulb,
    fasWindow,
    fasPaperclip,
    fasCompressAlt,
    fasGrinAlt,
    fasExternalLink,
    fasHorizontalRule,
    fasChartPie,
    fasArrowRight,
    fasServer,
    fasExternalLinkSquare,
    fasBookOpen,
    fasCamera,
    fasDolly,
    fasPlayCircle,
    fasInfinity,
    fasPhone,
    fasWrench,
    fasHome,
    fasBooks,
    fasRulerTriangle,
    fasGripVertical,
    fasGlobeStand,
    fasCameraHome,
    fasBagsShopping,
    fasMapMarkerAlt,
    fasLockAlt,
    fasSiren,
    fasWasher,
    fasFileCertificate,
    fasFileAlt,
    fasMegaphone,
    fasStar,
    fasSpinnerThird,
    fasPuzzlePiece,
    fasChartNetwork,
    fasAt,
    fasUnion,
    fasBolt,
    fasThumbsUp,
    fasThumbsDown,
    fasPenNib,
    fasSparkles,
    fasKeynote,
    fasExclamationTriangle,
    fasGraduationCap,
    fasSlidersVSquare,
    fasNetworkWired,
    fasPassport,
  );

  //REGULAR
  library.add(
    farHeart,
    farEllipsisH,
    farExternalLinkAlt,
    farPalette,
    farHighlighter,
    farSmile,
    farBold,
    farItalic,
    farUnderline,
    farHistory,
    farH1,
    farH2,
    farH3,
    farList,
    farListOl,
    farHorizontalRule,
    farExclamationSquare,
    farUndo,
    farRedo,
    farWindowAlt,
    farImages,
    farLink,
    farPaperclip,
    farSearch,
    farBell,
    farInfoCircle,
    farMegaphone,
    farPlus,
    farMinus,
    farChevronLeft,
    farChevronRight,
    farHome,
    farArrowLeft,
    farSpinner,
    farExpandAlt,
    farCompressAlt,
    farLongArrowLeft,
    farUserTimes,
    farClipboard,
    farThumbsDown,
    farThumbsUp,
  );

  //LIGHT
  library.add(
    // START LIGHT ICON MODAL
    falFolderOpen,
    falMailbox,
    falAnchor,
    falBagsShopping,
    falBookmark,
    falStars,
    falMagic,
    falBooks,
    falImagePolaroid,
    falMapMarkerAlt,
    falUsers,
    falMedal,
    falLockAlt,
    falCameraHome,
    falWrench,
    falShoppingCart,
    falChartPie,
    falChartArea,
    falAbacus,
    falBadgePercent,
    falPaperPlane,
    falRocket,
    falFeatherAlt,
    falRadar,
    falGlobeStand,
    falDesktop,
    falCode,
    falFile,
    falDatabase,
    falUpload,
    falLockOpen,
    falLockPointer,
    // END LIGHT ICON MODAL
    falIcons,
    falChevronUp,
    falChevronDown,
    falArrowLeft,
    falEllipsisH,
    falPlus,
    falTimes,
    falBrain,
    falFileAlt,
    falTags,
    falCloudUpload,
    falLink,
    falTrash,
    falSearch,
    falInfoCircle,
    falTimesOctagon,
    falTimesCircle,
    falBell,
    falPlusCircle,
    falExclamationSquare,
    falChevronLeft,
    falChevronRight,
    falPlusSquare,
    falPen,
    falMapPin,
    falEllipsisV,
    falUser,
    falUserClock,
    falCalendarWeek,
    falHeart,
    falCalendar,
    falBook,
    falChartBar,
    falCheckSquare,
    falBullhorn,
    falCog,
    falExternalLink,
    falIcons,
    falLock,
    falKey,
    falGlobeAmericas,
    falCircle,
    falExclamationTriangle,
    falCopyright,
    falPalette,
    falText,
    falGlobeAfrica,
    falGlobe,
    falDumbbell,
    falQuestionCircle,
    falCircleNotch,
    falArrowToBottom,
    falBold,
    falBrush,
    falPenFancy,
    falAlignRight,
    falItalic,
    falUnderline,
    falStrikethrough,
    falAlignLeft,
    falAlignCenter,
    falListUl,
    falListOl,
    falWindow,
    falWindowMaximize,
    falUnlink,
    falWindowClose,
    falThLarge,
    falCheckCircle,
    falSpinnerThird,
    falLightbulb,
    falTint,
    falRectangleLandscape,
    falBracketsCurly,
    falFileImport,
    falEmptySet,
    falAsterisk,
    falCalendarAlt,
    falFilter,
    falTag,
    falExpandAlt,
    falSyncAlt,
    falSync,
    falEdit,
    falSlidersVSquare,
    falPlug,
    falUnlockAlt,
    falLanguage,
    falPowerOff,
    falEnvelope,
    falPager,
    falCheck,
    falHome,
    falLockOpenAlt,
    falAngleDown,
    falFolder,
    falFolderTimes,
    falBars,
    falSave,
    falArrowsH,
    falStar,
    falClipboard,
    falShieldCheck,
    falUserSlash,
    falBellExclamation,
    falUndo,
    falMegaphone,
    falExternalLinkSquare,
    falClock,
    falEyeSlash,
    falEye,
    falSlidersV,
    falThumbtack,
    falHandHoldingMagic,
    falBolt,
    falCodeMerge,
    falAt,
    falClipboardListCheck,
    falFilePdf,
    falFileImage,
    falDownload,
    falCommentAlt,
    falCommentAltCheck,
    falCommentAltDots,
    falCommentAltTimes,
    falSpinner,
    falArchive,
    falPencil,
    falPaperclip,
    falAt,
    falSmile,
    falArrowUp,
    falChartLine,
    falArrowRight,
    falThumbsUp,
    falThumbsDown,
    falSparkles,
    falExternalLinkAlt,
    falFileSearch,
    falPenNib,
    falRepeat,
    falFileEdit,
    falFileCheck,
    falGift,
    falFileMinus,
    falPersonDolly,
    falDigging,
    falChalkboardTeacher,
    falKeynote,
    falCaretRight,
    falLevelUp,
    falComments,
    falCommentsAlt,
    falHistory,
    falImages,
    falGraduationCap,
    falUserGraduate,
    falBookmark,
    falSchool,
    falFileCertificate,
    falDiploma,
    falCubes,
    falBookOpen,
    falTrophyAlt,
    falPuzzlePiece,
    falEnvelopeOpenText,
    falSlidersH,
    falUserCheck,
    falUserEdit,
    falEquals,
    falNotEqual,
    falSiren,
    falPhone,
    falWasher,
    falBarcodeScan,
    falRulerTriangle,
    falInboxIn,
    falExclamationCircle,
    falPassport,
    falNetworkWired,
    falArrowFromLeft,
    falArrowFromRight,
  );

  //DUOTONE
  library.add(
    // START DUOTONE ICON MODAL
    fadFolderOpen,
    fadMailbox,
    fadAnchor,
    fadBagsShopping,
    fadBookmark,
    fadStars,
    fadMagic,
    fadBooks,
    fadImagePolaroid,
    fadMapMarkerAlt,
    fadUsers,
    fadMedal,
    fadLockAlt,
    fadCameraHome,
    fadWrench,
    fadShoppingCart,
    fadChartPie,
    fadChartArea,
    fadAbacus,
    fadBadgePercent,
    fadPaperPlane,
    fadRocket,
    fadFeatherAlt,
    fadRadar,
    fadGlobeStand,
    fadDesktop,
    fadCode,
    fadFile,
    fadDatabase,
    fadUpload,
    // END DUOTONE ICON MODAL
    fadPlug,
    fadLayerGroup,
    fadBomb,
    fadHome,
    fadHomeLgAlt,
    fadEye,
    fadEyeSlash,
    fadFileWord,
    fadTrash,
    fadCloudUpload,
    fadArrowSquareLeft,
    fadCheck,
    fadExternalLinkAlt,
    fadSearch,
    fadInfoCircle,
    fadThList,
    fadArrowUp,
    fadArrowDown,
    fadEquals,
    fadDownload,
    fadClipboard,
    fadClone,
    fadObjectGroup,
    fadHistory,
    fadFolder,
    fadPencil,
    fadEdit,
    fadPlusCircle,
    fadLanguage,
    fadPlus,
    fadTags,
    fadTag,
    fadTimesCircle,
    fadCalendarWeek,
    fadCalendar,
    fadUndo,
    fadBarcodeScan,
    fadSiren,
    fadChartNetwork,
    fadCalendarDay,
    fadUser,
    fadProjectDiagram,
    fadCircle,
    fadSlidersV,
    fadKey,
    fadLevelUp,
    fadListUl,
    fadSlidersVSquare,
    fadUnlockAlt,
    fadCog,
    fadPowerOff,
    fadSync,
    fadMegaphone,
    fadSignOut,
    fadTvRetro,
    fadExternalLink,
    fadFont,
    fadGlobe,
    fadPen,
    fadSparkles,
    fadTelescope,
    fadSirenOn,
    fadUserRobot,
    fadSave,
    fadArrowToLeft,
    fadFileUpload,
    fadBell,
    fadLockOpen,
    fadSyncAlt,
    fadEnvelope,
    fadPaperclip,
    fadLocationCircle,
    fadArchive,
    fadLineColumns,
    fadLink,
    fadCaretLeft,
    fadCaretRight,
    fadBells,
    fadCopy,
    fadExternalLinkSquare,
    fadThumbsUp,
    fadThumbsDown,
    fadHomeLg,
    fadStar,
    fadChartLine,
    fadArrowRight,
    fadFileSearch,
    fadMoneyCheckEdit,
    fadBuilding,
    fadIdCardAlt,
    fadNotEqual,
    fadPhone,
    fadUserFriends,
    fadUsersCog,
    fadRedoAlt,
    fadUserTag,
    fadWasher,
    fadClipboardCheck,
    fadInfo,
    fadCheckCircle,
    fadExclamationTriangle,
    fadThLarge,
    fadBookOpen,
    fadCamera,
    fadClipboardListCheck,
    fadDolly,
    fadGlobeEurope,
    fadPlayCircle,
    fadInfinity,
    fadFilter,
    fadInboxIn,
    fadCogs,
    fadClock,
    fadFileCheck,
    fadPuzzlePiece,
    fadThumbtack,
    fadBellExclamation,
    fadUserCog,
    fadSpinnerThird,
    fadAtom,
    fadHourglass,
    fadCommentTimes,
    fadCommentCheck,
    fadSmile,
    fadComments,
    fadGraduationCap,
    fadFileEdit,
  );
  library.add(faChrome, faIntercom, faWindows, faSalesforce, faSearchengin);

  Vue.component('font-awesome-icon', FontAwesomeIcon);
}
