<template>
  <SettingsDrawer
    :show="show"
    icon="network-wired"
    :title="$t(`${pre}.title`)"
    :title-options="titleOptions"
    :description="$t(`${pre}.description`)"
    :disabled="true"
    :item-id="true"
    size="66%"
    :saveable="false"
    @submit="$emit('submit', profile)"
    @close="handleClose"
  >
    <div slot="main">
      <BaseTabsWrapper
        :active-tab="activeTab"
        :tabs="tabs"
        @update-tab="handleUpdateTab"
      />
      <component :is="activeTabComponent" />
    </div>
  </SettingsDrawer>
</template>

<script>
import SettingsDrawer from '@/components/Settings/SettingsDrawer/SettingsDrawer.vue';

const i18nPrefix =
  'settings-layout.menu-data.authentication.sso-mapping.drawer';

const tabs = [
  {
    key: 'Profile',
    text: `${i18nPrefix}.profile.tab`,
    icon: 'passport',
  },
  {
    key: 'UserLabel',
    text: `${i18nPrefix}.user-label.tab`,
    icon: 'tag',
  },
];

export default {
  name: 'SsoAttributesMappingSettingsDrawer',
  components: {
    SettingsDrawer,
    // eslint-disable-next-line vue/no-unused-components
    SsoAttributesMappingProfile: () => import('./SsoAttributesMappingProfile'),
    // eslint-disable-next-line vue/no-unused-components
    SsoAttributesMappingUserLabel: () =>
      import('./SsoAttributesMappingUserLabel'),
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pre: `${i18nPrefix}`,
      titleOptions: {
        editable: false,
      },
      tabs: tabs.map((tab) => ({ ...tab, text: this.$t(tab.text) })),
      activeTab: tabs[0].key,
    };
  },
  computed: {
    activeTabComponent() {
      return `SsoAttributesMapping${this.activeTab}`;
    },
  },
  methods: {
    handleUpdateTab(tab) {
      this.activeTab = tab;
    },
    handleClose() {
      this.mappings = [];
      this.profiles = [];
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.sso-profile-permission {
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }
}
</style>
