<template>
  <div :class="`${base}`">
    <span :class="`${base}-readonly`" v-if="!editable && !value.icon">
      {{ type === 'input' ? value : value.label }}
    </span>
    <span :class="`${base}-readonly`" v-else-if="!editable && value.icon">
      <font-awesome-icon :icon="value.icon" />
    </span>
    <span :class="`${base}-editable`" v-else>
      <el-input
        :class="`${base}-editable__input`"
        v-if="type === 'input'"
        v-model="localValue"
        :placeholder="placeholder"
        size="md"
        @change="$emit('input', $event)"
      ></el-input>
      <BaseSelect
        v-else-if="type === 'select'"
        :class="`${base}-editable__select`"
        :value="localValue"
        :options="options"
        :placeholder="placeholder"
        :multiple="false"
        :searchable="true"
        value-key="id"
        size="md"
        @change="handleSelectChange"
      />
    </span>
  </div>
</template>
<script>
import BaseSelect from '@/components/BaseSelect.vue';

const base = 'sso-attributes-mapping-input';

export default {
  name: 'SsoAttributesMappingInput',
  components: {
    BaseSelect,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'input',
    },
    value: {
      default: null,
    },
    options: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      base: `${base}`,
      localValue: null,
    };
  },
  methods: {
    handleSelectChange(selection) {
      this.localValue = selection;
      this.$emit('input', selection);
    },
  },
  mounted() {
    this.localValue = this.value;
  },
};
</script>
<style lang="scss" scoped>
.sso-attributes-mapping-input {
  width: 100%;
  display: flex;
  align-items: center;
  &-editable {
    width: 100%;
    :deep() .el-input--md {
      height: 30px;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding: 0px 8px;
      }
    }
  }

  &-readonly {
    padding-left: 8px;
    font-size: 14px;
    word-break: break-all;
  }
}
</style>
