<template>
  <div class="label-container">
    <!-- LABEL NAME -->
    <div v-if="!editName" @dblclick="editName = true">
      {{ name }}
    </div>
    <ItemEditor
      class="label-editor"
      v-else
      :value="name"
      @edit="updateLabelName"
      :isLabel="true"
      @cancel="editName = false"
      placeholder="settings.permissions.user-labels.category-input-placeholder"
    />
    <!-- OPTIONS -->
    <div class="label-options" v-if="!editName">
      <el-tooltip
        :content="
          $t('settings.card-settings-options.content-parameters.edit-label')
        "
        placement="top"
      >
        <base-button
          :icon-only="true"
          icon="pen"
          size="sm"
          variant="tertiary"
          @click="editName = true"
          class="ml-1"
        ></base-button>
      </el-tooltip>

      <base-button
        @click="showDeleteLabel = true"
        :icon-only="true"
        icon="trash"
        size="sm"
        variant="tertiary"
        class="ml-1"
      ></base-button>
    </div>
    <BaseDialog
      v-if="showDeleteLabel"
      @confirm="deleteLabel"
      @cancel="showDeleteLabel = false"
      @close="showDeleteLabel = false"
      :title="$t('settings.permissions.user-labels.suppress-confirm-title')"
      :description="
        $t('settings.permissions.user-labels.suppress-confirm-description', {
          name,
        })
      "
      :confirm-text="$t('generic.delete')"
      :cancel-text="$t('generic.cancel')"
      :open="showDeleteLabel"
      variant="danger"
    ></BaseDialog>
  </div>
</template>

<script>
import ItemEditor from './ItemEditor';
export default {
  name: 'label-category-settings-overview-item',
  components: {
    ItemEditor,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    companyUserLabelCategoryId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editName: false,
      showDeleteLabel: false,
    };
  },
  methods: {
    /** DELETE LABEL */
    deleteLabel() {
      this.$emit('delete-label', {
        id: this.id,
        name: this.name,
        companyUserLabelCategoryId: this.companyUserLabelCategoryId,
      });
    },

    /** EDIT LABEL */
    updateLabelName(name) {
      this.editName = false;
      if (name !== this.name) {
        return this.$emit('update-label', {
          id: this.id,
          name,
          companyUserLabelCategoryId: this.companyUserLabelCategoryId,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label-container {
  display: flex;
  align-items: center;
  gap: 4px;
  max-height: 24px;
}

.label-editor {
  margin-left: 5px;
}

.label-container .label-options {
  visibility: hidden;
  display: contents;
}

.label-container:hover .label-options {
  visibility: visible;
}

.label-options {
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.options-icon {
  color: $grey-7-mayday;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background-color: $grey-1-mayday;
  }
  cursor: pointer;
  &_trash:hover {
    color: $red-mayday;
  }
  &_edit:hover {
    color: $blue-mayday;
  }
  * {
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
}
</style>
