<template>
  <div>
    <div
      v-if="showBulk"
      class="d-flex align-items-baseline justify-content-between"
    >
      <div v-if="showSelectAll" class="pl-2 d-flex">
        <el-checkbox v-model="checked" />
        <base-button
          class="ml-2"
          variant="tertiary"
          :pressed="checked"
          :text="
            $t(
              `knowledge.bulk-actions.${
                checked ? 'unselect-all' : 'select-all'
              }`,
            )
          "
          @click="checked = !checked"
        />
      </div>
      <!-- Empty div for flex justify-content-between -->
      <div v-else></div>
      <div class="d-flex align-items-center gap-2">
        <base-button
          variant="tertiary"
          :text="$t('generic.cancel')"
          @click="toggleShowBulk"
        />
        <BaseDropdownOptionsWrapper
          :options="dropdownOptions"
          :disabled="disableActions"
          textOnly
          :triggerText="$t('knowledge.bulk-actions.cta')"
          variant="primary"
          @select="handleSelectOption"
        />
      </div>
    </div>
    <div v-else class="d-flex justify-content-end pb-2">
      <base-button
        v-if="showSelect"
        variant="tertiary"
        :text="$t('knowledge.bulk-actions.select')"
        @click="toggleShowBulk"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseDropdownOptionsWrapper from '../BaseDropdownOptionsWrapper.vue';

export default {
  components: {
    BaseDropdownOptionsWrapper,
  },
  props: {
    selectedCount: {
      type: Number,
      default: 0,
    },
    showBulk: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: true,
    },
    displayClone: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    disableActions: {
      type: Boolean,
      default: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    isResultList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    dropdownOptions() {
      return [
        {
          key: 'SET_PUBLISHED',
          label: this.$t('knowledge.bulk-actions.publish'),
          icon: ['fal', 'check'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        {
          key: 'SET_DRAFT',
          label: this.$t('knowledge.bulk-actions.draft'),
          icon: ['fal', 'file-pen'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        {
          key: 'SET_VERIFIED',
          label: this.$t('knowledge.bulk-actions.verify'),
          icon: ['fal', 'circle-check'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        {
          key: 'SET_OUTDATED',
          label: this.$t('knowledge.bulk-actions.unverify'),
          icon: ['fal', 'times-circle'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        ...(this.displayClone
          ? [
              {
                key: 'CLONE',
                label: this.$t('knowledge.bulk-actions.clone'),
                icon: ['fal', 'clone'],
                disabled: Boolean(!this.userPermissions.canCreateContent),
              },
              {
                key: 'DUPLICATE',
                label: this.$t('knowledge.actions.duplicate-in-same-location'),
                icon: ['fal', 'object-group'],
                disabled: Boolean(!this.userPermissions.canCreateContent),
              },
            ]
          : []),

        {
          key: 'EDIT_LABELS',
          label: this.$t('knowledge.bulk-actions.edit.labels'),
          icon: ['fal', 'tag'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        },
        ...(this.isParametric
          ? [
              {
                key: 'EDIT_COLLECTIONS',
                label: this.$t('knowledge.bulk-actions.edit.collections'),
                icon: ['fal', 'folder'],
                disabled: Boolean(!this.userPermissions.canUpdateContent),
              },
              {
                key: 'EDIT_RESTRICTIONS',
                label: this.$t('knowledge.bulk-actions.edit.restrictions'),
                icon: ['fal', 'user-group'],
                disabled: Boolean(!this.userPermissions.canUpdateContent),
              },
              {
                key: 'EDIT_PRODUCT_PARAMETERS',
                label: this.$t('knowledge.bulk-actions.product-parameters'),
                icon: ['fal', 'cog'],
                divider: true,
              },
            ]
          : []),
        {
          key: 'ARCHIVE',
          label: this.$t('knowledge.bulk-actions.archive'),
          icon: ['fal', 'archive'],
          type: 'danger',
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          divider: true,
        },
        {
          key: 'SOFT_DELETE',
          label: this.$t('knowledge.actions.soft-delete'),
          icon: ['fal', 'trash'],
          type: 'danger',
          disabled: Boolean(!this.userPermissions.canDeleteContent),
        },
      ];
    },
    ...mapGetters(['isParametric', 'isGranularAccessRestrictions']),
  },
  methods: {
    handleSelectOption(e) {
      const key = e.detail[0];

      if (!key) return;

      if (key === 'SET_PUBLISHED') {
        return this.updateStatus('publish');
      } else if (key === 'SET_DRAFT') {
        return this.updateStatus('draft');
      } else if (key === 'SET_VERIFIED') {
        return this.updateStatus('verify');
      } else if (key === 'SET_OUTDATED') {
        return this.updateStatus('outdate');
      } else if (key === 'CLONE') {
        return this.$emit('clone');
      } else if (key === 'DUPLICATE') {
        return this.$emit('duplicate-in-same-location');
      } else if (key === 'EDIT_LABELS') {
        return this.$emit('edit-contents', { option: 'labels' });
      } else if (key === 'EDIT_COLLECTIONS') {
        return this.$emit('edit-contents', { option: 'collections' });
      } else if (key === 'EDIT_RESTRICTIONS') {
        return this.$emit('edit-contents', { option: 'restrictions' });
      } else if (key === 'EDIT_PRODUCT_PARAMETERS') {
        return this.$emit('edit-product-parameters');
      } else if (key === 'ARCHIVE') {
        return this.$emit('archive');
      } else if (key === 'SOFT_DELETE') {
        return this.$emit('bulk-delete', { softDelete: true });
      }
    },
    toggleShowBulk() {
      this.$emit('show-bulk');
    },
    updateStatus(action) {
      let payload;
      if (['publish', 'draft'].includes(action))
        payload = { key: 'published', value: action === 'publish' };
      if (['verify', 'outdate'].includes(action))
        payload = { key: 'isOutdated', value: action === 'outdate' };
      this.$emit('update-status', payload);
    },
  },
  watch: {
    checked(val) {
      if (val) {
        this.$emit('select-all');
      } else {
        this.$emit('unselect-all');
      }
    },
    selectedCount(val) {
      if (!val) this.checked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
small {
  color: $grey-5-mayday;
}
.inactive:hover {
  color: $blue-mayday;
}
.active {
  color: $blue-mayday;
}
</style>
