import * as types from './mutation-types';

export default {
  async getBacklogTasksCount({ commit }) {
    try {
      const backlogTasksCount =
        await this.$services.tasks.getBacklogTasksCount();
      commit(types.GET_BACKLOG_TASKS_COUNT, { backlogTasksCount });
    } catch (e) {
      return e;
    }
  },

  // HUB
  getSettingsTaskViews(_, { page = 1 }) {
    return this.$services.tasks.getSettingsTaskViews(page);
  },

  getTaskViews() {
    return this.$services.tasks.getTaskViews();
  },

  getTaskView(_, id) {
    return this.$services.tasks.getTaskView(id);
  },

  createTaskView(_, input) {
    return this.$services.tasks.createTaskView(input);
  },

  updateTaskView(_, input) {
    return this.$services.tasks.updateTaskView(input);
  },

  deleteTaskView(_, id) {
    return this.$services.tasks.deleteTaskView(id);
  },
};
