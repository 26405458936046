export default () => ({
  // TMP only for web-parametric bug feedbacks on steps
  // + used to not storeReadEvent if already in state
  focusStep: null,
  /**
   * KNOWLEDGE
   */
  knowledges: [],
  focusKnowledge: null,
  focusKnowledgeRoots: [],
  knowledgeEditorIsLoading: false,
  focusKnowledgeRootsIsLoading: false,

  /**
   * COLLECTIONS NAVIGATION
   */
  casePath: [],
  collectionsViewerIsLoading: false,
  focusCollectionId: null,
  focusCollection: null,
  focusContentId: null,
  focusContent: null,
  focusContentBacklinks: [],
  focusContentContributors: [],
  focusContentConcepts: null,
  updateStatus: '',

  focusCollectionChildren: [],
  focusCollectionRelatedContents: [],

  focusCollectionContentsResults: [],
  focusCollectionContentsTotal: 0,
  focusCollectionContentsPages: 0,
  collectionSearch: {
    filter: '',
    page: 1,
  },
  contentParameterLabels: [],
  toggleNavigationBar: true,

  /**
   * ARCHIVE FOCUS
   */
  archiveId: null,
  archiveContent: null,
  archiveDescendents: [],
  archiveFormerMainAncestor: null,
  archiveDeletionDate: null,
  archiveError: false,

  /**
   * CASE ARCHIVE FOCUS
   */

  caseArchiveViewerIsLoading: false,
  mainCaseArchive: {},
  focusCaseArchive: {},
  focusCaseArchiveAncestors: [],
  caseArchiveError: false,

  /**
   * MERGED_VIEWER + NAVIGATOR NODE
   */

  navigatorNodeIsLoading: false,

  // {
  //   entityType-id: item
  // }
  navigatorLookupMap: {},

  // {
  //   entityType-id: {
  //     dragTo: { entityType: 'Case' || 'Knowledge', path: 'abc/def', newIndex: 1, isKnowledge: true },
  //     dragFrom: { entityType: 'Case' || 'Knowledge',path: '123/456', isKnowledge: false }
  //   }
  // }
  // We need to differenciate between Case or Knowlege to handle the root key
  // when drag and dropping a Case entity
  dragAndDrop: {},

  /**
   * IMPORT ARTICLE
   */

  importArticleIsLoading: false,
  editingLanguage: null, //observed to trigger translation requests
  navigationLanguage: null, //updated after translation requests

  /**
   * TEMPLATE ARTICLE
   */

  templates: [],
  focusTemplate: {},
  templatesPath: [],

  /**
   * PRODUCT
   */

  collectionParents: [],

  /**
   * MAYDAY_EDITOR
   */

  fileUploadError: '',
});
